import { motion } from "framer-motion";
import styled from "styled-components";
import React, { ComponentPropsWithRef } from "react";

const StyledButton = motion(styled.button`
  all: unset;

  cursor: pointer;
  background: white;
  border: 5px solid ${(props) => props.theme.dark};
  border-radius: 13px;
  padding: 5px;
  min-height: 70px;
  box-sizing: border-box;
`);
const Answer = ({
  forwardedRef,
  ...props
}: ComponentPropsWithRef<typeof StyledButton>) => {
  return <StyledButton ref={forwardedRef} {...props}></StyledButton>;
};

export default Answer;
