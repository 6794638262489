import React, { useState } from "react";
import styled from "styled-components";
import { useUserData } from "../../data/UserData";
import ThemeButton from "../../components/ThemeButton/ThemeButton";
import AlertScreen from "../../components/AlertScreen/AlertScreen";
import { motion } from "framer-motion";
import ToggleInput from "./ToggleInput/ToggleInput";
import { Choices, Computed } from "../../data/variables";

const StyledChoiceInteraction = styled.div`
  height: 100%;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;

  .text {
    font-size: 1.5em;
    font-weight: 700;
    margin-block: 30px;
    margin-block: min(30px, 2cqh);
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }

  .buttons .grid {
    margin-top: 10px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;

    & > * {
      flex-basis: calc(50% - 5px);
    }
  }

  .toggles {
    //flex: 1;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    overflow: auto;
    margin-bottom: 10px;

    & > :first-child {
      margin-top: auto;
    }

    //hide scrollbar
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    .toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      text-align: left;
      gap: 10px;

      &:not(:last-child) {
        border-bottom: 1px solid ${(props) => props.theme.dark};
      }

      & > :nth-child(2) {
        flex-shrink: 0;
      }
    }
  }
`;

const ChoiceInteraction = ({ config }: { config: IChoiceInteraction }) => {
  const { getCompiled, incrementStage } = useUserData();
  const { text, choices, saveVariable, button, multi, header, skipButton } =
    config;
  const [selected, setSelected] = useState<Choice[]>([]);

  const skip = () => {
    if (skipButton) {
      incrementStage();
    }
  };

  const select = (choice) => {
    if (selected.includes(choice)) {
      setSelected(selected.filter((c) => c !== choice));
      return;
    }

    if (multi) {
      setSelected([...selected, choice]);
    } else {
      saveSelection([choice]);
    }
  };

  const saveSelection = (selected) => {
    if (selected.length === 0 && !skipButton) return;
    const choices = {};
    const computed = {};

    selected.forEach((choice) => {
      const save = choice.saveVariable || saveVariable;
      if (save) {
        save.forEach((save, i) => {
          const store = Choices[save]
            ? choices
            : Computed[save]
            ? computed
            : {};
          store[save] = getCompiled(choice.value[i]);
        });
      }
    });

    incrementStage({
      userChoices: choices,
      computedChoices: computed,
    });
  };

  return (
    <AlertScreen icon="question" showBack={false} header={header}>
      <StyledChoiceInteraction>
        <motion.div
          className="text"
          animate={{
            opacity: [0, 1],
          }}
          aria-live="polite"
        >
          {getCompiled(text)}
        </motion.div>
        {multi ? (
          <>
            <motion.div
              animate={{
                opacity: [0, 1],
              }}
              className="toggles"
            >
              {choices.map((choice, i) => (
                <div key={i} className="toggle">
                  <span>{choice.display ?? choice.value}</span>
                  <ToggleInput
                    active={selected.includes(choice)}
                    toggle={() => select(choice)}
                  ></ToggleInput>
                </div>
              ))}
            </motion.div>
            <ThemeButton
              as={motion.button}
              onClick={() => saveSelection(selected)}
              animate={{
                y: ["100%", "0%"],
              }}
            >
              {selected.length === 0 && skipButton
                ? skipButton
                : button ?? "Verder"}
            </ThemeButton>
          </>
        ) : (
          <motion.div
            animate={{
              y: ["100%", "0%"],
            }}
            className="buttons"
          >
            {skipButton && (
              <ThemeButton onClick={skip}>{skipButton}</ThemeButton>
            )}
            <div className="grid">
              {choices.map(
                (choice, i) =>
                  (!choice.hideIf || !getCompiled(choice.hideIf)) && (
                    <ThemeButton key={i} onClick={() => select(choice)}>
                      {choice.display ?? choice.value}
                    </ThemeButton>
                  )
              )}
            </div>
          </motion.div>
        )}
      </StyledChoiceInteraction>
    </AlertScreen>
  );
};

export default ChoiceInteraction;
