import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion, useAnimationControls } from "framer-motion";
import { Player } from "@lottiefiles/react-lottie-player";
import ThemeButton from "../../../components/ThemeButton/ThemeButton";
import { useNavigate } from "react-router-dom";
import Circles from "../../../components/Circles/Circles";
import { useUserData } from "../../../data/UserData";
import { gameData } from "../../../data/GameData";
import { useAudio } from "../../../assets/audio/Audio";

const StyledAchievements = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  color: white;
  text-align: center;

  font-family: var(--emphasis-font);
  letter-spacing: 1px;

  .circles {
    position: absolute;
    width: 100%;
    display: grid;
    place-items: center;

    z-index: -1;
  }

  .lottie-container {
    position: relative;
    height: 250px;
  }

  .lf-player-container {
    position: relative;
    top: -75px;
    width: calc(320px * 1.4);
  }

  .header {
    margin-top: 40px;
    position: relative;
  }

  .title,
  .description {
    position: relative;
    margin-inline: 20px;
    margin-inline: max(20px, 10cqw);
  }

  .title {
    font-size: 1.5em;
    margin-top: 40px;
    text-transform: uppercase;
    &::before,
    &::after {
      content: "'";
    }
  }

  .description {
    font-size: 1.25em;
  }

  .button {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
  }
`;

const transition = (delay) => ({
  delay,
  duration: 0.8,
  ease: "easeOut",
});

const circleVariants = {
  visible: (delay = 0) => ({
    y: "0",
    transition: transition(delay),
  }),
  hidden: {
    y: "-50%",
  },
};

const circleAnim = {
  variants: circleVariants,
  initial: "hidden",
  animate: "visible",
};

const NewAchievement = () => {
  const navigate = useNavigate();
  const lottie = useRef<Player>();
  const buttonControls = useAnimationControls();
  const infoControls = useAnimationControls();
  const { userData } = useUserData();
  const newestAchievement =
    userData.achievements[userData.achievements.length - 1]?.id;
  const audio = useAudio();

  useEffect(() => {
    audio.play("achievement");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!newestAchievement) {
    return <></>;
  }

  const achievement = gameData.achievements[newestAchievement];

  const { name, description, animation } = achievement;

  return (
    <StyledAchievements>
      <Circles
        playSound={false}
        style={{
          position: "absolute",
          left: -200,
          right: -200,
          bottom: 90,
        }}
        {...circleAnim}
      ></Circles>

      <motion.div
        animate={{
          opacity: [0, 1],
          scale: [0, 1],
        }}
        onAnimationComplete={() => {
          lottie.current.play();
        }}
        transition={transition(0)}
        className="header emphasis"
      >
        Achievement unlocked
      </motion.div>

      <div className="lottie-container" aria-hidden>
        <Player
          className="lottie"
          ref={lottie}
          keepLastFrame={true}
          src={animation}
          onEvent={(e) => {
            if (e === "complete") {
              infoControls.start(
                {
                  scale: [0, 1],
                  opacity: [0, 1],
                },
                { ...transition(0), duration: 0.4 }
              );
              buttonControls.start(
                {
                  y: [100, 0],
                },
                { ...transition(0), duration: 0.4 }
              );
            }
          }}
        ></Player>
      </div>

      <motion.div
        animate={infoControls}
        initial={{
          scale: 0,
          opacity: 0,
        }}
      >
        <div className="title">{name}</div>
        <div className="description">{description}</div>
      </motion.div>
      <motion.div
        animate={buttonControls}
        initial={{
          y: 100,
        }}
        className="button"
      >
        <ThemeButton onClick={() => navigate("/city")}>verder</ThemeButton>
      </motion.div>
    </StyledAchievements>
  );
};

export default NewAchievement;
