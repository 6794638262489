import "./Game.scss";
import React, { useEffect, useMemo } from "react";
import SliderInteraction from "../../interactions/SliderInteraction/SliderInteraction";
import BubbleInteraction from "../../interactions/BubbleInteraction/BubbleInteraction";
import Conversation from "../../interactions/Conversation/Conversation";
import { useUserData } from "../../data/UserData";
import { AnimatePresence, motion } from "framer-motion";
import ChoiceInteraction from "../../interactions/ChoiceInteraction/ChoiceInteraction";
import Event from "../../interactions/Event/Event";
import TrueFalse from "../../interactions/TrueFalseInteraction/TrueFalseInteraction";
import { useNavigate } from "react-router-dom";
import { useRedirect } from "../../util/useRedirect";
import { Player } from "@lottiefiles/react-lottie-player";
import DragDropInteraction from "../../interactions/DragDropInteraction/DragDropInteraction";
import VotingInteraction from "../../interactions/VotingInteraction/VotingInteraction";

const Game = () => {
  useRedirect();
  const navigate = useNavigate();
  const { currentStage, userData, incrementStage } = useUserData();

  const stage = useMemo(() => {
    switch (currentStage?.type) {
      case "dragDropInteraction":
        return (
          <DragDropInteraction config={currentStage}></DragDropInteraction>
        );
      case "votingInteraction":
        return <VotingInteraction config={currentStage}></VotingInteraction>;
      case "sliderInteraction":
        return <SliderInteraction config={currentStage}></SliderInteraction>;
      case "bubblePopperInteraction":
        return <BubbleInteraction config={currentStage}></BubbleInteraction>;
      case "trueFalse":
        return <TrueFalse config={currentStage}></TrueFalse>;
      case "conversation":
        if (!currentStage.inCity) {
          return <Conversation config={currentStage}></Conversation>;
        }
        break;
      case "choice":
        return <ChoiceInteraction config={currentStage}></ChoiceInteraction>;
      case "event":
        return <Event config={currentStage}></Event>;
      case "playAnimation":
        return (
          <div className="door-anim">
            <Player
              style={{
                height: "100%",
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
              }}
              src={currentStage.lottieFile}
              autoplay
              keepLastFrame
              onEvent={(e) => {
                if (e === "complete") {
                  incrementStage();
                }
              }}
            ></Player>
          </div>
        );
    }
  }, [currentStage, incrementStage]);

  useEffect(() => {
    if (
      !currentStage ||
      currentStage.inCity ||
      currentStage.type === "goToBuilding" ||
      currentStage.type === "popup"
    ) {
      navigate("/city");
      return;
    }
  }, [currentStage, navigate]);

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={userData.progression.currentStage}
        animate={{ opacity: [0, 1] }}
        style={{
          height: "100%",
        }}
        transition={{}}
        exit={{ opacity: 0 }}
      >
        {stage}
      </motion.div>
    </AnimatePresence>
  );
};

export default Game;
