import blissImg from "./char-zorgverzekering-bliss.svg";
import callingImg from "./char-zorgverzekering-calling.svg";
import defaultImg from "./char-zorgverzekering-default.svg";
import explainingImg from "./char-zorgverzekering-explaining.svg";
import holdingPaperImg from "./char-zorgverzekering-holding-paper.svg";
import pointingImg from "./char-zorgverzekering-pointing.svg";
import slightlySmilingImg from "./char-zorgverzekering-slightly-smiling.svg";
import thumbsUpImg from "./char-zorgverzekering-thumbs-up.svg";
import waveImg from "./char-zorgverzekering-wave.svg";
import winkImg from "./char-zorgverzekering-wink.svg";

const ZorgverzekeringGuy = {
  Default: defaultImg,
  Bliss: blissImg,
  Calling: callingImg,
  Explaining: explainingImg,
  HoldingPaper: holdingPaperImg,
  Pointing: pointingImg,
  SlightlySmiling: slightlySmilingImg,
  ThumbsUp: thumbsUpImg,
  Wave: waveImg,
  Wink: winkImg,
};

export default ZorgverzekeringGuy;
