import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  isAndroid,
  isChrome,
  isDesktop,
  isEdge,
  isIOS,
  isSafari,
} from "react-device-detect";
import { ReactComponent as SafariLogo } from "./browser-safari-icon.svg";
import { ReactComponent as AppleShare } from "./apple-share.svg";
import { ReactComponent as AddToHomeScreen } from "./add-to-home-screen.svg";
import { ReactComponent as ThreeDots } from "./three-dots-vertical.svg";
import { ReactComponent as ChromeLogo } from "./chrome-icon.svg";
import { ReactComponent as EdgeLogo } from "./edge-logo.svg";
import ThemeButton from "../../components/ThemeButton/ThemeButton";
import { NavLink } from "react-router-dom";

// This variable will save the event for later use.
window.addEventListener("beforeinstallprompt", (e) => {
  // Prevents the default mini-infobar or install dialog from appearing on mobile
  e.preventDefault();
  // Save the event because you'll need to trigger it later.
  window["installPrompt"] = e;
});

const StyledInstall = styled.div`
  height: 100%;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  overflow-y: auto;

  user-select: text;
  word-break: break-word;
  text-align: center;

  color: ${({ theme }) => theme.dark};

  h1 {
    font-weight: 500;
    font-size: 2em;
    font-family: var(--emphasis-font);
  }

  h2 {
    font-weight: 500;
    font-size: 1em;
    margin: 0;
  }

  .instructions {
    text-align: center;
    display: grid;
    gap: 20px;
    margin-bottom: 20px;
    width: 100%;
    border: 3px solid ${({ theme }) => theme.main};
    border-radius: 16px;
    padding: 10px 5px;

    svg {
      fill: ${({ theme }) => theme.main};
      stroke: ${({ theme }) => theme.main};

      margin-inline: auto;
    }
  }
`;

const pwaInstalled = window.matchMedia("(display-mode: standalone)").matches;

const Install = (props) => {
  const [deferredPrompt, setDeferredPrompt] = useState(window["installPrompt"]);
  const [outcome, setOutcome] = useState<"accepted" | "dismissed">();

  useEffect(() => {
    const listener = (e) => {
      // Prevents the default mini-infobar or install dialog from appearing on mobile
      e.preventDefault();
      // Save the event because you'll need to trigger it later.
      setDeferredPrompt(e);
    };
    window.addEventListener("beforeinstallprompt", listener);
    return () => {
      window.removeEventListener("beforeinstallprompt", listener);
    };
  }, []);

  const promptToInstall = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        setOutcome(choiceResult.outcome);
        setDeferredPrompt(null);
      });
    }
  };

  const fallBackInstructions = (
    <>
      <div className="instructions">
        <div>
          Wij kunnen geen instructies vinden voor jouw toestel. Soms kun je de
          app toch installeren als je even zoekt in de browser.
        </div>
        <div>
          Je kunt ook gewoon het spel spelen in de browser met de knop
          hieronder.
        </div>
        <div>
          We raden je aan om <code>{window.location.origin}</code> op te slaan
          als bladwijzer, om het spel later makkelijk terug te vinden.
        </div>
      </div>
    </>
  );

  return (
    <StyledInstall {...props}>
      <h1>De app installeren</h1>
      {pwaInstalled && (
        <p>De app is al geïnstalleerd! Je hoeft niks meer te doen ✅</p>
      )}
      <p>
        Dit spel kan als app op je telefoon geïnstalleerd worden zodat je het
        makkelijk terug kan vinden.
      </p>
      <p>Dit is niet verplicht of nodig om het spel te kunnen spelen!</p>
      <p>Hoe je de app installeert op jouw toestel, lees je hieronder.</p>
      {deferredPrompt ? (
        <div className="instructions">
          <h2>Automatische installatie</h2>

          <div>
            Jouw toestel ondersteunt automatische installatie!
            <br />
            Druk op de knop om de installatie te starten
          </div>

          <ThemeButton onClick={promptToInstall}>Installeer!</ThemeButton>
          {outcome === "accepted" && <div>De app is geïnstalleerd!</div>}
          {outcome === "dismissed" && <div>De installatie is geannuleerd.</div>}
        </div>
      ) : isIOS ? (
        isSafari ? (
          <div className="instructions">
            <h2>Instructies voor iPhone</h2>
            <div>Open het deel-menu van Safari</div>
            <AppleShare
              style={{
                width: 90,
                height: 90,
              }}
              alt="apple share icon"
            ></AppleShare>
            <div>
              Kies voor <b>Zet op beginscherm</b>
            </div>
            <AddToHomeScreen
              style={{
                width: 80,
                height: 80,
              }}
              alt="add to home screen icon"
            ></AddToHomeScreen>
            <div>
              Druk op <b>Voeg toe</b>
            </div>
          </div>
        ) : (
          <div className="instructions">
            <h2>Instructies voor iPhone</h2>
            <div>
              Je kan deze app alleen installeren met Safari. Open met deze link
              de app in Safari en volg de instructies op de pagina.
            </div>
            <code>{window.location.origin}/install</code>
            <SafariLogo
              style={{
                width: 64,
                height: 64,
              }}
              alt="safari logo"
            ></SafariLogo>
          </div>
        )
      ) : isAndroid ? (
        isChrome ? (
          <div className="instructions">
            <h2>Instructies voor Android</h2>
            <div>Open het menu van Chrome</div>
            <ThreeDots
              style={{
                width: 64,
                height: 64,
              }}
              alt="chrome three dots"
            ></ThreeDots>
            <div>
              Kies voor <b>Installeren</b> of <b>Voeg toe aan beginscherm</b>
            </div>
          </div>
        ) : (
          <div className="instructions">
            <h2>Instructies voor Android</h2>
            <div>
              Je kan deze app installeren met Google Chrome. Open met deze link
              de app in Chrome en volg de instructies op de pagina.
            </div>
            <code>{window.location.origin}/install</code>
            <ChromeLogo
              style={{
                width: 64,
                height: 64,
              }}
              alt="google chrome logo"
            ></ChromeLogo>
          </div>
        )
      ) : isDesktop ? (
        isChrome ? (
          fallBackInstructions
        ) : isEdge ? (
          fallBackInstructions
        ) : (
          <div className="instructions">
            <h2>Instructies voor installatie</h2>
            <div>
              Jouw browser ondersteunt geen installatie. Gebruik hiervoor Google
              Chrome of Microsoft Edge. Open met deze link de app in die browser
              en volg de instructies op de pagina.
            </div>
            <code>{window.location.origin}/install</code>
            <div
              style={{
                display: "grid",
                justifyContent: "center",
                gridTemplateColumns: "repeat(2, auto)",
                gap: 20,
              }}
            >
              <ChromeLogo
                style={{
                  width: 64,
                  height: 64,
                }}
                alt="google chrome logo"
              ></ChromeLogo>
              <EdgeLogo
                style={{
                  width: 64,
                  height: 64,
                }}
                alt="microsoft edge logo"
              ></EdgeLogo>
            </div>
          </div>
        )
      ) : (
        fallBackInstructions
      )}
      <div
        style={{
          flex: 1,
        }}
      ></div>
      <NavLink
        style={{
          width: "100%",
          marginTop: "auto",
        }}
        to="/city"
      >
        <ThemeButton>Naar het spel</ThemeButton>
      </NavLink>
    </StyledInstall>
  );
};

export default Install;
