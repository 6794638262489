import React from "react";
import styled from "styled-components";
import SkylineImg from "../City/backgrounds/skyline.svg";
import PosterImg from "../../data/Buildings/buildings/abri.svg";
import IconButton from "../../components/IconButton/IconButton";
import { useNavigate } from "react-router-dom";

const StyledPoster = styled.div`
  background: url(${SkylineImg}) no-repeat no-repeat bottom,
    linear-gradient(to bottom, #51a1e4, #e6f6fc);
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position-x: 25%;

  display: flex;
  align-items: flex-end;
  justify-content: center;

  img {
    height: 80%;
  }

  .back {
    position: absolute;
    top: 20px;
    left: 20px;
  }
`;

const Poster = () => {
  const navigate = useNavigate();

  return (
    <StyledPoster>
      <IconButton
        onClick={() => navigate("/city")}
        className="back"
        icon="Back"
      ></IconButton>
      <img src={PosterImg} alt="Poster Stichting Lezen en Schrijven." />
    </StyledPoster>
  );
};

export default Poster;
