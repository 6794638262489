import { useLocation, useNavigate } from "react-router-dom";
import { useUserData } from "../data/UserData";
import { useEffect } from "react";
import throttle from "lodash/throttle";

const f = (currentStage, pathname, navigate, incrementStage) => {
  if (pathname !== currentStage.redirect) {
    navigate(currentStage.redirect);
  }
  if (currentStage?.type === "redirect") {
    setTimeout(() => {
      incrementStage();
    }, 1000);
  }
};

const t_f = throttle(f, 1000, {
  leading: true,
  trailing: false,
});

export const useRedirect = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentStage, incrementStage } = useUserData();

  useEffect(() => {
    if (currentStage?.redirect) {
      t_f(currentStage, location.pathname, navigate, incrementStage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStage?.redirect, location.pathname]);
};
