import styled from "styled-components";
import React, { ComponentProps } from "react";

type Props = { fullSize?: boolean; borderSize?: number };
const StyledPopUp = styled.div<Props>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.dark + "54"};
  padding: 20px;
  color: ${(props) => props.theme.dark};
  z-index: 1;
  display: grid;
  place-items: center;
  overflow-y: auto;
  overflow-x: hidden;

  & > div {
    position: relative;
    background: linear-gradient(
      to bottom,
      ${(props) => props.theme.main},
      ${(props) => props.theme.dark}
    );
    border-radius: 16px;
    padding: ${(props) => props.borderSize}px;
    ${(props) => props.fullSize && "width: 100%; height: 100%;"}

    & > div {
      background: white;
      width: 100%;
      height: 100%;
      border-radius: 16px;
    }
  }
`;

StyledPopUp.defaultProps = {
  fullSize: false,
  borderSize: 4,
};

const PopUp = ({
  children,
  fullSize,
  borderSize,
  ...props
}: ComponentProps<typeof StyledPopUp> & Props) => {
  return (
    <StyledPopUp fullSize={fullSize} borderSize={borderSize}>
      <div>
        <div className="content" {...props}>
          {children}
        </div>
      </div>
    </StyledPopUp>
  );
};
export default PopUp;
