import { useUserData } from "../data/UserData";
import { useEffect, useState } from "react";
import { Howl } from "howler";

class Delay {
  delay: number;
  constructor(delay: number) {
    this.delay = delay;

    this.play = this.play.bind(this);
    this.stop = this.stop.bind(this);
    this.replay = this.replay.bind(this);
  }

  play() {
    return new Promise((resolve) => {
      setTimeout(resolve, this.delay);
    });
  }

  stop() {
    // not implemented
  }

  replay() {
    // not implemented
  }
}

class _Howl {
  howl;
  id;

  constructor(howl, id) {
    this.howl = howl;
    this.id = id;

    this.play = this.play.bind(this);
    this.stop = this.stop.bind(this);
    this.replay = this.replay.bind(this);
  }

  play() {
    return new Promise((resolve) => {
      this.howl.once("end", resolve);
      this.howl.once("stop", resolve);
      this.howl.play(this.id);
    }).then(() => {
      this.howl.off("end");
      this.howl.off("stop");
    });
  }

  stop() {
    this.howl.stop(this.id);
  }

  replay() {
    this.stop();
    this.play();
  }
}

class MultipleHowl {
  howls: _Howl[];
  constructor(howls: _Howl[]) {
    this.howls = howls;

    this.play = this.play.bind(this);
    this.stop = this.stop.bind(this);
    this.replay = this.replay.bind(this);
  }

  async play() {
    for (const howl of this.howls) {
      await howl.play();
    }
  }

  stop() {
    this.howls.forEach((howl) => howl.stop());
  }

  replay() {
    this.stop();
    this.play();
  }
}

function toHowl(
  voiceLine: VoiceLine,
  volume: number
): _Howl | MultipleHowl | Delay {
  let id = undefined;
  let howl;
  if (typeof voiceLine === "string") {
    howl = new Howl({
      src: [voiceLine],
      volume,
    });
  } else if (typeof voiceLine === "number") {
    return new Delay(voiceLine);
  } else if (Array.isArray(voiceLine)) {
    const howls = voiceLine.map((vc) => toHowl(vc, volume)) as _Howl[];
    return new MultipleHowl(howls);
  } else {
    const { src, offset, duration } = voiceLine;
    howl = new Howl({
      src: [src],
      volume,
      sprite: {
        sound: [offset, duration],
      },
    });
    id = "sound";
  }

  return new _Howl(howl, id);
}

const emptyHowl = {
  play: () => {},
  stop: () => {},
  replay: () => {},
};
const useHowl = (voiceLine: VoiceLine) => {
  const { userData } = useUserData();
  const volume = userData.settings.soundVolume;
  const [howl, setHowl] = useState(emptyHowl);

  useEffect(() => {
    if (voiceLine && volume > 0) {
      const howl = toHowl(voiceLine, volume);

      setHowl(howl);
      return () => {
        howl.stop();
      };
    } else {
      setHowl(emptyHowl);
    }
  }, [volume, voiceLine]);
  return howl;
};

export default useHowl;
