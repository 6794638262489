import React, { useRef } from "react";

import styled from "styled-components";
import { motion } from "framer-motion";

import IconButton from "../../components/IconButton/IconButton";
import { useNavigate } from "react-router-dom";
import { useDraggable } from "react-use-draggable-scroll";
import { isMobile } from "react-device-detect";
import Noah from "../../assets/characters/Noah/Noah";
import Help from "../Help/Help";

import BiebImg from "./bibliotheek-icon.svg";

import HelpVoice from "../../data/Modules/9-EindQuiz/voice/Noah - Kristel-13.36-58.mp3";

const StyledLibrary = styled.div`
  height: 100%;
  position: relative;

  .icon-button {
    position: absolute;
    top: 20px;

    &.back {
      left: 20px;
    }

    &.help {
      right: 20px;
    }
  }

  .scroll {
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 10px 10px;
    overflow-x: hidden;

    // hide scrollbar
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  color: ${({ theme }) => theme.dark};

  #lottie {
    width: min(100vw, 50vh);
    width: min(100cqw, 50cqh);
  }

  .header {
    font-size: 1.5em;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .main {
    position: relative;
    margin-top: auto;
    padding: 20px;
    max-width: 100%;
    display: grid;
    justify-content: center;
    background: linear-gradient(
      to top,
      ${({ theme }) => theme.dark},
      ${({ theme }) => theme.main}
    );
    //flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    grid-template-columns: 1fr;
    border-radius: 16px;
  }

  .main-wrapper {
    //height: 0;
    //flex: 1;
    width: 100%;
    //overflow-y: auto;
    border-radius: 16px;
  }
`;

const LibraryItem = styled.div`
  background: white;
  width: 100%;
  border-radius: 10px;
  padding: 16px;

  h3 {
    font-size: 1em;
    line-height: 1.5em;
    margin: 0;
  }

  p {
    font-size: 0.75em;
    margin-block: 5px;
  }

  a {
    color: ${({ theme }) => theme.link};
    text-decoration: underline;
  }
`;

const Library = () => {
  const navigate = useNavigate();
  const scrollable = useRef<HTMLDivElement>();
  let { events } = useDraggable(scrollable) as any;
  events = isMobile ? {} : events;

  return (
    <StyledLibrary>
      <div className="scroll" ref={scrollable} {...events}>
        <img src={BiebImg} id="lottie" alt="Bibliotheek logo" />
        <motion.div
          animate={{
            scale: [0, 1],
          }}
          className="header"
        >
          Bibliotheek
        </motion.div>

        <motion.div
          animate={{
            right: ["100%", "0%"],
          }}
          className="main"
        >
          <LibraryItem>
            <h3>Zorgverzekering</h3>
            <p>
              Meer lezen over zorgverzekeringen?{" "}
              <a
                href="https://www.consumentenbond.nl/zorgverzekering/zorgverzekering-18-jaar"
                target="_blank"
                rel="noreferrer"
              >
                Bekijk de website van de consumentenbond
              </a>
              .
            </p>
          </LibraryItem>
          <LibraryItem>
            <h3>Zorgtoeslag</h3>
            <p>
              Meer lezen over je zorgtoeslag?{" "}
              <a
                href="https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/belastingdienst/prive/toeslagen/zorgtoeslag/voorwaarden/ik-ben-meerderjarig"
                target="_blank"
                rel="noreferrer"
              >
                Bekijk de website van de Belastingdienst
              </a>
              .
            </p>
          </LibraryItem>
          <LibraryItem>
            <h3>Donorregister</h3>
            <p>
              Meer lezen over donor worden?{" "}
              <a
                target="_blank"
                href="http://www.donorwise.nl"
                rel="noreferrer"
              >
                Bekijk de website van donorwise
              </a>{" "}
              of{" "}
              <a
                target="_blank"
                href="https://www.transplantatiestichting.nl/jongeren"
                rel="noreferrer"
              >
                van de transplantatiestichting
              </a>
              .
              <br />
              <br />
              Heb je een keuze gemaakt of je donor wilt worden? Geef dit door op{" "}
              <a
                target="_blank"
                href="https://www.donorregister.nl/"
                rel="noreferrer"
              >
                de website van het Donorregister
              </a>
              .
            </p>
          </LibraryItem>
          <LibraryItem>
            <h3>Studiefinanciering</h3>
            <p>
              Meer lezen over stufi voor het MBO?{" "}
              <a
                href="https://www.rijksoverheid.nl/onderwerpen/studiefinanciering/studiefinanciering-mbo"
                target="_blank"
                rel="noreferrer"
              >
                Bekijk de website van de Rijksoverheid
              </a>
              .
            </p>
          </LibraryItem>
          <LibraryItem>
            <h3>Aansprakelijkheidsverzekering</h3>
            <p>
              Meer lezen over motorvoertuigen en
              aansprakelijkheidsverzekeringen?{" "}
              <a
                href="https://www.rijksoverheid.nl/onderwerpen/auto/vraag-en-antwoord/wa-verzekering-voertuig"
                target="_blank"
                rel="noreferrer"
              >
                Bekijk de website van de Rijksoverheid
              </a>
              .
            </p>
          </LibraryItem>
          <LibraryItem>
            <h3>Stagevergoeding</h3>
            <p>
              Meer lezen over het minimumloon voor stages?{" "}
              <a
                href="https://www.rijksoverheid.nl/onderwerpen/minimumloon/vraag-en-antwoord/minimumloon-stage"
                target="_blank"
                rel="noreferrer"
              >
                Bekijk de website van de Rijksoverheid
              </a>
              .
            </p>
          </LibraryItem>
          <LibraryItem>
            <h3>Loonheffingskorting</h3>
            <p>
              Meer lezen over het invullen van je inkomstenbelasting?{" "}
              <a
                target="_blank"
                href="https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/belastingdienst/prive/werk_en_inkomen/jongeren/teruggaaf_jongeren/aangifte_invullen/aangifte_invullen"
                rel="noreferrer"
              >
                Bekijk de website van de Belastingdienst
              </a>
              .
              <br />
              <br />
              Bekijk het loonheffingenformulier voor studenten en scholieren{" "}
              <a
                target="_blank"
                href="https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/themaoverstijgend/programmas_en_formulieren/model_opgaaf_gegevens_voor_de_loonheffingen_studenten_en_scholierenregeling"
                rel="noreferrer"
              >
                op deze website
              </a>
              .
            </p>
          </LibraryItem>
          <LibraryItem>
            <h3>Stemmen</h3>
            <p>
              Meer lezen over de verkiezingen en stemmen?{" "}
              <a
                target="_blank"
                href="https://prodemos.nl/nieuws/goed-voorbereid-naar-de-stembus-prodemos-helpt-met-verkiezingsactiviteiten"
                rel="noreferrer"
              >
                Bekijk de website van Prodemos
              </a>
              .
              <br />
              <br />
              Test bij welke politieke partij jouw voorkeur ligt op{" "}
              <a target="_blank" href="https://stemwijzer.nl/" rel="noreferrer">
                de Stemwijzer
              </a>{" "}
              of{" "}
              <a
                target="_blank"
                href="https://www.kieskompas.nl/nl/"
                rel="noreferrer"
              >
                Het kieskompas
              </a>
              .
              <br />
              <br />
              Meer weten over de aankomende verkiezingen? Lees{" "}
              <a
                target="_blank"
                href="https://prodemos.nl/app/uploads/2023/10/Verkiezingskrant_gewone_taal_tweede_kamer_2023_toegankelijk.pdf"
                rel="noreferrer"
              >
                de Verkiezingskrant
              </a>
              .
            </p>
          </LibraryItem>
          <LibraryItem>
            <h3>Oefenen</h3>
            <p>
              Steffie legt moeilijke dingen op een makkelijke manier uit.
              Bijvoorbeeld DigiD, geldzaken en een bezoek aan de huisarts.
              Bekijk{" "}
              <a
                target="_blank"
                href="https://www.steffie.nl/"
                rel="noreferrer"
              >
                steffie.nl
              </a>
              . <br />
              <br />
              Meer oefenen met taal, rekenen en internet? Bekijk de website{" "}
              <a target="_blank" href="https://oefenen.nl" rel="noreferrer">
                oefenen.nl
              </a>
              . <br />
              <br />
              Wil je meer weten over lezen en schrijven?{" "}
              <a
                target="_blank"
                href="https://www.lezenenschrijven.nl/ik-wil-leren"
                rel="noreferrer"
              >
                Bekijk de website van Stichting Lezen en Schrijven.
              </a>
            </p>
          </LibraryItem>
        </motion.div>
      </div>
      <IconButton
        onClick={() => navigate("/city")}
        className="icon-button back"
        icon="Back"
      />
      <Help
        help={{
          text: "In de bibliotheek vind je links naar meer informatie over de onderwerpen in deze game.",
          characterImage: Noah.Default,
          voiceLine: [HelpVoice],
        }}
      ></Help>
    </StyledLibrary>
  );
};

export default Library;
