import React, { ComponentPropsWithRef, useContext } from "react";

import styled from "styled-components";
import { useUserData } from "../../../data/UserData";
import { useNavigate } from "react-router-dom";
import { gameLinkActive } from "../../../data/GameData";
import Rating from "./Rating/Rating";
import { useAudio } from "../../../assets/audio/Audio";
import { Player } from "@lottiefiles/react-lottie-player";
import { CityContext } from "../City";

const lottie = (lottieFile, callback?) => {
  return (
    <Player
      style={{
        height: "100%",
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
      }}
      src={lottieFile}
      autoplay
      keepLastFrame
      onEvent={(e) => {
        if (e === "complete") {
          callback?.();
        }
      }}
    ></Player>
  );
};

type Props = {
  building: Building;
  closed?: boolean;
};

// left: ${(props) => props.building.left}${(props) => typeof props.building.left === "number" && "px"};
const StyledBuilding = styled.div<Props>`
  position: relative;
  margin-left: ${(props) => props.building.left}px;
  width: ${(props) => props.building.width}${(props) => typeof props.building.width === "number" && "px"};
  //max-height: 40cqh;
  display: flex;
`;

const Building = (
  props: ComponentPropsWithRef<typeof StyledBuilding> & Props
) => {
  const { building } = props;
  const audio = useAudio();
  const { currentStage, incrementStage, userData, currentModule } =
    useUserData();
  const navigate = useNavigate();
  const { setDoorAnimElem, setOverlayConversation } = useContext(CityContext);

  const { currentModule: module, currentStage: stage } = userData.progression;

  const score = userData.progression.moduleRatings[building.linkedModule];
  const closedUntil = building.closedUntil ?? [-1, -1];

  const completed = userData.progression.modulesCompleted.includes(
    building.linkedModule
  );

  const hasCheckpoint = userData.progression.checkpoints.length > 0;

  const reached =
    module > closedUntil[0] ||
    (module === closedUntil[0] && stage >= closedUntil[1]);

  const closed =
    !reached ||
    (building.linkedModule &&
      hasCheckpoint &&
      building.linkedModule !== module);

  const showRating =
    !userData.demo && completed && building.linkedModule !== module;

  const clickBuilding = () => {
    // Building closed
    if (closed) {
      if (building.closedCharacter) {
        setOverlayConversation({
          config: {
            type: "help",
            buttonText: "Verder",
            steps: [
              "Dit gebouw is nog gesloten en moet je eerst vrijspelen. Kom je later terug?",
            ],
            right: true,
            characterImage: building.closedCharacter,
            backgroundImage: building.closedBackground,
          },
          close: () => setOverlayConversation(null),
        });
      }
      return;
    }

    // CurrentStage is goToBuilding
    if (
      currentStage?.type === "goToBuilding" &&
      currentStage.building === building.name
    ) {
      incrementStage();
      if (!currentStage.shouldNavigate) {
        return;
      }
    }

    // Enter building
    let goTo = building.goTo;
    if (
      building.linkedModule !== undefined &&
      module !== building.linkedModule
    ) {
      goTo = completed ? "/rating" : "/play-module";
    }
    if (goTo) {
      const navigateGoTo = () =>
        navigate(goTo, {
          state: { module: building.linkedModule },
        });
      if (
        goTo === "/game" &&
        !gameLinkActive(currentModule, currentStage, building)
      ) {
        return;
      }
      if (building.playSound) audio.play(building.playSound);
      if (building.lottie) {
        setDoorAnimElem(lottie(building.lottie, navigateGoTo));
      } else navigateGoTo();
    }
  };

  if (building.hidden) return null;

  return (
    <StyledBuilding
      {...props}
      onClick={clickBuilding}
      style={{
        cursor: closed ? "default" : "pointer",
      }}
      role="link"
      aria-label={building.name}
      tabIndex={0}
    >
      {showRating && <Rating rating={score}></Rating>}
      <img
        src={
          closed && building.closedImage ? building.closedImage : building.image
        }
        alt={building.name}
        style={{
          objectPosition: "bottom",
          objectFit: "contain",
          maxHeight: "100%",
          maxWidth: "100%",
        }}
      />
    </StyledBuilding>
  );
};

export default Building;
