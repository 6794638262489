import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

window.addEventListener("swUpdated", (event) => {
  window["swUpdate"] = event["detail"].registration;
});

const Toast = motion(styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: ${(props) => props.theme.main};
  display: flex;
  justify-content: space-between;
  color: white;
  padding: 10px;
  font-weight: 500;

  span {
    color: ${(props) => props.theme.yellow.light};
    cursor: pointer;
  }
`);

const ServiceWorkerUpdate = () => {
  const [swUpdate, setSwUpdate] = useState<ServiceWorkerRegistration>(
    window["swUpdate"]
  );

  useEffect(() => {
    // check if service has an update
    const listener = (event) => {
      setSwUpdate(event["detail"].registration);
    };
    window.addEventListener("swUpdated", listener);
    return () => {
      window.removeEventListener("swUpdated", listener);
    };
  }, []);

  useEffect(() => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.getRegistration().then((registration) => {
        if (registration?.waiting) {
          setSwUpdate(registration);
        }
      });
    }
  }, []);

  const update = () => {
    const sw: ServiceWorker = swUpdate?.waiting;

    if (sw) {
      sw.onstatechange = () => {
        if (sw.state === "activated") {
          window.location.reload();
        }
      };

      sw.postMessage({
        type: "SKIP_WAITING",
      });
    }
  };

  return (
    <>
      {swUpdate && (
        <Toast
          animate={{
            y: 0,
          }}
          initial={{
            y: -100,
          }}
        >
          <div>Update beschikbaar</div>
          <span onClick={update}>Update</span>
        </Toast>
      )}
    </>
  );
};

export default ServiceWorkerUpdate;
