import "./City.scss";
import React, {
  createContext,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDraggable } from "react-use-draggable-scroll";
import { isMobile } from "react-device-detect";
import { Player } from "@lottiefiles/react-lottie-player";
import { useRedirect } from "../../util/useRedirect";
import { AnimatePresence, motion } from "framer-motion";

import Conversation from "../../interactions/Conversation/Conversation";
import { useUserData } from "../../data/UserData";
import Settings from "../Settings/Settings";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import { ParallaxPavement } from "./ParallaxPavement";

import Car from "./car.lottie";
import Mission from "./Mission";
import { useScrollPosition } from "../../util/useScrollPosition";
import useAchievements from "../../util/useAchievements";
import StarBar from "../../components/ProgressBar/StarBar";
import PopUp from "../../components/PopUp/PopUp";
import ThemeButton from "../../components/ThemeButton/ThemeButton";

type OverlayType = { config: ConversationConfig; close?: () => void };
export const CityContext = createContext<{
  setDoorAnimElem: (elem: ReactNode) => void;
  setOverlayConversation: (elem: OverlayType) => void;
}>(null);

const City = () => {
  useAchievements();
  useRedirect();
  const { currentStage, incrementStage } = useUserData();

  const [scrollElement, setScrollElement] = useState<HTMLDivElement>(null);

  const scrollContainer = useRef<HTMLDivElement>();
  useEffect(() => {
    setScrollElement(scrollContainer.current);
  }, []);
  const { events } = useDraggable(scrollContainer);

  useScrollPosition(scrollContainer);

  const [activeOverlay, setActiveOverlay] = useState<OverlayType>(null);

  const hasPopup = currentStage?.type === "popup";

  const [doorAnimationElement, setDoorAnimationElement] =
    useState<ReactNode>(null);

  useEffect(() => {
    if (currentStage?.type === "conversation" && currentStage.inCity) {
      setTimeout(() => setActiveOverlay({ config: currentStage }), 1000);
    } else {
      setActiveOverlay(null);
    }
  }, [currentStage]);

  return (
    <div className="City">
      <div
        className="scroll-x scrollbar-hidden"
        {...(isMobile ? {} : events)}
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
        }}
        ref={scrollContainer}
      >
        <div className="city-width">
          {/* !! Typings aren't correct */}
          {/* @ts-ignore */}
          <ParallaxProvider
            scrollAxis="horizontal"
            scrollContainer={scrollElement}
          >
            <div className="sky"></div>
            <Parallax speed={35} className="skyline"></Parallax>
            <Parallax speed={70} className="trees"></Parallax>
            <CityContext.Provider
              value={{
                setDoorAnimElem: setDoorAnimationElement,
                setOverlayConversation: setActiveOverlay,
              }}
            >
              <ParallaxPavement></ParallaxPavement>
            </CityContext.Provider>
            <Parallax speed={135} className="road"></Parallax>
            <Player
              className="lottie"
              style={{
                bottom: 0,
                width: 200,
                animation: "driving-car 6s linear infinite",
              }}
              autoplay
              loop
              src={Car}
            ></Player>
            <Parallax speed={235} className="pavement-front"></Parallax>
          </ParallaxProvider>
        </div>
      </div>

      <AnimatePresence mode="wait">
        {!doorAnimationElement && activeOverlay && (
          <motion.div
            animate={{
              opacity: 1,
            }}
            initial={{
              opacity: 0,
            }}
            exit={{
              opacity: 0,
            }}
            transition={{
              duration: 0.4,
              ease: "easeOut",
              delayChildren: 0.4,
            }}
            className="overlay"
          >
            <Conversation {...activeOverlay}></Conversation>
          </motion.div>
        )}
      </AnimatePresence>
      {!activeOverlay && (
        <>
          <StarBar right={20 + 20 + 32}></StarBar>
          <Settings x={20} y={20}></Settings>
          <Mission></Mission>
        </>
      )}
      {doorAnimationElement && (
        <div className="door-anim">{doorAnimationElement}</div>
      )}
      {hasPopup && (
        <PopUp
          style={{
            padding: 20,
            display: "flex",
            flexDirection: "column",
            gap: 20,
          }}
        >
          <div
            style={{
              fontSize: "1.25em",
              fontWeight: "700",
            }}
          >
            {currentStage.header}
          </div>
          <div>{currentStage.body}</div>
          <ThemeButton onClick={incrementStage}>
            {currentStage.button}
          </ThemeButton>
        </PopUp>
      )}
    </div>
  );
};

export default City;
