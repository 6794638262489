import React, { ComponentPropsWithRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useAudio } from "../../assets/audio/Audio";

type Color = "light" | "main" | "dark";

interface Props {
  inner?: boolean;
  gradient?: [Color, Color];
  playSound?: boolean;
  radius?: number;
}

export const Circle = motion(styled.div<Props>`
  overflow: hidden;

  display: grid;
  place-items: center;
  border-radius: 50%;
  position: relative;

  &::before {
    content: "";
    display: block;
    padding-top: 100%;
    clear: both;
    width: 100%;
    background: linear-gradient(
      to bottom,
      ${({ theme, gradient }) => theme[gradient[0]]},
      ${({ theme, gradient }) => theme[gradient[1]]}
    );
  }

  &[data-inner] {
    position: absolute;
    top: ${(props) => props.radius}px;
    left: ${(props) => props.radius}px;
    right: ${(props) => props.radius}px;
    bottom: ${(props) => props.radius}px;
  }
`);

Circle.defaultProps = {
  gradient: ["dark", "light"],
};

interface CirclesProps extends Props {
  gradients?: Props["gradient"][];
  radius?: number;
}

const Circles = ({
  children,
  gradients = [
    ["dark", "light"],
    ["dark", "main"],
  ],
  variants,
  animate,
  initial,
  transition,
  playSound = true,
  radius = 50,
  theme,
  ...props
}: ComponentPropsWithRef<typeof Circle> & CirclesProps) => {
  const anim = { variants, animate, initial, transition };
  const audio = useAudio();

  return (
    <Circle
      theme={theme}
      gradient={gradients[0]}
      {...props}
      {...anim}
      onAnimationStart={
        playSound
          ? () => {
              audio.play("swoosh");
            }
          : undefined
      }
    >
      <Circle
        data-inner
        theme={theme}
        gradient={gradients[1]}
        custom={0.2}
        transition={{
          ...transition,
          delay: (transition?.delay ?? 0) + 0.2,
        }}
        {...anim}
        radius={radius}
      >
        {children}
      </Circle>
    </Circle>
  );
};

export default Circles;
