import { motion, useAnimationControls } from "framer-motion";
import styled, { useTheme } from "styled-components";
import Theme from "../../theme";
import React, { ComponentPropsWithRef, createRef } from "react";
import { sparkle } from "./Sparkle/Sparkle";

const StyledButton = motion(styled.button`
  all: unset;

  cursor: pointer;
  background: white;
  border: 5px solid ${(props) => props.theme.dark};
  border-radius: 13px;
  padding: 5px;
  min-height: 70px;
  box-sizing: border-box;
`);
const green = Theme("green");
const red = Theme("red");
const Answer = ({
  answeredTheme = green,
  wrongTheme = red,
  multi,
  ...props
}: ComponentPropsWithRef<typeof StyledButton>) => {
  const animate = useAnimationControls();
  const theme: any = useTheme();
  const ref = createRef<HTMLButtonElement>();

  const onClick = (e) => {
    const correct = props.onClick && props.onClick();
    if (correct) {
      sparkle(e);
      if (multi) {
        animate.start({
          color: "#fff",
          backgroundColor: answeredTheme.light,
          borderColor: answeredTheme.dark,
          pointerEvents: "none",
        });
      } else {
        animate.start({
          // opacity: [1, 0],
          scale: 1.1,
          opacity: 0,
          pointerEvents: "none",
          backgroundColor: answeredTheme.light,
          borderColor: answeredTheme.dark,
          color: "#fff",
          transition: {
            opacity: {
              delay: 0.4,
            },
          },
        });
      }
    } else {
      animate.start({
        scale: [1, 1.1, 1],
        color: [theme.dark, "#fff", theme.dark],
        backgroundColor: ["#fff", wrongTheme.light, "#fff"],
        borderColor: [theme.dark, wrongTheme.dark, theme.dark],
      });
    }
  };

  return (
    <StyledButton
      ref={ref}
      {...props}
      animate={animate}
      onClick={onClick}
    ></StyledButton>
  );
};

export default Answer;
