import achievement from "./sounds/achievement.mp3";
import buttonPress from "./sounds/btn-click-3.wav";
import collectStar from "./sounds/Collect-Star.mp3";
import door1 from "./sounds/door-1.wav";
import door2 from "./sounds/door-2.wav";
import menuClose from "./sounds/Menu-Close.mp3";
import menuOpen from "./sounds/Menu-Open.mp3";
import pop from "./sounds/pop.wav";
import swoosh from "./sounds/Swoosh.mp3";
import wrongAnswer from "./sounds/wrong-answer.mp3";

export const Sounds = {
  achievement,
  buttonPress,
  collectStar,
  door1,
  door2,
  menuClose,
  menuOpen,
  pop,
  swoosh,
  wrongAnswer,
};
