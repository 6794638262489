import React, {
  ComponentPropsWithRef,
  useContext,
  useEffect,
  useState,
} from "react";
import { useUserData } from "../../data/UserData";
import { useAudio } from "../../assets/audio/Audio";
import styled from "styled-components";
import IconButton from "../../components/IconButton/IconButton";
import Circles from "../../components/Circles/Circles";
import {
  AnimatePresence,
  motion,
  MotionConfig,
  MotionConfigContext,
} from "framer-motion";
import Theme from "../../theme";
import Answer from "./Answer";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import Help from "../../views/Help/Help";

interface Props {
  config: IBubblePopperInteraction;
}

const StyledBubbleInteraction = styled.div<Partial<Props>>`
  height: 100%;
  position: relative;
  overflow-y: auto;

  color: ${(props) => props.theme.dark};
  font-weight: 700;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;

  .icon-button {
    position: absolute;
    top: 20px;

    &.back {
      left: 20px;
    }

    &.help {
      right: 20px;
    }
  }

  .question-container {
    margin-top: 52px;
    flex: 1 0;
    display: flex;
  }

  .question {
    font-size: 1.5em;
    text-align: center;
    padding-inline: 20px;
    margin-block: auto;
  }

  .answers-container {
    width: 100%;
    overflow-x: auto;
  }

  .answers {
    position: relative;
    bottom: 0;
    min-width: min-content;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    padding-bottom: 20px;
    padding-inline: 20px;
  }

  .bottom {
    position: relative;
    bottom: 0;
    width: 100%;
    display: grid;
    place-items: center;
    overflow: hidden;
    padding-top: 45px;
    flex-shrink: 0;
  }
`;

const yellow = Theme("yellow");
const BubbleInteraction = ({
  config,
  ...props
}: ComponentPropsWithRef<typeof StyledBubbleInteraction> & Props) => {
  const { incrementStage, applyPenalty } = useUserData();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [questions, setQuestions] = useState(config.questions);
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>([]);
  const question = questions[currentQuestion];
  const audio = useAudio();

  useEffect(() => {
    setSelectedAnswers([]);
  }, [currentQuestion]);

  const checkAnswer = (answer: string) => {
    if (question.answers.includes(answer)) {
      audio.play("pop");
      const selected = [...selectedAnswers, answer];
      if (selected.length === question.answers.length) {
        if (currentQuestion === questions.length - 1) {
          incrementStage();
        }
        setCurrentQuestion(currentQuestion + 1);
      }
      setSelectedAnswers(selected);
      return true;
    } else if (config.penalty > 0) {
      audio.play("wrongAnswer");
      applyPenalty(config.penalty);
      return false;
    }
  };

  const skipQuestion = () => {
    if (question.canSkip) {
      // remove question from array
      const before = questions.slice(0, currentQuestion);
      const after = questions.slice(currentQuestion + 1);

      let newOrder = [...before, ...after];

      // find place to insert it again
      const skippable = after.filter((q) => q.canSkip);
      const last = skippable[skippable.length - 1];
      const index = newOrder.indexOf(last);
      if (index !== -1) {
        newOrder = [
          ...newOrder.slice(0, index + 1),
          question,
          ...newOrder.slice(index + 1),
        ];
        setQuestions(newOrder);
      }
    }
  };

  const isAnswered = (answer: string) => {
    const slice = questions.slice(0, currentQuestion);
    return (
      selectedAnswers.includes(answer) ||
      slice.some((question) => question.answers.includes(answer))
    );
  };

  const answered = config.answers.filter(isAnswered).length;
  const all = config.questions.flatMap((q) => q.answers).length;
  const progress = (answered / all) * 100;

  let help = {
    ...config?.help,
    ...question?.help,
  };

  if (!help.characterImage || !help.text) {
    help = undefined;
  }

  const transition = useContext(MotionConfigContext).transition as any;
  const multi = question?.answers.length > 1;
  return (
    <StyledBubbleInteraction {...props}>
      <div className="question-container">
        <AnimatePresence mode="wait">
          <motion.div
            key={question?.question}
            className="question"
            animate={{
              scale: [0, 1],
              transition: {
                ...transition,
                duration: currentQuestion === 0 ? 0.8 : 0.4,
              },
            }}
            exit={{
              scale: [1, 0],
              transition: {
                ...transition,
                duration: 0.4,
              },
            }}
            aria-live="polite"
          >
            {question?.question}
            {question?.canSkip && currentQuestion < questions.length - 1 && (
              <IconButton playSound={true} onClick={skipQuestion} icon="Skip" />
            )}
          </motion.div>
        </AnimatePresence>
      </div>
      <div className="bottom">
        <Circles
          theme={multi ? yellow : undefined}
          gradients={[
            ["dark", "main"],
            ["main", "light"],
          ]}
          animate={{
            y: [300, 0],
          }}
          transition={transition}
          style={{
            position: "absolute",
            top: 0,
            left: "-100%",
            right: "-100%",
            zIndex: -1,
          }}
          radius={50}
        ></Circles>
        <div className="answers-container">
          <motion.div
            animate={{
              y: ["100%", "0%"],
            }}
            className="answers"
            data-multi={multi}
          >
            <MotionConfig
              transition={{
                ...transition,
                duration: 0.4,
              }}
            >
              {config.answers.map((answer) => (
                <Answer
                  multi={multi}
                  key={answer}
                  aria-hidden={isAnswered(answer)}
                  onClick={() => checkAnswer(answer)}
                >
                  {answer}
                </Answer>
              ))}
            </MotionConfig>
          </motion.div>
        </div>
      </div>
      <ProgressBar
        style={{
          right: help ? 20 + 32 + 10 : 20,
        }}
        progress={progress}
      >
        {answered} / {all}
      </ProgressBar>
      {help && <Help help={help}></Help>}
    </StyledBubbleInteraction>
  );
};

export default BubbleInteraction;
