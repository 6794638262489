import React, { ComponentPropsWithRef } from "react";
import ProgressBar, { Progress, StyledProgressBar } from "./ProgressBar";
import { useUserData } from "../../data/UserData";
import Theme from "../../theme";
import Star from "../../assets/stars/star-gold-1.svg";
import { gameData } from "../../data/GameData";

interface Props {}

const invertTheme = ({ dark, light }) => {
  return {
    dark: light,
    light: dark,
  };
};

const theme = invertTheme(Theme("yellow"));
const StarBar = (props: Props & ComponentPropsWithRef<typeof ProgressBar>) => {
  const { userData } = useUserData();

  let stars: number;
  let maxStars: number;

  const completedModules = userData.progression.modulesCompleted;
  const notCompletedModules =
    gameData.modules.length - completedModules.length - 2;

  maxStars = completedModules.length * 3 + notCompletedModules * 3;
  stars = completedModules
    .map((m) => userData.progression.moduleRatings[m])
    .map((r) => (r < 50 ? 1 : r < 100 ? 2 : 3))
    .reduce((a, b) => a + b, 0);

  const progress = (stars / maxStars) * 100;

  return (
    <StyledProgressBar
      role="text"
      aria-label={`Je hebt ${stars} van de ${maxStars} sterren`}
      {...props}
    >
      <Progress progress={progress} theme={theme}></Progress>
      <span aria-hidden>
        {stars}/{maxStars}
      </span>
      <img aria-hidden alt="Ster" src={Star} />
    </StyledProgressBar>
  );
};

export default StarBar;
