import React, { ComponentProps } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { PageAnimation } from "./PageAnimation";

const StyledPage = styled.div.withConfig({ componentId: "page" })`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Page = ({ children }: ComponentProps<typeof StyledPage>) => {
  return (
    <StyledPage as={motion.div} {...PageAnimation}>
      {children}
    </StyledPage>
  );
};

export default Page;
