import React, { ComponentProps, useState } from "react";
import styled, { useTheme } from "styled-components";

import IconButton from "../../components/IconButton/IconButton";
import { useAudio } from "../../assets/audio/Audio";
import { useUserData } from "../../data/UserData";
import merge from "lodash/merge";
import PopUp from "../../components/PopUp/PopUp";

import RaccoonLogo from "../../assets/logos/raccoon.svg";
import LsLogo from "../../assets/logos/lsLogo.svg";
import AppLogo from "../../assets/logos/app-icon.svg";
import Theme from "../../theme";

const SettingsModal = styled.div<{ x?: number; y?: number }>`
  display: contents;

  > button {
    position: absolute;
    top: ${(props) => props.y ?? "20"}px;
    right: ${(props) => props.x ?? "20"}px;
  }
`;

const red = Theme("red");

const build = process.env.REACT_APP_VERSION ?? "dev";
const date = process.env.REACT_APP_DATE
  ? new Date(process.env.REACT_APP_DATE).toISOString()
  : new Date().toISOString();

const StyledSettings = styled.div`
  height: 100%;
  background: white;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    align-items: center;

    span {
      flex: 1;
      text-align: center;
    }
  }

  .settings-body {
    display: flex;
    flex-direction: column;
    flex: 1;

    .settings {
      display: grid;
      grid-template-columns: auto min-content;
      align-items: center;
      margin-block-start: 40px;
    }

    .version {
      font-size: 0.75em;
      color: ${(props) => props.theme.white.dark};
      font-weight: 700;
      text-align: center;

      .app-logo {
        width: 50px;
        filter: grayscale(1);
        transition: filter 0.2s ease-in-out;
        &:hover {
          filter: grayscale(0);
        }
      }
    }

    .logos-wrapper {
      font-size: 0.75em;
      text-align: center;
      font-weight: 700;

      .logos {
        display: flex;
        max-height: 46px;
        justify-content: center;
        gap: 20px;
        margin-block: 5px;

        img {
          object-fit: contain;
          width: 84px;
          height: 46px;
        }
      }
    }
  }
`;

const Settings = ({
  open: startOpen = false,
  alwaysOpen = false,
  ...props
}: ComponentProps<typeof SettingsModal>) => {
  const { setUserData, userData } = useUserData();
  const [settingsOpen, setSettingsOpen] = useState(alwaysOpen || startOpen);
  const audio = useAudio();
  const theme = useTheme();

  const volume = userData.settings.soundVolume;
  const muted = volume === 0;

  const toggleMute = () => {
    setUserData((data) =>
      merge({}, data, { settings: { soundVolume: muted ? 1 : 0 } })
    );
  };

  const open = () => {
    setSettingsOpen(true);
    audio.play("menuOpen");
  };

  const close = () => {
    setSettingsOpen(false);
    audio.play("menuClose");
  };

  return (
    <SettingsModal {...props}>
      {settingsOpen ? (
        <PopUp fullSize borderSize={6}>
          <StyledSettings>
            <div className="header">
              <span className="emphasis">Instellingen</span>
              {!alwaysOpen && (
                <IconButton onClick={close} icon="Close"></IconButton>
              )}
            </div>
            <div className="settings-body">
              <div className="settings">
                <div>Geluidseffecten:</div>
                <IconButton
                  size={48}
                  onClick={toggleMute}
                  theme={muted ? red : theme}
                  icon={muted ? "Muted" : "Sound"}
                ></IconButton>
              </div>
              <div style={{ flex: 1 }}></div>
              <div className="logos-wrapper">
                Life@18 is gemaakt door:
                <div className="logos">
                  <img src={LsLogo} alt="Stichting Lezen en Schrijven logo" />
                  <img src={RaccoonLogo} alt="Raccoon Serious Games logo" />
                </div>
              </div>
              <div className="version">
                <div title={date}>versienummer {build}</div>
                <img className="app-logo" src={AppLogo} alt="Life@18" />
              </div>
            </div>
          </StyledSettings>
        </PopUp>
      ) : (
        <IconButton icon="Settings" onClick={open}></IconButton>
      )}
    </SettingsModal>
  );
};

export default Settings;
