import React, { ComponentProps } from "react";
import styled from "styled-components";

const StyledBalloon = styled.div<{ variant?: string; showFliebel?: boolean }>`
  margin: 0 20px;

  .wrapper {
    margin-${({ variant }) => (variant === "left" ? "right" : "left")}: auto;
    border: 5px solid ${({ theme }) => theme.main};
    padding: 10px 20px;
    background: white;
    color: ${({ theme }) => theme.dark};
    border-radius: 25px;
    // TODO extract shadow color to variable
    filter: drop-shadow(0 5px 6px #44249140);
    position: relative;
    white-space: pre-wrap;
    width: fit-content;
  }

  svg {
    position: absolute;
    top: calc(100% - 4px);
    width: 32px;
    height: 32px;

    ${({ variant }) => {
      return `
                ${variant}: 64px;
                ${variant === "right" ? "transform: scaleX(-1);" : ""}
            `;
    }}

    .cls-1 {
      fill: #fff;
    }

    .cls-2 {
      fill: ${({ theme }) => theme.main};
    }
  }
`;

StyledBalloon.defaultProps = {
  variant: "left",
};

const TextBubble = ({
  children,
  showFliebel = true,
  ...props
}: ComponentProps<typeof StyledBalloon>) => (
  <StyledBalloon {...props}>
    <div className="wrapper">
      {children}
      {showFliebel && (
        <svg
          id="Layer_2"
          data-name="Layer 2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 40.91962 30.79183"
        >
          <g id="OBJECTS">
            <g id="Group_40" data-name="Group 40">
              <path
                className="cls-1"
                d="m36.17963,0c-.90002,0-1.74005.41998-2.30005,1.13-2.72998,3.46002-5.58997,6.48999-8.14996,8.96997-5.46002,5.39001-11.58002,10.06-18.22003,13.90002,1.90002-4.13,2.84003-8.64001,2.75-13.17999-.01001-2.73999-.16998-5.47998-.47998-8.20001-.15997-1.47998-1.39001-2.59998-2.88-2.62h29.28003Z"
              />
              <path
                className="cls-2"
                d="m40.91962,0c-3.75,5.45001-8.02002,10.12-11.71002,13.70001-8.46997,8.20996-17.22998,13.98999-22.15002,16.08002-.91998.41998-1.88995.71997-2.87994.89996-1.30005.34003-2.68005-.10999-3.54004-1.14996-.71997-.98004-.83997-2.28003-.32001-3.38.36005-.82001.82001-1.58002,1.39001-2.27002,2.28003-2.92999,3.47998-7.34998,3.54999-13.13-.01996-3.60999-.32001-7.20001-.87-10.75h2.51001c1.48999.02002,2.72003,1.14001,2.88,2.62.31,2.72003.46997,5.46002.47998,8.20001.09003,4.53998-.84998,9.04999-2.75,13.17999,6.64001-3.84003,12.76001-8.51001,18.22003-13.90002,2.56-2.47998,5.41998-5.50995,8.14996-8.96997.56-.71002,1.40002-1.13,2.30005-1.13h4.73999Z"
              />
            </g>
          </g>
        </svg>
      )}
    </div>
  </StyledBalloon>
);

export default TextBubble;
