import React, {
  ComponentPropsWithRef,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import styled, { useTheme } from "styled-components";
import { useUserData } from "../../data/UserData";
import { useAudio } from "../../assets/audio/Audio";
import {
  AnimatePresence,
  motion,
  MotionConfig,
  MotionConfigContext,
  Variants,
} from "framer-motion";
import Circles from "../../components/Circles/Circles";
import Answer from "./Answer";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import Help from "../../views/Help/Help";
import Theme from "../../theme";
import { sparkle } from "../BubbleInteraction/Sparkle/Sparkle";
import ResultConversation from "../../components/ResultConversation/ResultConversation";
import shuffle from "lodash/shuffle";

interface Props {
  config: TrueFalseInteraction;
}

const StyledTrueFalse = styled.div<Partial<Props>>`
  height: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;

  color: ${(props) => props.theme.dark};
  font-weight: 700;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;

  .icon-button {
    position: absolute;
    top: 20px;

    &.back {
      left: 20px;
    }

    &.help {
      right: 20px;
    }
  }

  .question {
    margin-top: 52px;
    font-size: 1.5em;
    flex: 1 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-inline: 20px;
  }

  .bottom {
    position: relative;
    width: 100%;
    display: grid;
    place-items: center;
    overflow: hidden;
    padding: 45px 20px 20px;
    flex-shrink: 0;
  }

  .answers {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;

    button {
      box-sizing: border-box;
      min-height: 70px;
      width: 100%;

      @media (min-height: 700px) {
        padding: 10px;
      }
    }
  }
`;

const A = "A".charCodeAt(0);
const enumeratedAnswers = (answers) => {
  return answers.map((a, index) => {
    const letter = String.fromCharCode(A + index);

    return {
      ...a,
      answer: `${letter}. ${a.answer}`,
    };
  });
};

const [green, red] = [Theme("green"), Theme("red")];
const TrueFalse = ({
  config,
  ...props
}: Props & ComponentPropsWithRef<typeof StyledTrueFalse>) => {
  const { incrementStage, applyPenalty, getCompiled } = useUserData();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [result, setResult] = useState<{ text; correct; index; element }>(null);
  const audio = useAudio();
  const transition = useContext(MotionConfigContext).transition as any;
  const theme = useTheme() as any;

  let {
    answer,
    correctText = "Goed zo!",
    help,
    incorrectText = "Helaas is dat antwoord niet juist.",
    question,
    penalty = 0,
    answers = ["Waar", "Niet waar"] as any,
    shuffleAnswers = false,
    enumerateAnswers = false,
  } = { ...config, ...config.questions[currentQuestion] };

  question = getCompiled(question);
  answers = useMemo(() => {
    let newAnswers = answers.map((answer, index) => ({
      answer: getCompiled(answer),
      index,
    }));
    if (shuffleAnswers) newAnswers = shuffle(newAnswers);
    if (enumerateAnswers) newAnswers = enumeratedAnswers(newAnswers);
    return newAnswers;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestion]);

  const answersRef = useRef<HTMLDivElement>(null);

  const answerVariants: Variants = {
    initial: {
      scale: 1,
      opacity: 1,
      color: theme.dark,
      backgroundColor: "#fff",
      borderColor: theme.dark,
    },
    correct: {
      scale: 1.1,
      opacity: 1,
      color: "#fff",
      backgroundColor: green.light,
      borderColor: green.dark,
      position: "absolute",
      top: [result?.element.offsetTop, 0],
    },
    incorrect: {
      scale: 1.1,
      opacity: 1,
      color: "#fff",
      backgroundColor: red.light,
      borderColor: red.dark,
      position: "absolute",
      top: [result?.element.offsetTop, 0],
    },
    hidden: {
      opacity: 0,
      scale: 1,
      pointerEvents: "none",
    },
  };

  const checkAnswer = (chosenAnswer: number, event) => {
    // blur element
    event.target.blur();
    if (answer === chosenAnswer) {
      audio.play("pop");
      sparkle(event);
      setResult({
        text: correctText,
        correct: true,
        index: chosenAnswer,
        element: event.target,
      });
    } else {
      audio.play("wrongAnswer");
      setResult({
        text:
          typeof incorrectText === "function"
            ? incorrectText(chosenAnswer)
            : incorrectText,
        correct: false,
        index: chosenAnswer,
        element: event.target,
      });
      applyPenalty(penalty);
    }
  };

  const progress = (currentQuestion / config.questions.length) * 100;

  const closeResult = () => {
    setResult(null);
    if (currentQuestion === config.questions.length - 1) {
      incrementStage();
    }
    setCurrentQuestion((prev) => prev + 1);
  };

  return (
    <StyledTrueFalse {...props}>
      <AnimatePresence mode="wait">
        <motion.div
          aria-live="polite"
          key={question}
          className="question"
          animate={{
            scale: [0, 1],
            transition: {
              ...transition,
              duration: currentQuestion ? 0.4 : 0.8,
            },
          }}
          exit={{
            scale: 0,
            transition: {
              ...transition,
              duration: 0.4,
            },
          }}
        >
          {question}
        </motion.div>
      </AnimatePresence>

      <div className="bottom">
        <Circles
          gradients={[
            ["dark", "main"],
            ["main", "light"],
          ]}
          animate={{
            y: [300, 0],
          }}
          transition={transition}
          style={{
            position: "absolute",
            top: 0,
            left: "-100%",
            right: "-100%",
            zIndex: -1,
          }}
          radius={50}
        ></Circles>
        <motion.div
          animate={{
            y: ["100%", "0%"],
          }}
          className="answers"
          ref={answersRef}
        >
          <MotionConfig
            transition={{
              ...transition,
              duration: 0.4,
            }}
          >
            {answers.map((answer, index) => {
              return (
                <Answer
                  key={index}
                  onClick={(e) => checkAnswer(answer.index, e)}
                  variants={answerVariants}
                  animate={result ? "hidden" : "initial"}
                >
                  {answer.answer}
                </Answer>
              );
            })}
            {result && (
              <Answer
                variants={answerVariants}
                animate={result.correct ? "correct" : "incorrect"}
              >
                {answers.find((a) => a.index === result.index).answer}
              </Answer>
            )}
          </MotionConfig>
        </motion.div>
      </div>
      <ProgressBar
        style={{
          right: help ? 20 + 32 + 10 : 20,
        }}
        progress={progress}
      ></ProgressBar>
      {help && <Help help={help}></Help>}
      <AnimatePresence>
        {result && (
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
          >
            <ResultConversation
              close={closeResult}
              text={result.text}
              correct={result.correct}
              character={
                result.correct
                  ? config.correctCharacter
                  : config.incorrectCharacter
              }
            />
          </motion.div>
        )}
      </AnimatePresence>
    </StyledTrueFalse>
  );
};

export default TrueFalse;
