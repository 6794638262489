import OneStarImg from "./char-1-star.svg";
import TwoStarsImg from "./char-2-stars.svg";
import ThreeStarsImg from "./char-3-stars.svg";
import FourStarsImg from "./char-4-stars.svg";
import FiveStarsImg from "./char-5-stars.svg";

import DefaultImg from "./char-default.svg";
import BlissImg from "./char-bliss.svg";
import ContentImg from "./char-content.svg";
import PhoneCallingImg from "./char-phone-calling.svg";
import PhoneWinkImg from "./char-phone-wink.svg";
import SlightlySmilingImg from "./char-slightly-smiling.svg";
import SmilingImg from "./char-smiling.svg";
import SweatImg from "./char-sweat.svg";
import ThumbsUpImg from "./char-thumbs-up.svg";

const Noah = {
  Default: DefaultImg,
  Bliss: BlissImg,
  Content: ContentImg,
  PhoneCalling: PhoneCallingImg,
  PhoneWink: PhoneWinkImg,
  SlightlySmiling: SlightlySmilingImg,
  Smiling: SmilingImg,
  Sweat: SweatImg,
  ThumbsUp: ThumbsUpImg,

  OneStar: OneStarImg,
  TwoStars: TwoStarsImg,
  ThreeStars: ThreeStarsImg,
  FourStars: FourStarsImg,
  FiveStars: FiveStarsImg,
};

export default Noah;
