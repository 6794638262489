/*
* Onderdeel	Character	Tekst	Opmerking	L&S aanpassing
Missie		Straks heb ik stemrecht. Dit betekent dat ik mag stemmen.
Missie		Maar hoe moet dat eigenlijk? Laten we dat eens gaat uitzoeken!

Intro	Default	Goed dat je er bent!
Intro	Default	Ben je 18 jaar of ouder? Dan mag je nu gaan stemmen!
Intro	Pointing	Met jouw stem laat je weten wat jij belangrijk vindt. In de politiek en in de maatschappij. Moet de studiefinanciering omhoog? Hoe zorgen we ervoor dat er minder mensen arm zijn?
Intro	Pointing	Het is belangrijk om te stemmen. Zo laat je horen wat jij belangrijk vindt voor jezelf. Én voor de rest van Nederland!
Intro	Default	"Stemmen doe je bijvoorbeeld voor:
- de Tweede Kamer
- de Gemeenteraad
- de Provinciale Staten"
Intro	Default	"Stemmen, hoe werkt dat?
Zet de stappen in de goede volgorde!"

Volgorde		Je krijgt een stempas thuis
Volgorde		Kies een politieke partij
Volgorde		Ga naar het stembureau
Volgorde		Lever je stempas in en laat je ID-kaart zien
Volgorde		Krijg een stembiljet
Volgorde		Kleur 1 vakje rood
Volgorde		Doe je stembiljet in de stembus

Stemoefening	Default	"Je maakt het rondje rood bij de naam van de  persoon op wie je wilt stemmen.

Let op: Je mag echt maar 1 rondje rood kleuren."
Stemoefening	Pointing	We gaan dit nu oefenen. 	Extra scherm wat uitlegt dat we gaan oefenen met fictieve partijen en personen
Stemoefening		Stem op partij ZON
Stemoefening		Stem op partij Klomp
Stemoefening		Stem op Z. Nowak van partij Klomp
Feedback verkeerde partij	Pointing	Je stemt op de partij door op een persoon te stemmen die onder deze partij staat. Probeer het nog eens!
Feedback verkeerde partij verkeerde persoon	Pointing	Zoek onder partij 'Klomp' naar Z.Nowak. Kruis dat vakje aan Probeer het nog eens!
Feedback goede partij verkeerde persoon	Pointing	Je hebt de goede partij gekozen, maar nog niet de juiste persoon. Zoek de naam Z. Nowak in de rij van partij 'Klomp'.
Feedback twee kruisjes	Pointing	Een stem is alleen geldig als er maar 1 vakje is ingekleurd. Probeer het nog eens!

Woordenpopper 1 intro	Default	Om te mogen stemmen moet je een aantal dingen meenemen. Welke dingen zijn dat?
Woordenpopper 1		Wat heb je nodig om te stemmen?
Woordenpopper 1 correct		Stempas
Woordenpopper 1 correct		Identiteitsbewijs
Woordenpopper 1 fout		Toestemming ouders/verzorgers
Woordenpopper 1 fout		Telefoon
Woordenpopper 1 fout		Geld
Woordenpopper 1 fout		Brommercertificaat

Woordenpopper 2 intro	Pointing	Je weet nu wat je mee moet nemen naar het stembureau. Je komt soms moeilijke woorden tegen. Wat betekenen deze?
Woordenpopper 2		Niets invullen op het stembiljet
Woordenpopper 2 antwoord		Blanco stemmen
Woordenpopper 2		Iemand anders toestemming geven om voor jou te stemmen
Woordenpopper 2 antwoord		Volmacht
Woordenpopper 2		De plek waar je je stem uitbrengt. (Open) van 7:30 tot 21:00
Woordenpopper 2 antwoord		Stembureau
Woordenpopper 2		Het bewijs dat jij mag stemmen
Woordenpopper 2 antwoord		Stempas
Woordenpopper 2		Het formulier waarop jij aangeeft op wie je wilt stemmen
Woordenpopper 2 antwoord		Stembiljet
Woordenpopper 2		Deze persoon is de nummer 1 van de kandidatenlijst van een partij
Woordenpopper 2 antwoord		Lijsttrekker

Outro	Pointing	"Het is belangrijk om jouw stem te laten horen.
Elke stem telt mee!"
Outro	Default	"Er zijn verschillende politieke partijen.
Kies de partij die het best bij jouw ideeën past."
Outro	Default	Ga op zoek naar informatie, praat met familie of vrienden. Maak gebruik van stem- en kieshulpen.
Outro	Pointing	Kijk in de bibliotheek voor meer informatie!

Quiz		Wat heb je nodig om te kunnen stemmen?
Quiz optie 1		A. Stempas & ID-kaart
Quiz optie 2		B. Telefoon en stempas
Quiz optie 3		C. Toestemming ouders/verzorgers & ID-kaart

Bibliotheek		Prodemos		https://prodemos.nl/voor-scholen/mbo/  https://prodemos.nl/programmas/verkiezingsfestival/
		Stemwijzer?		https://stemwijzer.nl/
		Steffie?		https://www.steffie.nl/nieuws/onderwerpen/stemmen-met-steffie.html
		Kieskompas		https://www.kieskompas.nl/nl/
*
* */
import CharPointing from "./images/char-steambureau-pointing.svg";
import CharDefault from "./images/char-stembureau-default.svg";
import InsideImg from "./images/stembureau-inside.svg";
import Noah from "../../../assets/characters/Noah/Noah";
import { getVoiceLineGenerator } from "../../../util/Voice";

const icons = [
  "stemmen01.svg",
  "stemmen02.svg",
  "stemmen03.svg",
  "stemmen04.svg",
  "stemmen05.svg",
  "stemmen06.svg",
  "stemmen07.svg",
].map((f) => require(`./images/drag-drop-icons/${f}`));

const voice = getVoiceLineGenerator(
  [
    "Audio1-2.mp3",
    "Audio2-2.mp3",
    "20231026_Verkiezingen-Fela-01.mp3",
    "20231026_Verkiezingen-Fela-02.mp3",
    "20231026_Verkiezingen-Fela-03.mp3",
    "20231026_Verkiezingen-Fela-04.mp3",
    "20231026_Verkiezingen-Fela-05.mp3",
    "20231026_Verkiezingen-Fela-06.mp3",
    "20231026_Verkiezingen-Fela-07.mp3",
    "20231026_Verkiezingen-Fela-08.mp3",
    "20231026_Verkiezingen-Fela-09.mp3",
    "20231026_Verkiezingen-Fela-10.mp3",
    "20231026_Verkiezingen-Fela-11.mp3",
    "20231026_Verkiezingen-Fela-12.mp3",
    "20231026_Verkiezingen-Fela-13.mp3",
    "20231026_Verkiezingen-Fela-14.mp3",
  ].map((f) => require(`./voice/${f}`))
);

const ballot: Ballot = {
  parties: ["ZON", "Klomp"],
  candidates: [
    ["A. Boom", "ZON"],
    ["C. Janssen", "ZON"],
    ["G. Özdemir", "ZON"],
    ["M. Daalder", "ZON"],
    ["I. Smith", "ZON"],
    ["J. Mülller", "ZON"],
    ["J. Maan", "ZON"],
    ["N. Jessen", "ZON"],

    ["T. Groen", "Klomp"],
    ["T. Mick", "Klomp"],
    ["A. Sterk", "Klomp"],
    ["N. Strik", "Klomp"],
    ["S. Moens", "Klomp"],
    ["Y. Hassan", "Klomp"],
    ["Z. Nowak", "Klomp"],
    ["P. Russo", "Klomp"],
  ],
};

export const StemBureauCharacter = {
  Default: CharDefault,
  Pointing: CharPointing,
};

const StemModule: Module = {
  name: "StemHuisje",
  resumeBuilding: "stembureau",
  stages: [
    {
      type: "conversation",
      inCity: true,
      steps: [
        {
          text: "Straks heb ik stemrecht. Dit betekent dat ik mag *stemmen*.",
          characterImage: Noah.Sweat,
          voiceLine: voice(),
        },
        {
          text: "Maar hoe moet dat eigenlijk? Laten we dat eens gaan uitzoeken!",
          characterImage: Noah.ThumbsUp,
          voiceLine: voice(),
        },
      ],
      buttonText: "Verder",
      results: [
        {
          type: "mission",
          mission: {
            text: "Zoek uit hoe stemmen werkt!",
          },
        },
      ],
    },
    {
      type: "conversation",
      characterImage: StemBureauCharacter.Default,
      replayHere: true,
      right: true,
      backgroundImage: InsideImg,
      steps: [
        { text: "Goed dat je er bent!", voiceLine: voice() },
        {
          text: "Ben je 18 jaar of ouder? Dan mag je nu gaan *stemmen!*",
          voiceLine: voice(),
        },
        {
          voiceLine: voice(),
          text: "Met jouw stem laat je weten wat jij belangrijk vindt. In de *politiek en in de maatschappij*.",
          characterImage: StemBureauCharacter.Pointing,
        },
        {
          voiceLine: voice(),
          text: "Bijvoorbeeld: Moet de studiefinanciering omhoog? Hoe zorgen we ervoor dat er minder mensen arm zijn?",
          characterImage: StemBureauCharacter.Pointing,
        },
        {
          voiceLine: voice(),
          text: "Het is belangrijk om te stemmen. Zo laat je horen wat jij belangrijk vindt voor jezelf. Én voor de rest van Nederland!",
          characterImage: StemBureauCharacter.Pointing,
        },
        {
          voiceLine: voice(),
          text: "Stemmen doe je bijvoorbeeld voor: \n- de Tweede Kamer\n- de Gemeenteraad\n- de Provinciale Staten",
        },
        {
          voiceLine: voice(),
          text: "Stemmen, hoe werkt dat? Zet de stappen in de goede volgorde!",
        },
      ],
      buttonText: "Verder",
    },
    {
      type: "dragDropInteraction",
      penalty: 5,
      help: {
        text: "Sleep de blokken omhoog of naar beneden om ze in de goede volgorde te zetten.\n\nDruk daarna op 'controleer'. Dan zie je of je de goede volgorde hebt gevonden.",
        right: true,
        characterImage: StemBureauCharacter.Pointing,
      },
      entries: [
        {
          description: "Kies een politieke partij",
          correctPosition: 1,
          image: icons[1],
        },
        {
          description: "Kleur 1 vakje rood",
          correctPosition: 5,
          image: icons[5],
        },
        {
          description: "Krijg een stembiljet",
          correctPosition: 4,
          image: icons[4],
        },
        {
          description: "Ga naar het stembureau",
          correctPosition: 2,
          image: icons[2],
        },
        {
          description: "Je krijgt een stempas thuis",
          correctPosition: 0,
          image: icons[0],
        },
        {
          description: "Doe je stembiljet in de stembus",
          correctPosition: 6,
          image: icons[6],
        },
        {
          description: "Lever je stempas in en laat je identiteitsbewijs zien",
          correctPosition: 3,
          image: icons[3],
        },
      ],
    },
    {
      type: "conversation",
      characterImage: StemBureauCharacter.Pointing,
      right: true,
      backgroundImage: InsideImg,
      steps: [
        {
          voiceLine: voice(),
          text: "Je maakt het rondje *rood* bij de naam van de persoon op wie je wilt stemmen.\n\nLet op: Je mag echt maar *1 rondje rood kleuren*.",
        },
        {
          voiceLine: voice(),
          text: "We gaan dit nu oefenen met neppe *partijen en kandidaten!*",
          characterImage: StemBureauCharacter.Pointing,
        },
      ],
      buttonText: "Verder",
    },
    {
      type: "votingInteraction",
      penalty: 5,
      ballot,
      help: {
        text: "Klik op 1 rondje om te stemmen. Klik daarna op 'controleer'. Dan zie je of de stem goed is uitgebracht.",
        right: true,
        characterImage: StemBureauCharacter.Pointing,
      },
      voting: [
        {
          type: "party",
          key: "ZON",
          instructions: "Stem op partij ZON",
        },
        {
          type: "party",
          key: "Klomp",
          instructions: "Stem op partij Klomp",
        },
        {
          type: "candidate",
          key: ["Z. Nowak", "Klomp"],
          instructions: "Stem op Z. Nowak van partij Klomp",
        },
      ],
    },
    {
      type: "conversation",
      characterImage: StemBureauCharacter.Pointing,
      right: true,
      backgroundImage: InsideImg,
      steps: [
        {
          voiceLine: voice(),
          text: "Om te mogen stemmen moet je een aantal dingen meenemen. Welke dingen zijn dat?",
        },
      ],
      buttonText: "Verder",
    },
    {
      type: "bubblePopperInteraction",
      penalty: 5,
      questions: [
        {
          question: "Wat heb je nodig om te stemmen?",
          answers: ["Stempas", "Identiteits\xADbewijs"],
        },
      ],
      help: {
        text: "Klik op de woorden van de spullen die je mee moet nemen, als je gaat stemmen.",
        right: true,
        characterImage: StemBureauCharacter.Pointing,
      },
      answers: [
        "Stempas",
        "Identiteits\xADbewijs",
        "Toestemming ouders/verzorgers",
        "Telefoon",
        "Geld",
        "Brommer\xADcertificaat",
      ].sort(() => Math.random() - 0.5),
    },
    {
      type: "conversation",
      characterImage: StemBureauCharacter.Pointing,
      right: true,
      backgroundImage: InsideImg,
      steps: [
        {
          voiceLine: voice(),
          text: "Je weet nu wat je mee moet nemen naar *het stembureau.* Je komt soms moeilijke woorden tegen. Wat betekenen deze?",
        },
      ],
      buttonText: "Verder",
    },
    {
      type: "bubblePopperInteraction",
      penalty: 5,
      help: {
        text: "Klik op het woord dat bij de beschrijving past",
        right: true,
        characterImage: StemBureauCharacter.Pointing,
      },
      questions: [
        {
          question: "Niets invullen op het stembiljet",
          answers: ["Blanco stemmen"],
          canSkip: true,
        },
        {
          question: "Iemand anders toestemming geven om voor jou te stemmen",
          answers: ["Volmacht"],
          canSkip: true,
        },
        {
          question:
            "De plek waar je je stem uitbrengt. (Open) van 7:30 tot 21:00",
          answers: ["Stembureau"],
          canSkip: true,
        },
        {
          question: "Het bewijs dat jij mag stemmen",
          answers: ["Stempas"],
          canSkip: true,
        },
        {
          question: "Het formulier waarop jij aangeeft op wie je wilt stemmen",
          answers: ["Stembiljet"],
          canSkip: true,
        },
        {
          question:
            "Deze persoon is de nummer 1 van de kandidatenlijst van een partij",
          answers: ["Lijsttrekker"],
          canSkip: true,
        },
      ],
      answers: [
        "Blanco stemmen",
        "Volmacht",
        "Stembureau",
        "Stempas",
        "Stembiljet",
        "Lijsttrekker",
      ].sort(() => Math.random() - 0.5),
    },
    {
      type: "redirect",
      redirect: "/rating",
    },
    {
      type: "conversation",
      characterImage: StemBureauCharacter.Default,
      right: true,
      backgroundImage: InsideImg,
      steps: [
        {
          voiceLine: voice(),
          text: "Het is belangrijk om jouw stem te laten horen. *Elke stem telt mee!*",
        },
        {
          voiceLine: voice(),
          text: "Er zijn verschillende *politieke partijen*. Kies de partij die het best bij jouw ideeën past.",
        },
        {
          voiceLine: voice(),
          text: "Ga op zoek naar informatie, praat met familie of vrienden. Maak gebruik van *stem- en kieshulpen.*",
        },
      ],
      buttonText: "Verder",
    },
  ],
};

export default StemModule;
