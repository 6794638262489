import React, { ComponentProps } from "react";
import styled, { ThemeProvider } from "styled-components";
import { invertedButtonTheme } from "../../theme";
import GradientBlock from "../GradientBlock/GradientBlock";
import { useAudio } from "../../assets/audio/Audio";

let alerted = false;

function isCompletelyInView(element: HTMLElement) {
  if (alerted || !element) return;
  const rect = element.getBoundingClientRect();
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight;
  const windowWidth = window.innerWidth || document.documentElement.clientWidth;

  const vertInView = rect.top >= 0 && rect.bottom <= windowHeight;
  const horInView = rect.left >= 0 && rect.right <= windowWidth;

  const b = vertInView && horInView;
  console.log("Button completely in view: ", b);
  if (!b) {
    if (alerted) return;
    alerted = true;
    alert(
      "Op deze telefoon, met deze lettergrootte, is het spel niet altijd volledig te zien. Daarom moet je soms scrollen."
    );
  }
}

interface Props {
  $inverted?: boolean;
  playSound?: boolean;
  $fullWidth?: boolean;
}

const StyledButton = styled.button<Props>`
  all: unset;
  text-shadow: ${({ theme, $inverted }) =>
    $inverted ? "1px 1px 0px white;" : `3px 3px 0 ${theme.dark}`};
  cursor: pointer;
  color: ${({ theme }) => theme.text ?? "white"};
  -webkit-text-fill-color: ${({ theme }) => theme.text ?? "white"};
  text-align: center;
  appearance: none;

  width: ${(props) => (props.$fullWidth ? "100%" : "auto")};

  --regular: linear-gradient(
    to bottom,
    ${({ theme }) => theme.main},
    ${({ theme }) => theme.dark}
  );
  --inner-regular: linear-gradient(
    to bottom,
    ${({ theme }) => theme.dark},
    ${({ theme }) => theme.main}
  );
  --hover: linear-gradient(
    to bottom,
    ${({ theme }) => theme.light},
    ${({ theme }) => theme.main}
  );
  --inner-hover: linear-gradient(
    to bottom,
    ${({ theme }) => theme.main},
    ${({ theme }) => theme.light}
  );

  &[disabled] {
    cursor: not-allowed;
  }

  .inner {
    padding: 8px 12px;
  }

  .GradientBlock {
    background: ${(props) =>
      props.$inverted ? "var(--hover)" : "var(--regular)"};
    .inner {
      background: ${(props) =>
        props.$inverted ? "var(--inner-hover)" : "var(--inner-regular)"};
    }

    &:hover {
      background: ${(props) =>
        props.$inverted ? "var(--regular)" : "var(--hover)"};
      .inner {
        background: ${(props) =>
          props.$inverted ? "var(--inner-regular)" : "var(--inner-hover)"};
      }
    }
  }
`;

StyledButton.defaultProps = {
  $fullWidth: true,
};

const ThemeButton = ({
  children,
  onClick,
  playSound = true,
  ...props
}: ComponentProps<typeof StyledButton> & Props) => {
  const ref = React.useRef<HTMLButtonElement>(null);
  const audio = useAudio();
  const _onClick = playSound
    ? () => {
        audio.play("buttonPress");
        onClick && onClick();
      }
    : onClick;

  return (
    <StyledButton role="button" onClick={_onClick} {...props}>
      <GradientBlock ref={ref} className="emphasis">
        {children}
      </GradientBlock>
    </StyledButton>
  );
};

export default ThemeButton;

export const InvertedButton = (
  props: ComponentProps<typeof ThemeButton> & Props
) => (
  <ThemeProvider theme={invertedButtonTheme}>
    <ThemeButton $inverted {...props}></ThemeButton>
  </ThemeProvider>
);
