import Noah from "../../../assets/characters/Noah/Noah";

import BackgroundImg from "./images/quiz-inside.svg";
import Quizmaster from "./images/char-quizmaster/Quizmaster";
import { Variable } from "../../variables";
import { getVoiceLineGenerator } from "../../../util/Voice";

const voice = getVoiceLineGenerator(
  [
    "Noah - Kristel-13.13-49.mp3",
    "20230713-Noah-Kristel-10.26-04.mp3",
    "20230713-Noah-Kristel-14.1-03.mp3",
    "Noah - Kristel-13.22-52.mp3",
    "Noah - Kristel-13.24-53.mp3",
    "Eindquiz - Suzanne-01.2-01.mp3",
    "20231026_Quiz-Suzanne-01.mp3",
    "Eindquiz - Suzanne-01.8-04.mp3",
    "Eindquiz - Suzanne-01.10-05.mp3",
    "Noah - Kristel-13.26-54.mp3",
    "Noah - Kristel-13.28-55.mp3",
    "Noah - Kristel-13.30-56.mp3",
    "20230713-Noah-Kristel-10.5-05.mp3",
    "Noah - Kristel-13.32-57.mp3",
  ].map((f) =>
    typeof f === "string"
      ? require(`./voice/${f}`)
      : [require(`./voice/${f[0]}`), f[1], f[2]]
  )
);

const options = [
  "Eindquiz - Suzanne-05.1-06.mp3",
  "Aansprakelijkheidsverzekering10.mp3",
].map((f) =>
  typeof f === "string"
    ? require(`./voice/${f}`)
    : [require(`./voice/${f[0]}`), f[1], f[2]]
);

const quizQuestions = [
  {
    question: "1. Wat is GEEN deel van je basis zorgverzekering?",
    answers: ["Huisarts", "Tandarts", "Psychiater"],
    answer: 1,
    incorrectText: "Fout! Dit zit wel in de *basis zorgverzekering*.",
    correctText:
      "Heel goed! Daarvoor moet je een *aanvullende verzekering* afsluiten.",
  },
  {
    question:
      "2. Is het verplicht om van je zorgtoeslag je zorgverzekering te betalen?",
    answers: [
      "Ja, altijd",
      "Soms, alleen als je veel zorg gebruikt",
      "Nee, maar het is verstandig om dit wel te doen",
    ],
    answer: 2,
    incorrectText:
      "Fout! Het is niet verplicht, maar wel verstandig om te doen.",
  },
  {
    question:
      "3. Hoeveel kost een aansprakelijkheids\xADverzekering je ongeveer elke maand?",
    answers: ["€3 tot €5", "€30 tot €50", "€300 tot €500"],
    answer: 0,
    shuffleAnswers: false,
    incorrectText: "Fout! Deze verzekering kost je €3 tot €5 elke maand.",
  },
  {
    question: "4. Krijg je altijd een stagevergoeding?",
    answers: [
      "Ja, dit is verplicht volgens de wet",
      "Soms, dat mag het stagebedrijf zelf bepalen",
      "Nee, stages zijn altijd onbetaald",
    ],
    answer: 1,
    incorrectText: "Fout! Dit krijg je soms. Dat ligt aan het *stagebedrijf*.",
  },
  {
    question: "5. Wanneer is een aansprakelijkheids\xADverzekering handig?",
    answers: [
      "Als je op vakantie gaat",
      "Als je vaak naar het ziekenhuis moet",
      "Als je per ongeluk iets kapot hebt gemaakt",
    ],
    answer: 2,
    incorrectText:
      "Fout! Het is handig, als je per ongeluk iets kapot hebt gemaakt.",
  },
  {
    question: "6. Je hebt niets ingevuld. Hoe sta je in het Donorregister?",
    answers: [
      "Met 'nee', dan ben je geen donor",
      "Met 'geen bezwaar', dan ben je wel donor",
      "Met 'ja', dan ben je wel donor",
    ],
    answer: 1,
    incorrectText:
      "Fout! Als je niets invult, sta je in het Donorregister met 'geen bezwaar'.",
  },
  {
    question: "7. Mijn stagebedrijf vergoedt altijd mijn reiskosten.",
    answers: [
      "Klopt! Dat is verplicht volgens de wet",
      "Soms, dat mag het stagebedrijf zelf bepalen",
      "Nee, de reiskosten moet je altijd zelf betalen",
    ],
    answer: 0,
    incorrectText:
      "Fout! Je *stagebedrijf* is altijd verplicht om je *reiskosten* te betalen.",
  },
  {
    question: "8. Welk laagste bedrag kun je kiezen voor het eigen risico?",
    answers: ["€85", "€385", "€685"],
    answer: 1,
    shuffleAnswers: false,
    incorrectText: "Fout! Het laagste bedrag is €385.",
  },
  {
    question:
      "9. Je vraagt zorgtoeslag aan. Hoelang duurt het voor je het krijgt?",
    answers: ["Meteen", "Na 1 tot 3 maanden", "Na 1 jaar"],
    answer: 1,
    incorrectText: "Fout! Dit staat na 1 tot 3 maanden op je rekening.",
  },
  {
    question: "10. Wanneer kun je WEL stufi krijgen?",
    answers: [
      "Als je mbo 1 of 2 doet",
      "Als je 17 jaar oud bent",
      "Als je geen opleiding volgt",
    ],
    answer: 0,
    incorrectText:
      "Fout! Je krijgt stufi als je mbo 1 of 2 doet, en ook bij andere opleidingen.",
  },
  {
    question: "11. Moet je extra bijgeleende stufi altijd terugbetalen?",
    answers: [
      "Ja, altijd",
      "Soms, alleen niet als je mbo 1 of 2 doet",
      "Nee, dit is een gift",
    ],
    answer: 0,
    incorrectText: "Fout! Je moet extra geleende stufi altijd terugbetalen.",
  },
  {
    question: "12. Wat heb je nodig om te kunnen stemmen?",
    answers: [
      "Stempas & identiteitsbewijs",
      "Telefoon en stempas",
      "Toestemming ouders/verzorgers & ID-kaart",
    ],
    answer: 0,
    incorrectText: "Fout! Je moet je stempas en identiteitsbewijs meenemen.",
  },
];

const numQuestions = quizQuestions.length;

const EindQuizModule: Module = {
  name: "EindQuiz",
  stages: [
    {
      type: "conversation",
      inCity: true,
      characterImage: Noah.Default,
      buttonText: "Verder",
      steps: [
        {
          text: "Wat goed! Je hebt alle huizen bezocht.",
          characterImage: Noah.Bliss,
          voiceLine: voice(),
        },
        {
          voiceLine: voice(),
          text: "Laten we bij de bankrekening kijken hoeveel geld er maandelijks binnenkomt, en wat er maandelijks afgaat.",
        },
      ],
      results: [
        {
          type: "mission",
          mission: {
            text: "Laten we bij de bankrekening kijken hoeveel geld er maandelijks binnenkomt, en wat er maandelijks afgaat.",
          },
        },
      ],
    },
    {
      type: "goToBuilding",
      building: "bank",
      shouldNavigate: false,
    },
    {
      type: "trueFalse",
      redirect: "/game",
      questions: [
        {
          question: `{{answer = income - expenses;}}Maandelijks komt er €{{= income.toFixed(2).replace('.',',') }} binnen op je rekening. Je geeft €{{= expenses.toFixed(2).replace('.',',') }} uit per maand. Hoeveel houd je per maand over?`,
          answers: [
            "€{{= (answer - 10).toFixed(2).replace('.',',') }}",
            `€{{= answer.toFixed(2).replace('.',',') }}`,
            "€{{= (answer + 100).toFixed(2).replace('.',',') }}",
          ],
          answer: 1,
          incorrectText:
            "Dit is niet goed. Om te weten hoeveel geld je overhoudt, moet jouw *uitgaven* aftrekken van jouw *inkomen*.",
        },
      ],
      help: {
        characterImage: Noah.SlightlySmiling,
        buttonText: "Terug",
        text: "Om te weten hoeveel geld je overhoudt, moet jouw *uitgaven* aftrekken van jouw *inkomen*. Hoeveel krijg je als je het laatste bedrag aftrekt van het eerste bedrag?",
      },
    },
    {
      type: "redirect",
      redirect: "/bank",
    },
    {
      type: "conversation",
      inCity: true,
      characterImage: Noah.Default,
      buttonText: "Verder",
      steps: [
        {
          text: "Je hebt alle locaties bezocht, én je weet hoeveel geld je per maand krijgt en uitgeeft.",
          characterImage: Noah.Bliss,
          voiceLine: voice(),
        },
        {
          text: "Daarom ben je uitgenodigd om mee te doen aan de grote *eindquiz*!",
          voiceLine: voice(),
        },
        {
          text: "De quiz begint bijna. Laten we snel naar de TV-studio gaan!",
          voiceLine: voice(),
        },
      ],
      results: [
        {
          type: "mission",
          mission: {
            text: "Laten we meedoen aan de grote *eindquiz*! Ga snel naar de TV-studio!",
          },
        },
      ],
    },
    {
      type: "conversation",
      backgroundImage: BackgroundImg,
      right: true,
      characterImage: Quizmaster.Default,
      buttonText: "Verder",
      steps: [
        {
          text: "Welkom bij de grote '*Life@18*' quiz!",
          voiceLine: voice(),
          characterImage: Quizmaster.Wave,
        },
        {
          text: `Er komen straks ${numQuestions} vragen over wat je allemaal kunt regelen als je 18 wordt.`,
          voiceLine: voice(),
        },
        {
          text: "Beantwoord zoveel mogelijk vragen goed en speel een mooie achievement vrij!",
          voiceLine: voice(),
          characterImage: Quizmaster.ThumbsUp,
        },
      ],
    },
    {
      penalty: 100 / numQuestions,
      type: "trueFalse",
      correctText: "Heel goed!",
      incorrectCharacter: Quizmaster.Sad,
      correctCharacter: Quizmaster.ThumbsUp,
      replayHere: true,
      shuffleAnswers: true,
      enumerateAnswers: true,
      questions: quizQuestions,
      results: [
        {
          type: "save",
          location: "computedChoices",
          name: "quizCorrect",
          value: `{{= Math.round(progression.moduleRatings[progression.currentModule] / (${
            100 / numQuestions
          })) }}`,
        },
      ],
    },
    {
      type: "conversation",
      right: true,
      backgroundImage: BackgroundImg,
      characterImage: Quizmaster.Default,
      buttonText: "Verder",
      steps: [
        {
          text: `{{ quizCorrect = ${Variable(
            "quizCorrect"
          )}}}Je hebt {{= quizCorrect }} {{= quizCorrect === 1 ? 'vraag' : 'vragen' }} goed beantwoord!`,
          characterImage: Quizmaster.ThumbsUp,
          voiceLine: voice(),
        },
        {
          text: `{{= quizCorrect < ${numQuestions} ? 'Voor de achievement moet je alle vragen goed beantwoorden. Je kunt het altijd nog een keer proberen.' : 'Gefeliciteerd! Je bent een echte *QuizKampioen*!' }}`,
          voiceLine: () =>
            window["quizCorrect"] < numQuestions ? options[0] : options[1],
        },
      ],
    },
    {
      type: "redirect",
      redirect: "/rating",
    },
    {
      type: "conversation",
      inCity: true,
      characterImage: Noah.Default,
      buttonText: "Verder",
      steps: [
        {
          text: "Goed gedaan! We hebben alle zaken geregeld die belangrijk zijn, als we 18 jaar worden.",
          characterImage: Noah.Bliss,
          voiceLine: voice(),
        },
        {
          text: "Je kan altijd een onderwerp opnieuw bekijken. Klik dan op het gebouw.",
          voiceLine: voice(),
        },
        {
          text: "Ben je op zoek naar meer informatie over een onderwerp? Kijk dan in de bibliotheek. ",
          voiceLine: voice(),
        },
        {
          text: "In de bibliotheek vind je links naar meer informatie over de onderwerpen in deze game.",
          voiceLine: voice(),
        },
        {
          text: "Als je klaar bent, kun je het spel nu afsluiten.",
          voiceLine: voice(),
        },
      ],
    },
  ],
  resumeBuilding: "quiz",
};

export default EindQuizModule;
