import { useNavigate } from "react-router-dom";
import { Achievements } from "../data/Achievements";
import { useUserData } from "../data/UserData";
import { useEffect } from "react";
import { gameData } from "../data/GameData";

const achievements = Object.entries(Achievements);
let throttle = false;

const useAchievements = () => {
  const { userData, grantAchievement } = useUserData();
  const navigate = useNavigate();

  useEffect(() => {
    const lastModule =
      userData.progression.currentModule === gameData.modules.length - 1;
    const checkpoint = userData.progression.checkpoints.length > 0;

    if (checkpoint || userData.demo || (throttle && !lastModule)) {
      return;
    }

    for (const [key, achievement] of achievements) {
      if (userData.achievements.find((a) => a.id === key)) {
        continue;
      }
      if (achievement.check?.(userData)) {
        throttle = true;
        grantAchievement(key);
        if (!lastModule) {
          navigate("/new-achievement");
        }
        break;
      }
    }
    setTimeout(() => {
      throttle = false;
    }, 1000);
  }, [userData, grantAchievement, navigate]);
};

export default useAchievements;
