import Noah from "../../../assets/characters/Noah/Noah";
import AansprakelijkheidCharacter from "./images/Aansprakelijkheid/AansprakelijkheidCharacter";
import { Choices, Variable } from "../../variables";

import Background from "./images/aansprakelijkheidsverzekering-inside_1.svg";
import { getVoiceLineGenerator } from "../../../util/Voice";

const importAll = (require) =>
  require.keys().reduce((acc, next) => {
    acc[next.replace("./", "")] = require(next);
    return acc;
  }, {});

const coins = importAll(
  // @ts-ignore
  require.context("./images/coins", false, /\.(png|jpe?g|svg)$/)
);

const voice = getVoiceLineGenerator(
  [
    "Noah-Kristel-06.20-25.mp3",
    "Noah-Kristel-06.22-26.mp3",
    "Noah-Kristel-06.24-27.mp3",
    "Aansprakelijkheidsverzekering-Emy-03.2-01.mp3",
    "Aansprakelijkheidsverzekering-Emy-03.5-02.mp3",
    "Aansprakelijkheidsverzekering-Emy-04.1-03.mp3",
    "Aansprakelijkheidsverzekering-Emy-03.7-04.mp3",
    "Aansprakelijkheidsverzekering-Emy-03.9-05.mp3",
    "Aansprakelijkheidsverzekering-Emy-07.3-06.mp3",
    "Aansprakelijkheidsverzekering-Emy-05.4-07.mp3",
  ].map((f) => require(`./voice/${f}`))
);

const options = ["Noah-Kristel-06.26-28.mp3", "Noah-Kristel-09.1-29.mp3"].map(
  (f) => require(`./voice/${f}`)
);

const AansprakelijkheidsVerzekeringModule: Module = {
  name: "Aansprakelijkheids-Module",
  resumeBuilding: "aansprakelijkheidsverzekering",
  stages: [
    {
      type: "conversation",
      inCity: true,
      characterImage: Noah.Default,
      buttonText: "Verder",
      steps: [
        {
          characterImage: Noah.Sweat,
          text: "Als je iets kapot maakt van een ander, moet je soms de schade betalen.",
          voiceLine: voice(),
        },
        {
          characterImage: Noah.Sweat,
          text: "Zo kun je in één keer veel geld kwijt zijn.",
          voiceLine: voice(),
        },
        {
          characterImage: Noah.Default,
          text: "Een *aansprakelijkheids\xADverzekering* kan een oplossing zijn. Laten we uitzoeken wat dit precies voor verzekering is!",
          voiceLine: voice(),
        },
      ],
      results: [
        {
          type: "mission",
          mission: {
            text: [
              "Zoek meer informatie over de *aansprakelijkheids\xADverzekering*.",
            ],
          },
        },
      ],
    },
    {
      type: "conversation",
      characterImage: AansprakelijkheidCharacter.Explaining,
      right: true,
      buttonText: "Verder",
      backgroundImage: Background,
      replayHere: true,
      steps: [
        {
          text: "Goed dat je meer informatie komt ophalen over een *aansprakelijkheids\xADverzekering*.",
          voiceLine: voice(),
        },
        {
          voiceLine: voice(),
          text: "Een *aansprakelijkheids\xADverzekering* betaalt een deel van de schade die jij bij een ander veroorzaakt.",
          characterImage: AansprakelijkheidCharacter.Explaining,
        },
        {
          voiceLine: voice(),
          text: "Zonder *aansprakelijkheids\xADverzekering* moet je misschien veel geld betalen bij een ongeluk.",
          characterImage: AansprakelijkheidCharacter.QuestionSad,
        },
        {
          voiceLine: voice(),
          text: "Voor een *aansprakelijkheids\xADverzekering* betaal je elke maand een *premie*. Voor jongeren is dat zo'n €3 tot €5 per maand.",
          characterImage: AansprakelijkheidCharacter.FolderInHand,
        },
        {
          text: "In welke situaties zou je een *aansprakelijkheids\xADverzekering* kunnen gebruiken? Ontdek het hier.",
          voiceLine: voice(),
        },
      ],
    },
    {
      type: "trueFalse",
      answers: ["Niet", "Wel"],
      incorrectCharacter: AansprakelijkheidCharacter.QuestionSad,
      correctCharacter: AansprakelijkheidCharacter.Explaining,
      penalty: 5,
      help: {
        characterImage: AansprakelijkheidCharacter.Explaining,
        text: "Kies of dit wel of niet wordt verzekerd door de aansprakelijkheids\xADverzekering",
      },
      questions: [
        {
          question:
            "Je fiets valt tegen een auto aan en maakt een kras.\n\nWordt dit vergoed door de verzekering?",
          answer: 1,
          correctText:
            "Dat klopt! Als je schade maakt aan spullen van een ander, wordt dit vergoed.",
          incorrectText:
            "Het is wel verzekerd wanneer jij schade maakt aan de spullen van een ander.",
        },
        {
          question:
            "Tijdens het sporten knal je tegen iemand op. Diegene verliest een tand.\n\nWordt dit vergoed door de verzekering?",
          answer: 1,
          correctText:
            "Dat klopt! Jouw verzekering dekt de kosten voor de persoon bij wie je schade veroorzaakt.",
          incorrectText:
            "Het wordt wel verzekerd wanneer jij bij iemand anders schade veroorzaakt.",
        },
        {
          question:
            "Je bent op vakantie in Spanje. Je zakt door een dure stoel.\n\nWordt dit vergoed door de verzekering?",
          answer: 1,
          correctText:
            "Dat klopt! Jouw verzekering vergoedt de kosten voor schade die je in het *buitenland* maakt.",
          incorrectText:
            "Jouw verzekering vergoedt de kosten voor schade die je in het *buitenland* maakt.",
        },
        {
          question:
            "Je hebt een bijbaan. Je schrijft daar per ongeluk met watervaste stift op een whiteboard.\n\nWordt dit vergoed door de verzekering?",
          answer: 0,
          correctText:
            "Dat klopt. Schade die je op werk veroorzaakt, is niet verzekerd. Vaak heeft de *werkgever* hier een verzekering voor.",
          incorrectText:
            "Helaas... Schade die je op werk veroorzaakt, is niet verzekerd. Vaak heeft de *werkgever* hier een verzekering voor.",
        },
        {
          question:
            "Jouw kat gaat logeren bij je vrienden. Maar hij krabt de hele bank kapot!\n\nWordt dit vergoed door de verzekering?",
          answer: 1,
          correctText:
            "Dat klopt. Schade die jouw huisdier veroorzaakt, is verzekerd.",
          incorrectText: "Schade die jouw huisdier veroorzaakt, is verzekerd.",
        },
        {
          question:
            "Je bent heel boos op je vriend. Zo boos, dat je expres iets kapot maakt van hem.\n\nWordt dit vergoed door de verzekering?",
          answer: 0,
          correctText:
            "Dat klopt. Schade die jij *met opzet* veroorzaakt, is niet verzekerd.",
          incorrectText:
            "Schade die jij *met opzet* veroorzaakt, is niet verzekerd.",
        },
        {
          question:
            "Je hebt een botsing met je scooter. Jij hebt geen voorrang gegeven.\n\nWordt dit vergoed door de verzekering?",
          answer: 0,
          correctText:
            "Dat klopt. Voor jouw auto, scooter of ander motorvoertuig heb je een *aparte aansprakelijkheids\xADverzekering* nodig. Een *Wettelijke Aansprakelijkheids\xADverzekering ('WA')* noemen ze dat.",
          incorrectText:
            "Dat wordt niet door deze verzekering vergoed. Voor jouw auto, scooter of ander motorvoertuig heb je een *aparte aansprakelijkheids\xADverzekering* nodig. Een *Wettelijke Aansprakelijkheids\xADverzekering ('WA')* noemen ze dat.",
        },
      ],
    },
    {
      type: "conversation",
      characterImage: AansprakelijkheidCharacter.Explaining,
      right: true,
      buttonText: "Verder",
      backgroundImage: Background,
      steps: [
        {
          text: "Met deze verzekering is er dus een kans dat je veel geld bespaart. Maar als je de verzekering nooit nodig hebt, kost het je geld. Kijk maar.",
          voiceLine: voice(),
        },
      ],
    },
    {
      type: "sliderInteraction",
      text: "Stel je voor: Je bent al 8 jaar verzekerd. Je zit in een koffietentje. Je gooit per ongeluk koffie op iemands nieuwe sneakers. Wat kost je dit met of zonder verzekering?",
      button: "Verder",
      help: {
        text: "Zet de schuif naar links of rechts om te zien wat de kosten zijn geweest met of zonder *aansprakelijkheids\xADverzekering*.",
        characterImage: AansprakelijkheidCharacter.Explaining,
      },
      inputs: [
        {
          type: "radio",
          on: 288,
          off: 150,
          name: "Aansprakelijkheids\xADverzekering",
        },
      ],
      outputs: [
        {
          function: (a) => a,
          formatter: (a) => `€${a}`,
          name: (a) =>
            a === 288
              ? "Kosten 8 jaar verzekering"
              : "Kosten schade op sneakers",
          imageFunction: (a) =>
            coins[`coins-${a}.svg`] ?? coins[`coins-${a}.png`],
        },
      ],
    },
    {
      type: "sliderInteraction",
      text: "Je bent nu 5 jaar verzekerd en hebt nooit schade gemaakt. Wat heeft de verzekering jou gekost?",
      button: "Verder",
      help: {
        text: "Zet de schuif naar links of rechts om te zien wat de kosten zijn geweest met of zonder *aansprakelijkheids\xADverzekering*.",
        characterImage: AansprakelijkheidCharacter.Explaining,
      },
      inputs: [
        {
          type: "radio",
          on: 180,
          off: 0,
          name: "Aansprakelijkheids\xADverzekering",
        },
      ],
      outputs: [
        {
          function: (a) => a,
          formatter: (a) => `€${a}`,
          imageFunction: (a) =>
            coins[`coins-${a}.svg`] ?? coins[`coins-${a}.png`],
          name: (a) =>
            a === 180 ? "Kosten 5 jaar verzekering" : "Geld uitgegeven",
        },
      ],
    },
    {
      type: "sliderInteraction",
      text: "Je schopt per ongeluk een bal door de ruit van de buren. Je hebt je verzekering 1 jaar. Wat kost dit met of zonder verzekering?",
      button: "Verder",
      help: {
        text: "Zet de schuif naar links of rechts om te zien wat de kosten zijn geweest met of zonder *aansprakelijkheids\xADverzekering*.",
        characterImage: AansprakelijkheidCharacter.Explaining,
      },
      inputs: [
        {
          type: "radio",
          on: 36,
          off: 500,
          name: "Aansprakelijkheids\xADverzekering",
        },
      ],
      outputs: [
        {
          function: (a) => a,
          formatter: (a) => `€${a}`,
          imageFunction: (a) =>
            coins[`coins-${a}.svg`] ?? coins[`coins-${a}.png`],
          name: (a) =>
            a === 36 ? "Kosten 1 jaar verzekering" : "Kosten schade van ruit",
        },
      ],
    },
    {
      type: "redirect",
      redirect: "/rating",
    },
    {
      type: "conversation",
      buttonText: "Verder",
      backgroundImage: Background,
      characterImage: AansprakelijkheidCharacter.Explaining,
      right: true,
      steps: [
        {
          text: "Je kunt nu zelf kiezen. Of je neemt geen *aansprakelijkheids\xADverzekering* en betaalt alle schade als je iets kapot maakt. Of je neemt wel een *aansprakelijkheids\xADverzekering* en betaalt minder bij een ongelukje.",
          voiceLine: voice(),
        },
      ],
    },
    {
      type: "choice",
      text: "Wil je een aansprakelijkheids\xADverzekering afsluiten?",
      choices: [
        {
          display: "Ja",
          value: [4],
          saveVariable: [Choices.aansprakelijkheidsVerzekering],
        },
        {
          display: "Nee",
          value: [0],
          saveVariable: [Choices.aansprakelijkheidsVerzekering],
        },
      ],
    },
    {
      type: "conversation",
      buttonText: "Verder",
      backgroundImage: Background,
      characterImage: Noah.Default,
      steps: [
        {
          text: `{{ hasAV = ${Variable(
            "aansprakelijkheidsVerzekering"
          )} > 0 }}{{= !hasAV ? "Ook prima, Zorg wel altijd voor een spaarpotje, mocht je ergens schade maken." : "Dit kost €4 per maand. Nu hoef je niet bang te zijn voor onverwachte en hoge kosten!" }}`,
          voiceLine: () => options[window["hasAV"] ? 0 : 1],
        },
      ],
      results: [
        {
          type: "bank",
          description: "Aansprakelijkheids\xADverzekering",
          amount: `{{= -${Variable("aansprakelijkheidsVerzekering")} }}`,
          key: "aansprakelijkheidsVerzekering",
          monthly: true,
        },
      ],
    },
  ],
};

export default AansprakelijkheidsVerzekeringModule;
