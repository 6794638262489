import { AnimatePresence, motion, MotionConfig } from "framer-motion";
import React, { ComponentPropsWithRef } from "react";
import styled from "styled-components";
import Circles from "../Circles/Circles";
import IconButton from "../IconButton/IconButton";
import { useNavigate } from "react-router-dom";
import WarningIcon from "./WarningIcon";

interface Props {
  header?: string;
  icon?: "exclamation" | "question";
  showBack?: boolean;
  onBack?: () => void;
}

const StyledAlert = styled.div<Props>`
  height: 100%;
  position: relative;
  background: ${(props) => props.theme.main};
  display: grid;
  color: ${(props) => props.theme.dark};

  .inset-anim {
    background: white;
    box-sizing: border-box;
    border-radius: 16px;
    border: solid ${(props) => props.theme.main};
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;

    .main {
      padding: 10px;
      flex: 1 0;
    }
  }

  header {
    position: relative;
    overflow: hidden;
    padding-top: 32px;
    padding-bottom: 72px;
  }

  .header {
    color: white;
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    word-break: break-word;
    position: relative;
  }

  .back-button {
    position: absolute;
    top: 20px;
    left: 20px;
  }

  .warning-icon {
    margin-left: auto;
    margin-top: -67px;
    flex-shrink: 0;
    margin-right: 10px;
  }

  .circle-container {
    position: absolute;
    height: 150px;
    top: 0;
    width: 100%;
  }
`;

const transition = {
  duration: 0.8,
  ease: "easeOut",
};

const AlertScreen = ({
  children,
  header,
  icon,
  showBack = true,
  onBack,
  ...props
}: ComponentPropsWithRef<typeof StyledAlert> & Props) => {
  const navigate = useNavigate();

  const [headerDuration, setHeaderDuration] = React.useState(0.8);

  React.useEffect(() => {
    setHeaderDuration(0.4);
  }, []);

  if (!onBack) {
    onBack = () => {
      navigate(-1);
    };
  }

  return (
    <StyledAlert {...props}>
      <MotionConfig transition={transition}>
        <motion.div
          animate={{
            borderWidth: [0, 10],
          }}
          className="inset-anim"
        >
          <header>
            <Circles
              style={{
                position: "absolute",
                left: "-50%",
                right: "-50%",
                bottom: 0,
              }}
              animate={{
                y: [-149, 0],
              }}
              transition={transition}
              gradients={[
                ["dark", "main"],
                ["main", "light"],
              ]}
            ></Circles>

            <AnimatePresence mode="wait">
              <motion.div
                key={header}
                className="header emphasis"
                animate={{
                  scale: [0, 1],
                }}
                transition={{ ...transition, duration: headerDuration }}
                exit={{
                  scale: [1, 0],
                }}
              >
                {header}
              </motion.div>
            </AnimatePresence>
          </header>
          {icon && (
            <WarningIcon
              aria-hidden
              as={motion.svg}
              animate={{
                x: [100, 0],
                rotate: ["25deg", "0deg"],
              }}
              className="warning-icon"
              icon={icon}
            />
          )}
          <div className="main">{children}</div>
        </motion.div>
      </MotionConfig>
      {showBack && (
        <IconButton
          className="back-button"
          icon="Back"
          onClick={onBack}
        ></IconButton>
      )}
    </StyledAlert>
  );
};

export default AlertScreen;
