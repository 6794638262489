import styled from "styled-components";
import React, {
  ComponentPropsWithRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import ThemeButton from "../../components/ThemeButton/ThemeButton";
import TextBubble from "../../components/TextBubble/TextBubble";
import { motion, useAnimationControls } from "framer-motion";
import { useUserData } from "../../data/UserData";
import IconButton from "../../components/IconButton/IconButton";
import useHowl from "../../util/useHowl";

export interface ConversationProps {
  config: ConversationConfig;
  start?: number;
  close?: () => void;
}

// takes in a string with *bold* and returns an array of strings and bolded strings
const bold = (text: string) => {
  const parts = text.split("*");
  return parts
    .map((part, i) => {
      if (i % 2 === 1) {
        return <b key={i}>{part}</b>;
      }
      return part;
    })
    .filter((part) => !!part);
};

const StyledConversation = styled.div<{ center?: boolean }>`
  width: 100%;
  isolation: isolate;
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  padding-top: 20px;
  align-items: ${({ center }) => (center ? "center" : "flex-end")};
  text-align: left;
  font-weight: 400;

  & > div {
    width: 100%;
    display: grid;
    gap: 20px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    & > .button {
      position: absolute;
      bottom: 20px;
      left: 20px;
      right: 20px;
    }
  }
`;

const transition = {
  duration: 0.4,
  ease: "easeOut",
};

const StyledBackground = motion(styled.img`
  position: absolute;
  width: 100%;
  object-position: center bottom;
  max-height: 100%;
  z-index: -1;
  pointer-events: none;
  bottom: 0;
`);

const StyledCharacter = motion(styled.img<{ right: boolean }>`
  object-fit: contain;
  object-position: ${({ right }) => (right ? "right" : "left")};

  max-height: 350px;
  max-height: max(65cqh, 350px);
  max-width: 100%;

  ${({ right }) => (right ? "margin-left: auto;" : "")}
`);

const Conversation = ({
  config,
  close,
  start = 0,
  ...props
}: ConversationProps & ComponentPropsWithRef<typeof StyledConversation>) => {
  const { getCompiled, incrementStage } = useUserData();
  const textControls = useAnimationControls();
  const balloonControls = useAnimationControls();
  const characterControls = useAnimationControls();
  const [currentStep, setCurrentStep] = useState(start);
  const [buttonActive, setButtonActive] = useState(false);

  const _step = config.steps[currentStep];
  let step: ConversationStep;

  if (typeof _step === "string") {
    step = { text: _step };
  } else {
    step = _step as ConversationStep;
  }

  let { right, backgroundImage, characterImage, buttonText, voiceLine, text } =
    {
      ...config,
      ...step,
    };

  text = getCompiled(text);
  voiceLine = typeof voiceLine === "function" ? voiceLine() : voiceLine;

  const accessibleText = voiceLine ? "" : text;

  const howl = useHowl(voiceLine);

  const nextStep = async (inc = 1) => {
    if (!buttonActive) return;
    howl?.stop();
    setButtonActive(false);
    const nextStepIndex = currentStep + inc;
    let newStep = config.steps[nextStepIndex];
    if (typeof newStep === "string") {
      newStep = { text: newStep };
    }
    const { right: newRight } = {
      ...config,
      ...newStep,
    };
    if (nextStepIndex === config.steps.length) {
      if (config.type === "conversation") {
        incrementStage();
      } else {
        close?.();
      }
      return;
    }
    if (right !== newRight) {
      await hideBalloon();
      await hideCharacter();
    } else {
      await hideBalloon();
    }
    setCurrentStep(nextStepIndex);
  };

  const showBalloon = useCallback(
    async (duration = 0.4) => {
      await balloonControls.start(
        {
          scale: [null, 1],
        },
        {
          ...transition,
          duration,
        }
      );
      await textControls.start(
        {
          opacity: [null, 1],
        },
        transition
      );
      setButtonActive(true);
      howl?.play();
    },
    [howl, balloonControls, textControls]
  );

  const hideBalloon = useCallback(async () => {
    await textControls.start(
      {
        opacity: [null, 0],
      },
      transition
    );
    await balloonControls.start(
      {
        scale: [null, 0],
      },
      transition
    );
  }, [balloonControls, textControls]);

  const showCharacter = useCallback(
    async (duration = 0.4) => {
      await characterControls.start(
        {
          scale: [null, 1],
          x: [null, 0],
        },
        {
          ...transition,
          duration,
        }
      );
    },
    [characterControls]
  );

  const hideCharacter = useCallback(async () => {
    await characterControls.start(
      {
        scale: [null, 0.8],
        x: [null, right ? 100 : -100],
      },
      transition
    );
  }, [characterControls, right]);

  useEffect(() => {
    void showBalloon();
  }, [showBalloon, text]);

  useEffect(() => {
    void showBalloon(0.8);
    void showCharacter(0.8);
  }, [right, showBalloon, showCharacter]);

  // We operate under the assumption that this will not change within a conversation. It messes up the animation otherwise.
  const center = !characterImage;

  return (
    <StyledConversation center={center} {...props}>
      <span className="sr-only" aria-live="polite">
        {accessibleText}
      </span>
      <div>
        {backgroundImage && (
          <StyledBackground aria-hidden src={backgroundImage} />
        )}

        {text && (
          <TextBubble
            as={motion.div}
            animate={balloonControls}
            initial={{ scale: 0 }}
            variant={right ? "right" : "left"}
            showFliebel={!!characterImage}
            style={
              center
                ? {}
                : {
                    originX: right ? 1 : 0,
                    originY: 1,
                  }
            }
          >
            <motion.span
              role="text"
              initial={{ opacity: 0 }}
              animate={textControls}
            >
              {bold(text)}
              {voiceLine && (
                <IconButton
                  style={{
                    position: "absolute",
                    right: 0,
                    top: "50%",
                    transform: "translate(50%, -50%)",
                  }}
                  size={26}
                  icon="Replay"
                  onClick={howl.replay}
                ></IconButton>
              )}
            </motion.span>
          </TextBubble>
        )}

        {characterImage && (
          <StyledCharacter
            aria-hidden
            key={"right=" + right}
            initial={{ scale: 0.8, x: right ? 100 : -100 }}
            animate={characterControls}
            right={right}
            src={characterImage}
          ></StyledCharacter>
        )}

        {buttonText && (
          <div className="button">
            <ThemeButton
              playSound={buttonActive}
              as={motion.button}
              onClick={nextStep}
            >
              {buttonText}
            </ThemeButton>
          </div>
        )}
      </div>
      {currentStep > 0 && (
        <IconButton
          playSound
          style={{
            position: "absolute",
            top: 20,
            left: 20,
          }}
          onClick={() => nextStep(-1)}
          icon="Back"
        ></IconButton>
      )}
    </StyledConversation>
  );
};

export default Conversation;
