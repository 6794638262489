import React, { ComponentPropsWithRef } from "react";
import GradientBlock from "../../../components/GradientBlock/GradientBlock";
import Theme from "../../../theme";
import styled from "styled-components";
import { motion, MotionConfig } from "framer-motion";

const StyledToggle = styled(GradientBlock)`
  width: 46px;
  height: 28px;
  padding: 2px;
  position: relative;
  display: inline-block;

  & > .inner {
    display: flex;
    align-items: center;
  }

  &[data-active="true"] > .inner {
    justify-content: flex-end;
  }

  .active {
  }
`;

const white = Theme("white");
const ToggleInput = ({
  active,
  toggle,
  ...props
}: ComponentPropsWithRef<typeof StyledToggle> & {
  active?: boolean;
  toggle?: () => void;
}) => {
  return (
    <MotionConfig transition={{ duration: 0.1, ease: "easeOut" }}>
      <StyledToggle
        theme={white}
        style={{
          cursor: "pointer",
        }}
        data-active={active}
        onClick={toggle}
        role="switch"
        aria-checked={active}
        {...props}
      >
        <motion.div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
          }}
          animate={{
            opacity: active ? 1 : 0,
          }}
        >
          <StyledToggle></StyledToggle>
        </motion.div>
        <GradientBlock
          as={motion.div}
          layout
          light="true"
          theme={white}
          style={{
            width: 20,
            height: 20,
            padding: 2,
            zIndex: 1,
          }}
        ></GradientBlock>
      </StyledToggle>
    </MotionConfig>
  );
};

export default ToggleInput;
