function* voiceLineGenerator(voiceLines) {
  for (let i = 0; i < voiceLines.length; i++) {
    yield voiceLines[i];
  }
}

export const getVoiceLineGenerator = (voiceLines) => {
  const generator = voiceLineGenerator(voiceLines);
  return () => generator.next().value;
};
