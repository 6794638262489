import Noah from "../../../assets/characters/Noah/Noah";
import Background from "./images/home-noah-birthday.svg";
import { getVoiceLineGenerator } from "../../../util/Voice";

const voiceLines = [
  "Noah-Kristel-02.1-01.mp3",
  "Noah-Kristel-01.9-02.mp3",
  "Noah-Kristel-01.11-03.mp3",
  "Noah-Kristel-01.13-04.mp3",
  "Noah-Kristel-01.15-05.mp3",
  "Noah-Kristel-01.17-06.mp3",
  "Noah-Kristel-01.19-07.mp3",
  "Noah-Kristel-01.21-08.mp3",
  "Noah-Kristel-01.23-09.mp3",
  "Noah-Kristel-01.25-10.mp3",
  "Noah-Kristel-03.2-11.mp3",
].map((voiceLine) => require(`./voicelines/${voiceLine}`));
const voice = getVoiceLineGenerator(voiceLines);

const IntroModule: Module = {
  name: "GameIntro",
  resumeBuilding: "Huis nummer 10",
  stages: [
    {
      type: "conversation",
      buttonText: "Verder",
      redirect: "/city",
      inCity: true,
      characterImage: Noah.Smiling,
      steps: [
        {
          text: "Hey! Ik ben Noah. Dit is de plek waar ik woon. Laten we naar mijn huis gaan.",
          voiceLine: voice(),
        },
        {
          text: "Ik woon op nummer 10. Klik op het huis om naar binnen te gaan.",
          voiceLine: voice(),
        },
      ],
      results: [
        {
          type: "mission",
          mission: {
            text: "Ik woon op nummer 10. Klik op het huis om naar binnen te gaan.",
          },
        },
      ],
    },
    {
      type: "conversation",
      buttonText: "Verder",
      characterImage: Noah.Smiling,
      backgroundImage: Background,
      steps: [
        {
          text: "Welkom! Zoals je ziet, hangen de slingers al. Want ik word bijna 18 jaar!",
          voiceLine: voice(),
        },
        {
          voiceLine: voice(),
          text: "Bij 18 jaar worden, komt een hoop kijken. Er moeten allerlei zaken geregeld worden!",
        },
        {
          voiceLine: voice(),
          text: "Een DigiD heb ik al. Nu kan ik bijvoorbeeld een zorgverzekering of studiefinanciering regelen.",
        },
        {
          voiceLine: voice(),
          text: "We gaan samen een paar van deze belangrijke zaken ontdekken in deze game.",
        },
        {
          voiceLine: [voice(), 1000, voice()],
          text: "Zullen we op pad gaan? Kijken op welke plekken je allemaal zaken kan regelen?\n\nDruk op de knop om het gebouw weer uit te gaan.",
          buttonText: "Naar de stad",
        },
      ],
    },
    {
      type: "conversation",
      name: "stad 1",
      buttonText: "Verder",
      inCity: true,
      characterImage: Noah.Smiling,
      steps: [
        {
          voiceLine: voice(),
          text: "We kunnen rondkijken door te swipen. Er is vast van alles te ontdekken.",
        },
        {
          voiceLine: voice(),
          text: "Het regelen van deze zaken kost tijd en energie. Vaak draait het ook om geld. ",
        },
        {
          voiceLine: voice(),
          text: "Via de bank kun je mijn bankrekening inzien. Ga naar de bank.",
        },
      ],
      results: [
        {
          type: "mission",
          mission: {
            text: "Ga naar de bank.",
          },
        },
      ],
    },
    {
      type: "goToBuilding",
      building: "bank",
      shouldNavigate: true,
    },
  ],
};

export default IntroModule;
