import React from "react";
import "./App.css";
import GameRouter from "./Game/GameRouter";
import { ThemeProvider } from "styled-components";
import Theme from "./Game/theme";
import { UserProvider } from "./Game/data/UserData";
import { MotionConfig } from "framer-motion";
import Audio from "./Game/assets/audio/Audio";

function App() {
  return (
    <div className="App">
      <UserProvider>
        <Audio>
          <ThemeProvider theme={Theme()}>
            <MotionConfig
              transition={{
                duration: 0.8,
                ease: "easeOut",
              }}
            >
              <GameRouter></GameRouter>
            </MotionConfig>
          </ThemeProvider>
        </Audio>
      </UserProvider>
    </div>
  );
}

export default App;
