import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useRef,
} from "react";
import { Sounds } from "./Sounds";
import { useUserData } from "../../data/UserData";
import { Howl, Howler } from "howler";

Howler.autoSuspend = false;

document.addEventListener("touchstart", function () {
  if (Howler.ctx.state === "suspended") {
    Howler.ctx.resume();
  }
});

const PlayAudioContext = createContext<{
  play: (sound: keyof typeof Sounds) => void;
}>(null);

const _Audio = ({ children }: PropsWithChildren) => {
  const { userData } = useUserData();
  const howls = useRef({});

  const playAudio = useRef((url: string) => {
    if (!howls.current[url]) {
      howls.current[url] = new Howl({
        src: [url],
        html5: true,
      });
    }
    howls.current[url].play();
  }).current;

  const play = useRef((sound: keyof typeof Sounds) => {
    const soundFile = Sounds[sound];
    playAudio(soundFile);
  }).current;

  const value = {
    play: userData.settings.soundVolume > 0 ? play : () => {},
  };

  return (
    <PlayAudioContext.Provider value={value}>
      {children}
    </PlayAudioContext.Provider>
  );
};

export const useAudio = () => {
  return useContext(PlayAudioContext);
};

export default _Audio;
