import { Variable } from "../../variables";

import EventImg1 from "./images/event/event3-1.svg";
import EventImg2 from "./images/event/event3-2.1_v2.svg";
import EventImg3 from "./images/event/event3-2.2.svg";
import BackgroundImg from "./images/stufi-inside_1.svg";
import Noah from "../../../assets/characters/Noah/Noah";
import LoonHeffingsCharacter from "./images/character/LoonHeffingsCharacter";
import { euroFormat } from "../../../util/EuroFormat";
import { getVoiceLineGenerator } from "../../../util/Voice";

const voice = getVoiceLineGenerator(
  [
    "20230713-Noah-Kristel-17-16.mp3",
    "20230713-Noah-Kristel-10.25-02.mp3",
    "Noah - Kristel-09.19-38.mp3",
    "Loonheffingskorting - Tim-01-01.mp3",
    "Loonheffingskorting - Tim-01.22-02.mp3",
    "Loonheffingskorting - Tim-01.20-03.mp3",
    "Loonheffingskorting - Tim-01.18-04.mp3",
    "Loonheffingskorting - Tim-01.16-05.mp3",
    "Loonheffingskorting - Tim-02.1-06.mp3",
    "Loonheffingskorting - Tim-01.13-07.mp3",
    "Loonheffingskorting - Tim-01.11-08.mp3",
    "Loonheffingskorting - Tim-01.8-09.mp3",
  ].map((f) => require(`./voice/${f}`))
);

const options = [
  "20230713-Noah-Kristel-10.23-17.mp3",
  "20230713-Noah-Kristel-10.24-18.mp3",
].map((f) => require(`./voice/${f}`));

const feedback = [
  [
    "Als je geen *loonheffingskorting* aanvraagt betaal je meer *belasting* en krijg je minder loon per maand. Vraag *loonheffingskorting* aan bij de baan waar jij het meeste verdient.",
    "Goed zo! Kijk bij welke baan je *inkomen* het hoogst is. Dáár vraag je *loonheffingskorting* aan. Zo krijg je meer geld op je rekening. Dan hoef je dit later niet terug te vragen bij de *Belastingdienst*.",
  ],
  [
    "Kijk bij welke baan je *inkomen* het hoogst is. Dáár vraag je *loonheffingskorting* aan. Zo krijg je meer geld op je rekening. Dan hoef je dit later niet terug te vragen bij de *Belastingdienst*.",
    "Als je 2x de *korting* krijgt, betaal je te weinig *belasting* over je loon. Je moet waarschijnlijk aan het einde van het jaar geld *terugbetalen*.",
  ],
];

const sliderFeedback = (a, b) => {
  a = Math.min(a, 1);
  b = Math.min(b, 1);

  return feedback[a][b];
};

const module: Module = {
  name: "Loonheffingskorting",
  stages: [
    {
      type: "event",
      redirect: "/game",
      buttonText: "Verder",
      header: "Naar de tandarts",
      steps: [
        {
          text: "Oh, je hebt tandpijn! Tijd voor een bezoek aan de tandarts. En oeps, een gaatje! Dat gaat de tandarts vullen.",
          voiceLine: voice(),
          image: EventImg1,
        },
        {
          text: `{{ X = ${Variable(
            "aanvullendeVerzekeringTandarts"
          )}; }}{{= X > 0 ? 'Jij hebt een aanvullende tandartsverzekering. Jij hoeft deze rekening dus niet te betalen. Dat doet de verzekering.' : 'De controle en het vullen van het gaatje kosten samen €100. Deze rekening moet jij betalen. Je bent niet voor de tandarts verzekerd.' }}`,
          image: `{{= X > 0 ? '${EventImg3}' : '${EventImg2}' }}`,
          voiceLine: () => (window["X"] > 0 ? options[1] : options[0]),
        },
      ],
      results: [
        {
          type: "bank",
          description: "Tandarts",
          amount: "{{= X > 0 ? 0 : -100 }}",
          key: "tandarts",
        },
      ],
    },
    {
      type: "conversation",
      inCity: true,
      characterImage: Noah.Default,
      buttonText: "Verder",
      steps: [
        {
          text: "Heb je een bijbaan, of krijg je een stagevergoeding? Dan kun je *loonheffingskorting* krijgen.",
          voiceLine: voice(),
        },
        {
          text: "Laten we kijken wat dat is!",
          voiceLine: voice(),
          characterImage: Noah.Smiling,
        },
      ],
      results: [
        {
          type: "mission",
          mission: {
            text: "Als je een bijbaan hebt kun je *loonheffingskorting* aanvragen. Laten we kijken wat dat is!",
          },
        },
      ],
    },
    {
      type: "conversation",
      backgroundImage: BackgroundImg,
      buttonText: "Verder",
      replayHere: true,
      steps: [
        {
          text: "Hi! Goed dat jij informatie op komt halen!",
          characterImage: LoonHeffingsCharacter.Waving,
          voiceLine: voice(),
        },
        {
          text: "Je betaalt *belasting* over je inkomen. Dat heet *loonheffing*.",
          voiceLine: voice(),
        },
        {
          voiceLine: voice(),
          text: "Niemand betaalt graag belasting. Gelukkig kunnen we *loonheffingskorting* krijgen.",
          characterImage: LoonHeffingsCharacter.Sweat,
        },
        {
          voiceLine: voice(),
          text: "Met *loonheffingskorting* betaal je minder *belasting*. En hou je meer geld over!",
        },
        {
          voiceLine: voice(),
          text: "Let op! Je mag maar bij 1 werkgever *loonheffingskorting* krijgen.",
          characterImage: LoonHeffingsCharacter.Sweat,
        },
        {
          voiceLine: voice(),
          text: "Je hebt je *loonheffingskorting* al gebruikt bij je bijbaan. Maar straks heb je ook *inkomen* van je *stagevergoeding*.",
        },
      ],
      right: true,
      characterImage: LoonHeffingsCharacter.Default,
    },
    {
      type: "sliderInteraction",
      button: "Kies",
      inputs: [
        {
          type: "radio",
          name: "Bijbaan: €200 per maand",
          on: 72,
          off: 0,
        },
        {
          type: "radio",
          name: "Stagevergoeding: €300 per maand",
          on: 108,
          off: 0,
        },
      ],
      outputs: [
        {
          name: "Loonheffingskorting per maand",
          function(a, b) {
            return a + b;
          },
          formatter: euroFormat.format,
          correctAnswer: (a, b) => {
            return a + b === 108;
          },
        },
      ],
      penalty: 10,
      text: "Vraag je loonheffingskorting bij je bijbaan of je stage aan?",
      feedback: {
        character: (a, b) =>
          a + b === 108
            ? LoonHeffingsCharacter.Waving
            : LoonHeffingsCharacter.Sweat,
        correct: sliderFeedback,
        incorrect: sliderFeedback,
      },
    },
    {
      type: "trueFalse",
      penalty: 10,
      correctCharacter: LoonHeffingsCharacter.Waving,
      incorrectCharacter: LoonHeffingsCharacter.Sweat,
      questions: [
        {
          question: "Waar kun je zien of de loonheffingskorting is gebruikt?",
          answer: 0,
          answers: [
            "Op je loonstrook",
            "Op het loonheffingsformulier",
            "Op je werkrooster",
          ],
          correctText:
            "Klopt! Op je *loonstrook* zie je bij *loonheffing* staan of er geld is ingehouden.",
          incorrectText: (i) =>
            i === 1
              ? "Op het *loonheffingsformulier* zie je of je het hebt aangevraagd. Op je *loonstrook* zie je bij *loonheffing* of *arbeidskorting* of er geld is ingehouden."
              : "Hier zie je dat niet staan. Op je *loonstrook* zie je bij *loonheffing* of *arbeidskorting* of er geld is ingehouden.",
        },
        {
          question:
            "Hoe kun je soms ingehouden loonheffing toch nog terugkrijgen?",
          answer: 0,
          answers: [
            "Door aangifte te doen bij de belastingdienst",
            "Dit krijg je nooit meer terug",
            "Door dit aan je baas te vragen",
          ],
          correctText:
            "Dat klopt. Doe elk jaar voor 1 april *belastingaangifte* om eventueel *ingehouden loonheffing* terug te krijgen.",
          incorrectText: (i) =>
            i === 1
              ? "Je krijgt het in sommige gevallen *wel* terug. Wanneer er *meer loonheffing* is ingehouden dan jij had hoeven betalen."
              : "Jouw baas kan *ingehouden loonheffing* niet terugbetalen. Dit doet de *Belastingdienst*.",
        },
      ],
    },
    {
      type: "conversation",
      backgroundImage: BackgroundImg,
      buttonText: "Verder",
      steps: [
        {
          text: "Als je inkomen verandert, verandert je *loonheffingskorting* ook!",
          voiceLine: voice(),
          characterImage: LoonHeffingsCharacter.Sweat,
        },
        {
          text: "Kijk altijd goed waar je de *loonheffingskorting* gebruikt.",
          voiceLine: voice(),
          characterImage: LoonHeffingsCharacter.Sweat,
        },
        {
          text: "Zo krijg krijg je elke maand het meeste geld op je rekening.",
          voiceLine: voice(),
          buttonText: "Naar de stad",
        },
      ],
      right: true,
      characterImage: LoonHeffingsCharacter.Default,
    },
    {
      type: "redirect",
      redirect: "/rating",
    },
    {
      type: "redirect",
      redirect: "/city",
    },
  ],
  resumeBuilding: "loonheffing",
};

export default module;
