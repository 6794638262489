export const PageAnimation = {
  animate: {
    opacity: [0, 1],
  },
  exit: {
    opacity: 0,
  },
  transition: {
    duration: 0.2,
  },
};
