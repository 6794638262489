/**
 * Here goes all the data that belongs to the user. This data is dynamic and stored in the local storage
 */
import React, { createContext, useContext, useEffect, useState } from "react";
import { gameData } from "./GameData";
import template from "lodash/template";
import merge from "lodash/merge";
import { Achievements } from "./Achievements";
import { defaultUserData } from "./defaultUserData";
import RaccoonAnalytics from "@raccoon-serious-games/raccoon-analytics";
import { analyticsEnabled, startModuleTimer } from "../util/analytics";
import { executeMigrations } from "./migrations/migrations";

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer I>
    ? Array<DeepPartial<I>>
    : DeepPartial<T[P]>;
};

export const UserDataContext = createContext<{
  userData: UserData;
  setUserData: (value: ((prevState: UserData) => UserData) | UserData) => void;
  incrementStage: (mergeObj?: Partial<UserData>) => void;
  currentModule: Module;
  currentStage: Stage;
  getCompiled: (string) => any;
  applyPenalty: (penalty?: number) => void;
  grantAchievement: (achievement: keyof typeof Achievements) => void;
}>({} as any);

const proxyHandler = {
  get: (target, prop) => {
    return target[prop as string] ?? 0;
  },
};

const updateBankResult = (
  bankUpdate: { bankUpdates: UserData["bankUpdates"] },
  result,
  getCompiled: (x: any) => any
) => {
  // if key is already in bankUpdates, update it
  const existing = bankUpdate.bankUpdates.find((b) => b.key === result.key);
  if (existing) {
    existing.amount = getCompiled(result.amount);
    return;
  }

  bankUpdate.bankUpdates.push({
    ...result,
    amount: getCompiled(result.amount),
  });
};

const STORAGE_KEY = "Life@18.userData";
const initData = (): UserData => {
  const data =
    localStorage.getItem(STORAGE_KEY) || localStorage.getItem("userData");
  if (data) {
    try {
      const userData = JSON.parse(data);
      executeMigrations(userData);
      return userData;
    } catch (e) {
      return { ...defaultUserData, failedMigration: data };
    }
  }
  return defaultUserData;
};

export const UserProvider = ({ children }: any) => {
  const [userData, setUserData] = useState<UserData>(initData());

  const proxy = {
    ...userData,
    userChoices: new Proxy(userData.userChoices, proxyHandler),
    computedChoices: new Proxy(userData.computedChoices, proxyHandler),
    income: userData.bankUpdates.reduce(
      (acc, { amount, monthly }) =>
        monthly && amount > 0 ? acc + amount : acc,
      0
    ),
    expenses: userData.bankUpdates.reduce(
      (acc, { amount, monthly }) =>
        monthly && amount < 0 ? acc + -amount : acc,
      0
    ),
  };

  const getCompiled = (x: any) => {
    if (typeof x === "string") {
      const t = template(x, {
        evaluate: /\{\{(.+?)}}/g,
        interpolate: /\{\{=(.+?)}}/g,
        escape: /\{\{-(.+?)}}/g,
      });
      let value = t(proxy);

      try {
        return JSON.parse(value);
      } catch (e) {
        return value;
      }
    }
    return x;
  };

  const currentModule = gameData.modules[userData.progression.currentModule];
  const currentStage = currentModule?.stages[userData.progression.currentStage];

  const grantAchievement = (achievement: keyof typeof Achievements) => {
    setUserData((userData) => {
      if (userData.achievements.find((a) => a.id === achievement)) {
        return userData;
      }
      const achievements = [...userData.achievements, { id: achievement }];
      const update = {
        achievements,
      };
      return merge({}, userData, update);
    });
  };

  const incrementStage = (mergeObj: DeepPartial<UserData> = {}) => {
    setUserData((currentUserData) => {
      const moduleIndex = currentUserData.progression.currentModule;
      const currentModule = gameData.modules[moduleIndex];
      const stageIndex = currentUserData.progression.currentStage;
      const currentStage = currentModule?.stages[stageIndex];

      const updates: DeepPartial<UserData> = {};

      if (moduleIndex === 0 && stageIndex === 0) {
        analyticsEnabled.then(
          (enabled) => enabled && RaccoonAnalytics.teams.started()
        );
        startModuleTimer(moduleIndex, currentUserData.timerId, setUserData);
      }

      const moduleComplete = stageIndex + 1 >= currentModule.stages.length;

      if (moduleComplete) {
        if (moduleIndex + 1 >= gameData.modules.length - 1) {
          analyticsEnabled.then(
            (enabled) => enabled && RaccoonAnalytics.teams.finished()
          );
        }
        analyticsEnabled.then(
          (enabled) =>
            enabled &&
            RaccoonAnalytics.events.record(
              `Module ${currentModule.name} completed`,
              "Module completed"
            )
        );

        startModuleTimer(moduleIndex + 1, currentUserData.timerId, setUserData);
      }

      let newModuleIndex = moduleIndex;
      do {
        newModuleIndex++;
        updates.progression = {
          currentStage: moduleComplete ? 0 : stageIndex + 1,
          currentModule: moduleComplete ? newModuleIndex : moduleIndex,
        };
      } while (
        currentUserData.progression.modulesCompleted.includes(newModuleIndex)
      );

      if (currentStage.type === "skip") {
        updates.progression = {
          currentModule: currentStage.module,
          currentStage: currentStage.stage,
        };
      }

      // If the module is complete, go back to the last checkpoint, if there is one
      if (moduleComplete) {
        const cp = currentUserData.progression.checkpoints.pop();
        if (cp !== undefined) {
          updates.progression.currentModule = cp;
          updates.progression.currentStage = 0;
          updates.progression.checkpoints = [
            ...currentUserData.progression.checkpoints,
          ];
        }

        // assign a rating to the next module
        if (
          currentUserData.progression.moduleRatings[
            updates.progression.currentModule
          ] === -1
        ) {
          updates.progression.moduleRatings = [
            ...currentUserData.progression.moduleRatings,
          ];
          updates.progression.moduleRatings[
            updates.progression.currentModule
          ] = 100;
        }

        // mark module complete
        if (
          !currentUserData.progression.modulesCompleted.includes(moduleIndex)
        ) {
          updates.progression.modulesCompleted = [
            ...currentUserData.progression.modulesCompleted,
            moduleIndex,
          ];
        }
      }

      currentStage?.results?.forEach((result) => {
        if (result.type === "bank") {
          updates.bankUpdates = updates.bankUpdates ?? [
            ...currentUserData.bankUpdates,
          ];
          updateBankResult(updates as UserData, result, getCompiled);
        }
        if (result.type === "save") {
          const acc = updates[result.location];
          let value = getCompiled(result.value);

          updates[result.location] = {
            ...acc,
            [result.name]: value,
          };
        }
        if (result.type === "achievement") {
          updates.achievements = updates.achievements ?? [
            ...currentUserData.achievements,
          ];
          updates.achievements.push(result);
        }
        if (result.type === "mission") {
          updates.missions = updates.missions ?? [...currentUserData.missions];
          updates.missions.push(result.mission);
        }
      });

      return merge({}, currentUserData, updates, mergeObj);
    });
  };

  const applyPenalty = (penalty?: number) => {
    if (!penalty) return;

    setUserData((prevData) => {
      const module = prevData.progression.currentModule;
      const ratings = [...prevData.progression.moduleRatings];
      ratings[module] = Math.max(0, ratings[module] - penalty);
      return merge({}, prevData, {
        progression: {
          moduleRatings: ratings,
        },
      });
    });
  };

  useEffect(() => {
    if (currentStage?.type === "updateData" || currentStage?.type === "skip") {
      incrementStage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStage]);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(userData));
  }, [userData]);

  const value = {
    userData,
    setUserData,
    incrementStage,
    currentModule,
    currentStage,
    getCompiled,
    applyPenalty,
    grantAchievement,
  };

  return (
    <UserDataContext.Provider value={value}>
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserData = () => {
  return useContext(UserDataContext);
};
