import { gameData } from "../data/GameData";
import merge from "lodash/merge";
import { startModuleTimer } from "./analytics";

export const getResetFn = (
  module: number,
  setUserData,
  navigate,
  fromReplay = true
) => {
  return () => {
    setUserData((userData: UserData) => {
      let entryPoint = gameData.modules[module].stages.findIndex(
        (stage) => stage.replayHere
      );
      if (
        entryPoint === -1 ||
        (!fromReplay && !userData.progression.modulesCompleted.includes(module))
      )
        entryPoint = 0;

      if (userData.progression.currentModule !== module) {
        userData.progression.checkpoints.push(
          userData.progression.currentModule
        );
      }

      startModuleTimer(module, userData.timerId, setUserData);
      userData.progression.currentStage = entryPoint;
      userData.progression.currentModule = module;
      const oldRating = userData.progression.moduleRatings[module];
      if (oldRating !== -1)
        userData.progression.previousRatings[module].push(oldRating);
      userData.progression.moduleRatings[module] = 100;
      return merge({}, userData);
    });
    navigate("/game");
  };
};
