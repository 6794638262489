const Colors = {
  purple: {
    main: "#7329E8",
    dark: "#442491",
    light: "#902DF9",
    lightest: "#b26aff",
    superLight: "#DAB8FF",
  },
  white: {
    dark: "#B1B1B1",
    light: "#FFFFFF",
    main: "#D8D8D8",
  },
  red: {
    main: "#BD4F5C",
    dark: "#97434D",
    light: "#E04355",
  },
  yellow: {
    dark: "#FEB11A",
    main: "#FEDB4A",
    light: "#FEDB4A",
  },
  orange: {
    dark: "#bc5e00",
    main: "#d87100",
    light: "#f47a00",
  },
  // TODO temp colors to test theming
  blue: {
    main: "#2F80ED",
    dark: "#1D4ED8",
    light: "#5d9aee",
    lightest: "#8bb8f2",
  },
  green: {
    dark: "#83C46F",
    light: "#94DE7E",
  },
  link: "#00A5C5",
  // yellow: {
  //   light: "#FEDB4A",
  //   dark: "#FEB11A",
  // },
};

const Theme = (color = "purple") => ({
  ...Colors[color],
  ...Colors,
});

export const invertedButtonTheme = (theme) => ({
  text: theme.dark,
  dark: "#B1B1B1",
  light: "#FFFFFF",
  main: "#D8D8D8",
});

export default Theme;
