import React, { ComponentProps, forwardRef } from "react";
import styled from "styled-components";

interface Props {
  light?: boolean;
}

const StyledGradientBlock = styled.div.withConfig({
  componentId: "GradientBlock",
})<Props>`
  --color-one: ${({ theme, light }) => (light ? theme.light : theme.main)};
  --color-two: ${({ theme, light }) => (light ? theme.main : theme.dark)};

  background: linear-gradient(to bottom, var(--color-one), var(--color-two));
  padding: 4px;
  border-radius: 50px;
  box-shadow: 0 5px 6px #45249125;

  .inner {
    background: linear-gradient(to bottom, var(--color-two), var(--color-one));
    border-radius: 50px;
    overflow: hidden;
    height: 100%;
  }
`;

const _GradientBlock = (
  { children, ...props }: ComponentProps<typeof StyledGradientBlock>,
  ref: React.ElementRef<any>
) => {
  return (
    <StyledGradientBlock ref={ref} {...props}>
      <div className="inner">{children}</div>
    </StyledGradientBlock>
  );
};

const GradientBlock = forwardRef(_GradientBlock);

export default GradientBlock;
