import React, { ComponentProps } from "react";
import styled from "styled-components";
import ThemeButton from "../ThemeButton/ThemeButton";

import BackIcon from "./icons/back-icon-img.svg";
import CloseIcon from "./icons/close-icon-img.svg";
import SkipIcon from "./icons/skip-icon-img.svg";
import QuestionMarkIcon from "./icons/questionmark-icon-img.svg";
import SettingsIcon from "./icons/settings-icon-img.svg";
import SoundIcon from "./icons/sound-icon-img.svg";
import MutedIcon from "./icons/muted-icon-img.svg";
import ReplayIcon from "./icons/icon-replay-audio.svg";

export enum Icon {
  Back = BackIcon,
  Close = CloseIcon,
  Skip = SkipIcon,
  QuestionMark = QuestionMarkIcon,
  Settings = SettingsIcon,
  Sound = SoundIcon,
  Muted = MutedIcon,
  Replay = ReplayIcon,
}

interface Props {
  size?: number;
}

const StyledButton = styled(ThemeButton)<Props>`
  width: ${({ size }) => size}px !important;
  height: ${({ size }) => size}px !important;

  .GradientBlock,
  .GradientBlock .inner {
    width: 100%;
    height: 100%;
  }

  .GradientBlock .inner {
    padding: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
      max-width: 80%;
      max-height: 80%;
      filter: drop-shadow(0 1px 1px #00000033);
    }
  }
`;

StyledButton.defaultProps = {
  playSound: false,
  size: 32,
};

const IconButton = ({
  icon,
  ...props
}: ComponentProps<typeof StyledButton> & {
  icon?: keyof typeof Icon;
} & Props) => {
  const iconImg = Icon[icon].toString();

  return (
    <StyledButton {...props}>
      {iconImg && <img alt={icon} src={iconImg} />}
    </StyledButton>
  );
};

export default IconButton;
