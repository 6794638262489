import React, { useState } from "react";
import styled from "styled-components";
import ThemeButton from "../../components/ThemeButton/ThemeButton";
import { ReactSortable } from "react-sortablejs";
import Circles from "../../components/Circles/Circles";
import { motion } from "framer-motion";
import { useAudio } from "../../assets/audio/Audio";
import { useUserData } from "../../data/UserData";
import Theme from "../../theme";
import Help from "../../views/Help/Help";

const [red, green] = [Theme("red"), Theme("green")];

const StyledDragDrop = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-block: 20px;
  gap: 20px;

  .list {
    flex: 1;
    display: flex;
    flex-direction: column;

    header {
      color: white;
      text-align: center;
      font-weight: 600;
      font-size: 1.2em;
      margin-bottom: 20px;
      padding-inline: 20px;
    }

    ul {
      flex: 1 1 0;
      overflow-x: visible;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 0;
      gap: 5px;
      font-size: 0.9em;
      list-style: none;
      padding-inline-start: 10px;
      padding-inline-end: 20px;

      counter-reset: entry;

      // Hide scrollbar for Chrome, Safari and Opera
      &::-webkit-scrollbar {
        display: none;
      }

      // Hide scrollbar for IE, Edge and Firefox
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      .entry {
        display: flex;
        align-items: center;
        gap: 10px;

        &.sortable-drag {
          .counter {
            opacity: 0;
          }
        }

        .counter {
          color: white;
          font-family: var(--emphasis-font);
          font-size: 1.5em;
          counter-increment: entry;

          ::before {
            content: counter(entry);
          }
        }

        .item {
          --color: white;
          min-height: 50px;
          border: 4px solid ${(props) => props.theme.dark};
          border-radius: 13px;
          list-style: none;
          background: white;
          padding-inline: 10px;
          flex: 1;

          display: flex;
          align-items: center;

          background: linear-gradient(90deg, white, var(--color));
          transition: --color 0.2s ease-in-out, border-color 0.2s ease-in-out;

          justify-content: space-between;

          span {
            padding-block: 10px;
          }

          img {
            max-height: 2.5em;
          }
        }
      }
    }
  }

  .button {
    width: 100%;
    box-sizing: border-box;
    padding-inline: 20px;
  }
`;

const DragDropInteraction = ({ config }: { config: IDragDropInteraction }) => {
  const { play } = useAudio();
  const { applyPenalty, incrementStage } = useUserData();
  const [entries, setEntries] = useState(
    config.entries.map((entry, i) => ({ ...entry, id: i }))
  );
  const { help } = config;

  const clearCorrect = () => {
    const elements = document.querySelectorAll<HTMLDivElement>(".entry .item");
    elements.forEach((element) => {
      element.style.borderColor = undefined;
      element.style.setProperty("--color", "white");
    });
  };

  const flashCorrect = () => {
    // flash correct elements as green, and incorrect as red
    const elements = document.querySelectorAll<HTMLDivElement>(".entry .item");
    elements.forEach((element, i) => {
      setTimeout(() => {
        element.style.borderColor =
          entries[i].correctPosition === i ? green.dark : red.dark;
        element.style.setProperty(
          "--color",
          entries[i].correctPosition === i ? green.light : red.light
        );
      }, (i + 1) * 200);
    });
  };

  const check = () => {
    clearCorrect();
    const correct = entries.every((entry, i) => entry.correctPosition === i);
    if (correct) {
      play("pop");
      incrementStage();
    } else {
      play("wrongAnswer");
      applyPenalty(config.penalty);
      setTimeout(flashCorrect, 200);
    }
  };

  const accessibility = {
    current: `Huidige volgorde: ${entries
      .map((entry) => entry.description)
      .join(", ")}`,
  };

  const moveUp = (index: number) => {
    if (index === 0) return;
    const newEntries = [...entries];
    const temp = newEntries[index - 1];
    newEntries[index - 1] = newEntries[index];
    newEntries[index] = temp;
    setEntries(newEntries);
  };

  const moveDown = (index: number) => {
    if (index === entries.length - 1) return;
    const newEntries = [...entries];
    const temp = newEntries[index + 1];
    newEntries[index + 1] = newEntries[index];
    newEntries[index] = temp;
    setEntries(newEntries);
  };

  return (
    <StyledDragDrop>
      <Circles
        style={{
          position: "absolute",
          left: "-75%",
          right: "-75%",
          bottom: "10%",
          zIndex: -1,
        }}
        animate={{
          y: ["-100%", "0%"],
        }}
        transition={{
          duration: 0.8,
          ease: "easeOut",
        }}
      ></Circles>
      <motion.div
        className="list"
        animate={{
          opacity: [0, 1],
          y: [50, 0],
        }}
        transition={{
          delay: 0.6,
          duration: 0.6,
          ease: "easeOut",
        }}
      >
        <header
          style={{
            marginRight: help ? 20 + 32 : undefined,
          }}
        >
          Zet op de juiste volgorde
        </header>
        <div className="sr-only" aria-live="polite">
          {accessibility.current}
        </div>
        <ReactSortable
          tag="ul"
          list={entries}
          setList={setEntries}
          animation={150}
          scroll={true}
        >
          {entries.map((entry, i) => (
            <li className="entry" key={entry.id}>
              <div className="counter"></div>
              <div className="item">
                <span>{entry.description}</span>
                <div className="sr-only">
                  <button onClick={() => moveUp(i)}>Verplaats omhoog</button>
                  <button onClick={() => moveDown(i)}>Verplaats omlaag</button>
                </div>
                <img src={entry.image} aria-hidden alt="" />
              </div>
            </li>
          ))}
        </ReactSortable>
      </motion.div>
      <motion.div
        animate={{
          y: [100, 0],
        }}
        transition={{
          duration: 0.4,
          delay: 0.6,
          ease: "easeOut",
        }}
      >
        <ThemeButton onClick={check} className="button" playSound={false}>
          Controleer
        </ThemeButton>
      </motion.div>
      {help && <Help help={help}></Help>}
    </StyledDragDrop>
  );
};

export default DragDropInteraction;
