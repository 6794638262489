import { _Variable } from "./GameData";

export enum Choices {
  eigenRisicoZorgverzekering = "eigenRisicoZorgverzekering",
  aanvullendeVerzekeringTandarts = "aanvullendeVerzekeringTandarts",
  aanvullendeVerzekeringBrilLenzen = "aanvullendeVerzekeringBrilLenzen",
  aanvullendeVerzekeringSpoedeisendeHulpBuitenland = "aanvullendeVerzekeringSpoedeisendeHulpBuitenland",
  aanvullendeVerzekeringFysio = "aanvullendeVerzekeringFysio",
  aanvullendeVerzekeringAnticonceptie = "aanvullendeVerzekeringAnticonceptie",

  zorgToeslag = "zorgToeslag",

  aansprakelijkheidsVerzekering = "aansprakelijkheidsVerzekering",

  stufi = "stufi",
  stufiLening = "stufiLening",
}

export enum Computed {
  maandelijksePremie = "maandelijksePremie",

  premieZorgverzekering = "premieZorgverzekering",

  quizCorrect = "quizCorrect",
}

export const Variable = _Variable<keyof typeof Choices | keyof typeof Computed>(
  [Choices, "userChoices"],
  [Computed, "computedChoices"]
);
