import React, {
  ComponentPropsWithRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import {
  motion,
  useAnimationControls,
  useMotionValue,
  useSpring,
  useTransform,
} from "framer-motion";
import throttle from "lodash/throttle";

const updateLabel = (label: HTMLDivElement, value: number | string) => {
  label.innerText = value.toString();
};

type Props = { input: IRangeInput; onChange?: (value: any) => void };

const StyledRangeInput = styled.div<Props>`
  // TODO move padding to parent
  padding: 0 20px;

  .big-label {
    display: inline-block;
    position: relative;
    font-weight: 900;
    color: ${(props) => props.theme.dark};
    transform: translateX(-50%);
  }

  & > .label {
    font-weight: 900;
    color: ${(props) => props.theme.dark};
    font-size: 0.75em;
    position: relative;
    opacity: 0.5;
    left: -10px;
  }

  .track {
    position: relative;
    height: 30px;

    .all-track {
      position: absolute;
      top: 50%;
      left: 0;
      height: 5px;
      background: ${(props) => props.theme.superLight};
      width: 100%;
      transform: translateY(-50%);
    }

    .visited-track {
      position: absolute;
      top: 0;
      left: 0;
      height: 5px;
      background: linear-gradient(
        to bottom,
        ${(props) => props.theme.main},
        ${(props) => props.theme.dark}
      );
    }

    .thumb {
      position: absolute;
      width: 22px;
      height: 22px;
      background: linear-gradient(
        to bottom,
        ${(props) => props.theme.main},
        ${(props) => props.theme.dark}
      );
      border-radius: 50%;
      padding: 2px;

      top: -8px;
      left: -11px;

      cursor: pointer;

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;

        background: linear-gradient(
          to bottom,
          ${(props) => props.theme.dark},
          ${(props) => props.theme.main}
        );
        border-radius: 50%;
      }
    }

    .marks {
      top: -4px;
      position: absolute;
      display: flex;
      justify-content: space-between;
      width: 100%;
      .mark {
        position: relative;
        width: 3px;
        height: 13px;
        cursor: pointer;

        &::before {
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          position: absolute;
          transform: translateX(-50%);
          top: -5px;
        }

        background: linear-gradient(
          to bottom,
          ${(props) => props.theme.dark},
          ${(props) => props.theme.main}
        );

        .label {
          font-size: 0.75em;
          position: absolute;
          top: 16px;
          transform: translateX(-50%);
          color: ${(props) => props.theme.dark};
          font-weight: 700;
        }
      }
    }
  }
`;

const RangeInput = ({
  input,
  onChange,
  ...props
}: ComponentPropsWithRef<typeof StyledRangeInput> & Props) => {
  let { formatter = (x) => x, mapping, max, min, step, label, start } = input;

  const getValue = useCallback(
    (i) => {
      const v = i * step + min;

      if (mapping) {
        if (typeof mapping === "function") {
          return mapping(v);
        } else {
          return mapping[i];
        }
      } else {
        return v;
      }
    },
    [mapping, min, step]
  );

  const [value, setValue] = useState(step ? getValue(0) : min);
  const track = useRef<HTMLDivElement>();
  const numMarks = (max - min) / step + 1;
  const [cellWidth, setCellWidth] = React.useState(0);
  const cells = Array.from({ length: numMarks }, (_, i) => i * cellWidth);

  const bigLabel = useAnimationControls();
  const motionValue = useMotionValue(0);
  const spring = useSpring(0, {
    bounce: 0,
    damping: 10,
    mass: 0.01,
  });
  const thumbX = step > 0 ? spring : motionValue;
  const labelX = useTransform(thumbX, (x) => `calc(-50% + ${x}px)`);

  const updateValue = useCallback(
    (newValue) => {
      setValue(newValue);
      bigLabel.start({
        scale: 1,
      });
      if (onChange) {
        onChange(newValue);
      }
    },
    [bigLabel, onChange]
  );

  const moveToCell = (closest) => {
    thumbX.stop();
    thumbX.set(closest);
    const v = cells.indexOf(closest);
    const newValue = getValue(v);
    updateValue(newValue);
  };

  const onDragEnd = () => {
    const x = thumbX.get();
    if (step > 0) {
      const closest = cells.reduce((prev, curr) =>
        Math.abs(curr - x) < Math.abs(prev - x) ? curr : prev
      );
      moveToCell(closest);
    } else {
      const value = (max - min) * (x / track.current.clientWidth) + min;
      updateValue(value);
    }
  };

  const onDragStart = () => {
    if (step > 0)
      bigLabel.start({
        scale: 0,
      });
  };

  const onDrag = throttle(() => {
    if (step > 0) return;
    const x = thumbX.get();
    const value = (max - min) * (x / track.current.clientWidth) + min;
    updateLabel(labelRef.current, formatter(value));
  }, 100);

  useEffect(() => {
    setCellWidth(track.current.clientWidth / (numMarks - 1));
  }, [numMarks]);

  const labelRef = useRef<HTMLDivElement>(null);
  if (labelRef.current) updateLabel(labelRef.current, formatter(value));

  const clickTrack = (e) => {
    if (step > 0) return;
    const x = e.clientX - track.current.getBoundingClientRect().left;
    const value = (max - min) * (x / track.current.clientWidth) + min;
    updateValue(value);
    thumbX.set(x);
  };

  // Start at a different value
  useEffect(() => {
    if (start) {
      if (Array.isArray(mapping)) {
        const v = mapping[start];
        const i = cells[start];
        updateValue(v);
        thumbX.set(i);
      }
    }
  }, [start, cellWidth]); // TODO: look into depency array

  const nextValue = () => {
    if (step > 0) {
      // get index of closest cell to thumbX
      const closest = cells.reduce((prev, curr) =>
        Math.abs(curr - thumbX.get()) < Math.abs(prev - thumbX.get())
          ? curr
          : prev
      );
      const i = cells.indexOf(closest);
      const v = getValue(i + 1);
      if (!v) return;
      updateValue(v);
      thumbX.set(cells[i + 1]);
    } else {
      const newValue = Math.min(value + (max - min) * 0.05, max);
      updateValue(newValue);
      thumbX.set((newValue / (max - min)) * track.current.clientWidth);
    }
  };

  const prevValue = () => {
    if (step > 0) {
      // get index of closest cell to thumbX
      const closest = cells.reduce((prev, curr) =>
        Math.abs(curr - thumbX.get()) < Math.abs(prev - thumbX.get())
          ? curr
          : prev
      );
      const i = cells.indexOf(closest);
      const v = getValue(i - 1);
      if (!v) return;
      updateValue(v);
      thumbX.set(cells[i - 1]);
    } else {
      const newValue = Math.max(value - (max - min) * 0.05, min);
      updateValue(newValue);
      thumbX.set((newValue / (max - min)) * track.current.clientWidth);
    }
  };

  return (
    <StyledRangeInput {...props}>
      <div className="label">{label}</div>
      <motion.div
        style={{ originY: "100%", x: labelX }}
        animate={bigLabel}
        className="big-label"
        ref={labelRef}
      ></motion.div>
      <div
        className="track"
        // tabIndex={0}
        ref={track}
        onClick={clickTrack}
        // role="slider"
        aria-valuetext={formatter(value)}
        onKeyUp={(e) => {
          if (e.key === "ArrowRight") {
            nextValue();
          }
          if (e.key === "ArrowLeft") {
            prevValue();
          }
        }}
      >
        <div className="all-track">
          <motion.div
            className="visited-track"
            style={{
              width: thumbX,
            }}
          ></motion.div>
          {step > 0 && (
            <div className="marks">
              {cells.map((v, i) => (
                <div
                  className="mark"
                  key={i}
                  onClick={() => moveToCell(v)}
                  tabIndex={0}
                  aria-label={formatter(getValue(i))}
                >
                  {(i === 0 || i === cells.length - 1) && (
                    <motion.div
                      animate={{ scale: value === getValue(i) ? 0 : 1 }}
                      style={{ originY: 0, x: "-50%" }}
                      className="label"
                    >
                      {formatter(getValue(i))}
                    </motion.div>
                  )}
                </div>
              ))}
            </div>
          )}
          <motion.div
            drag="x"
            className="thumb"
            dragConstraints={{ left: 0, right: track.current?.clientWidth }}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onDrag={onDrag}
            onClick={(e) => e.stopPropagation()}
            dragElastic={0}
            dragMomentum={false}
            dragTransition={{
              bounceStiffness: 0,
              bounceDamping: 0,
            }}
            style={{ x: thumbX }}
          />
        </div>
      </div>
    </StyledRangeInput>
  );
};

export default RangeInput;
