import React, { ComponentPropsWithRef } from "react";
import styled from "styled-components";
import Theme from "../../../theme";

interface AchievementProps {
  achievement: Achievement;
  unlocked?: boolean;
}

export const StyledAchievement = styled.div<AchievementProps>`
  //width: 133px;
  //height: 202px;
  background: linear-gradient(
    to bottom,
    ${(props) => props.theme.dark},
    ${(props) => props.theme.main}
  );
  border: 4px solid ${(props) => props.theme.main};
  border-radius: 16px;
  text-align: center;
  ${({ unlocked }) => !unlocked && "filter: grayscale(100%);"}
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block: 10px;
  padding-inline: 5px;

  .icon {
    height: 100px;
    margin-bottom: 20px;
    //margin-top: auto;
  }

  .bottom {
    margin-block: auto;
  }

  .title {
    font-weight: 700;
  }

  .description {
    font-size: 0.75em;
  }
`;

const yellow = Theme("yellow");

export const Achievement = ({
  achievement,
  unlocked,
  ...props
}: ComponentPropsWithRef<typeof StyledAchievement> & AchievementProps) => {
  return (
    <StyledAchievement
      {...props}
      theme={yellow}
      unlocked={unlocked}
      achievement={achievement}
    >
      <img
        src={unlocked ? achievement.unlocked : achievement.locked}
        alt="Logo Achievement"
        className="icon"
      />
      <div className="bottom">
        <div className="title">{achievement.name}</div>
        <div className="description">{achievement.description}</div>
      </div>
    </StyledAchievement>
  );
};
