import GoedBeginLottie from "../assets/achievements/eerste-achievement.lottie";
import GoedBeginLocked from "../assets/achievements/goed begin_locked.svg";
import GoedBeginUnlocked from "../assets/achievements/goed begin_achieved.svg";

import StudieLocked from "../assets/achievements/studie_locked.svg";
import StudieUnlocked from "../assets/achievements/studie_achieved.svg";
import StudieLottie from "../assets/achievements/studie.lottie";

import ToeslagenLocked from "../assets/achievements/toeslagen_locked.svg";
import ToeslagenUnlocked from "../assets/achievements/toeslagen_achieved.svg";
import ToeslagenLottie from "../assets/achievements/toeslagen.lottie";

import VerbeterenLocked from "../assets/achievements/verbeteren_locked.svg";
import VerbeterenUnlocked from "../assets/achievements/verbeteren_achieved.svg";
import VerbeterenLottie from "../assets/achievements/verbeteren.lottie";

import SterrenLocked from "../assets/achievements/sterren_locked.svg";
import SterrenUnlocked from "../assets/achievements/sterren_achieved.svg";
import SterrenLottie from "../assets/achievements/sterren.lottie";

import QuizLocked from "../assets/achievements/quiz-locked.svg";
import QuizUnlocked from "../assets/achievements/quiz-achieved.svg";
import QuizLottie from "../assets/achievements/quiz.lottie";

import { gameData } from "./GameData";
import EindQuizModule from "./Modules/9-EindQuiz/EindQuizModule";

export const Achievements: {
  [key: string]: Achievement;
} = {
  goedBegin: {
    name: "Een goed begin is het halve werk!",
    description: "Gefeliciteerd! Je hebt je eerste zaak geregeld!",
    locked: GoedBeginLocked,
    unlocked: GoedBeginUnlocked,
    animation: GoedBeginLottie,
    check(userData) {
      return userData.progression.modulesCompleted.includes(1);
    },
  },
  studie: {
    name: "Klaar voor studie!",
    description: "Goed zo! Je hebt alles geregeld om naar school te gaan.",
    locked: StudieLocked,
    unlocked: StudieUnlocked,
    animation: StudieLottie,
    check(userData) {
      return userData.progression.modulesCompleted.includes(4);
    },
  },
  toeslagen: {
    name: "Toeslagen",
    description: "Yes! Alle toeslagen zijn aangevraagd!",
    locked: ToeslagenLocked,
    unlocked: ToeslagenUnlocked,
    animation: ToeslagenLottie,
    check(userData) {
      return userData.progression.modulesCompleted.includes(2);
    },
  },
  verbeteren: {
    name: "Verbeteren",
    description: "Goed bezig. Je hebt jezelf al 1x verbeterd!",
    locked: VerbeterenLocked,
    unlocked: VerbeterenUnlocked,
    animation: VerbeterenLottie,
    check(userData) {
      const ratings = gameData.modules.map(() => []);
      userData.progression.previousRatings.forEach((prevRatings, i) => {
        ratings[i].push(...prevRatings);
      });
      userData.progression.moduleRatings.forEach((rating, i) => {
        ratings[i].push(rating);
      });

      return ratings.some((ratings) => {
        if (userData.progression.currentModule <= 1) return false;
        // find any improvment between two adjacent ratings
        for (let i = 0; i < ratings.length - 1; i++) {
          if (ratings[i + 1] > ratings[i]) {
            return true;
          }
        }
        return false;
      });
    },
  },
  sterren: {
    name: "Sterren",
    description: "Hoera! Je hebt al 10 sterren verdiend. Ga zo door!",
    locked: SterrenLocked,
    unlocked: SterrenUnlocked,
    animation: SterrenLottie,
    check(userData) {
      const completedModuleRatings = userData.progression.modulesCompleted.map(
        (i) => userData.progression.moduleRatings[i]
      );

      const completedModuleStars = completedModuleRatings.map((rating) =>
        rating === 100 ? 3 : rating >= 50 ? 2 : 1
      );
      const totalStars = completedModuleStars.reduce((a, b) => a + b, 0);

      return totalStars >= 10;
    },
  },
  quiz: {
    name: "Quiz master",
    description: "Wow! Je hebt de quiz gedaan zonder fouten te maken!",
    locked: QuizLocked,
    unlocked: QuizUnlocked,
    animation: QuizLottie,
    check(userData) {
      const i = gameData.modules.indexOf(EindQuizModule);
      return (
        userData.progression.currentModule > i &&
        userData.progression.moduleRatings[i] === 100
      );
    },
  },
};
