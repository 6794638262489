import ZorgverzekeringModule from "./Modules/1-ZorgverzekeringModule/ZorgverzekeringModule";
import IntroModule from "./Modules/0-IntroModule/IntroModule";
import ZorgToeslagModule from "./Modules/2-ZorgToeslagModule/ZorgToeslagModule";
import AansprakelijkheidsverzekeringModule from "./Modules/5-AansprakelijkheidsModule/AansprakelijkheidsVerzekeringModule";
import Building from "../views/City/Building/Building";
import { Achievements } from "./Achievements";
import StufiModule from "./Modules/4-StufiModule/StufiModule";
import StageVergoedingModule from "./Modules/6-StageVergoedingModule/StageVergoedingModule";
import LoonHeffingsKortingModule from "./Modules/7-LoonheffingsModule/LoonHeffingsKortingModule";
import EindQuizModule from "./Modules/9-EindQuiz/EindQuizModule";
import DonorModule from "./Modules/3-DonorModule/DonorModule";
import StemModule from "./Modules/8-Stemmen/StemModule";

export const defaultSettings = {
  musicVolume: 1,
  soundVolume: 1,
};

export const gameData = {
  modules: [
    IntroModule,
    ZorgverzekeringModule,
    ZorgToeslagModule,
    DonorModule,
    StufiModule,
    AansprakelijkheidsverzekeringModule,
    StageVergoedingModule,
    LoonHeffingsKortingModule,
    StemModule,
    EindQuizModule,
    {
      name: "Eind",
      stages: [],
    },
  ],
  achievements: Achievements,
};

export function gameLinkActive(
  module: Module,
  stage: Stage,
  building: Building
) {
  if (building.name !== module.resumeBuilding) return false;
  return [
    "sliderInteraction",
    "bubblePopperInteraction",
    "trueFalse",
    "conversation",
    "choice",
    "event",
    "goToBuilding",
    "dragDropInteraction",
    "votingInteraction",
  ].includes(stage.type);
}

export function _Variable<T = string>(...enums: [object, string][]) {
  return function (x: T) {
    for (let i = 0; i < enums.length; i++) {
      if (enums[i][0][x as string]) {
        return `${enums[i][1]}.${x}`;
      }
    }
  };
}
