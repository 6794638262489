import React, { useRef } from "react";

import styled from "styled-components";
import { motion } from "framer-motion";
import { Player } from "@lottiefiles/react-lottie-player";

import Animation from "./anim.lottie";
import { useUserData } from "../../../data/UserData";
import { gameData } from "../../../data/GameData";
import IconButton from "../../../components/IconButton/IconButton";
import { useNavigate } from "react-router-dom";
import { Achievement } from "./Achievement";
import { useDraggable } from "react-use-draggable-scroll";
import { isMobile } from "react-device-detect";
import Noah from "../../../assets/characters/Noah/Noah";
import Help from "../../Help/Help";

import VoiceLine from "./Noah-Kristel-08.4-22.mp3";
import ThemeButton from "../../../components/ThemeButton/ThemeButton";

const StyledAchievementShowroom = styled.div`
  height: 100%;
  position: relative;

  .icon-button {
    position: absolute;
    top: 20px;

    &.back {
      left: 20px;
    }

    &.help {
      right: 20px;
    }
  }

  .scroll {
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 10px 10px;

    // hide scrollbar
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  color: ${({ theme }) => theme.dark};

  #lottie {
    width: 100%;
    width: min(100cqw, 50cqh);
  }

  .header {
    font-size: 1.5em;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .main {
    position: relative;
    margin-top: auto;
    padding: 10px;
    max-width: 100%;
    display: grid;
    justify-content: center;
    background: linear-gradient(
      to bottom,
      ${({ theme }) => theme.dark},
      ${({ theme }) => theme.main}
    );
    //flex-wrap: wrap;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(133px, 1fr));
    grid-auto-rows: 1fr;
    border-radius: 16px;
  }

  .main-wrapper {
    //height: 0;
    //flex: 1;
    width: 100%;
    //overflow-y: auto;
    border-radius: 16px;
  }
`;

const AchievementShowroom = () => {
  const navigate = useNavigate();
  const scrollable = useRef<HTMLDivElement>();
  let { events } = useDraggable(scrollable) as any;
  events = isMobile ? {} : events;

  const { userData } = useUserData();
  const { achievements } = userData;
  const allAchievements = Object.entries(gameData.achievements);

  const firstTime =
    userData.progression.currentModule === 2 &&
    userData.progression.currentStage === 2;

  return (
    <StyledAchievementShowroom>
      <div
        className="scroll"
        ref={scrollable}
        style={
          firstTime
            ? {
                paddingBottom: 100,
              }
            : {}
        }
        {...events}
      >
        <Player autoplay keepLastFrame src={Animation}></Player>
        <motion.div
          animate={{
            scale: [0, 1],
          }}
          className="header"
        >
          Achievement Showroom
        </motion.div>

        <motion.div
          animate={{
            right: ["100%", "0%"],
          }}
          className="main"
        >
          {allAchievements.map(([id, achievement]) => (
            <Achievement
              key={id}
              achievement={achievement}
              unlocked={!!achievements.find((a) => a.id === id)}
            />
          ))}
        </motion.div>
      </div>
      <IconButton
        onClick={() => navigate("/city")}
        className="icon-button back"
        icon="Back"
      />
      {firstTime && (
        <div
          style={{
            position: "absolute",
            bottom: 20,
            right: 20,
            left: 20,
          }}
        >
          <ThemeButton onClick={() => navigate("/city")}>
            Naar de stad
          </ThemeButton>
        </div>
      )}
      {/*<IconButton className="icon-button help" icon="QuestionMark" />*/}
      <Help
        help={{
          text: "Hier zie je alle achievements die je kunt behalen. Lukt het jou om de prijzenkast te vullen?",
          characterImage: Noah.Default,
          voiceLine: [VoiceLine],
          buttonText: firstTime ? "Verder" : undefined,
        }}
        open={firstTime}
      ></Help>
    </StyledAchievementShowroom>
  );
};

export default AchievementShowroom;
