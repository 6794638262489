import React, { useState } from "react";
import AlertScreen from "../../components/AlertScreen/AlertScreen";
import Theme from "../../theme";
import { ThemeProvider } from "styled-components";
import ThemeButton from "../../components/ThemeButton/ThemeButton";
import { useUserData } from "../../data/UserData";
import { useNavigate } from "react-router-dom";
import { defaultUserData } from "../../data/defaultUserData";
import { gameData } from "../../data/GameData";
import PopUp from "../../components/PopUp/PopUp";

const red = Theme("red");

const Oops = () => {
  const { setUserData } = useUserData();
  const navigate = useNavigate();

  const [resetFunction, setResetFunction] = useState<() => void>(null);

  const begin = () => {
    setUserData(defaultUserData);
    navigate("/start");
  };

  const end = () => {
    const lastModule = gameData.modules.length - 1;

    setUserData({
      ...defaultUserData,
      progression: {
        ...defaultUserData.progression,
        currentModule: lastModule,
      },
    });

    navigate("/start");
  };

  return (
    <ThemeProvider theme={red}>
      <AlertScreen
        showBack={false}
        header="Oeps..."
        style={{
          textAlign: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <p>
            Sinds je de game voor het laatst hebt gespeeld, hebben we veel
            nieuwe dingen toegevoegd!
          </p>
          <p>
            Daarom zijn jouw opgeslagen gegevens niet meer geldig. We moeten ze
            helaas verwijderen.
          </p>
          <p>
            Je kan nu kiezen. <br />
            Wil je vanaf het begin beginnen, en elk huisje langsgaan? <br />
            Of wil je naar het einde gaan, en in je eigen tempo de nieuwe
            huisjes ontdekken?
          </p>
          <div
            style={{
              display: "flex",
              gap: "10px",
            }}
          >
            <ThemeButton
              onClick={() => {
                setResetFunction(() => begin);
              }}
            >
              Begin
            </ThemeButton>
            <ThemeButton
              onClick={() => {
                setResetFunction(() => end);
              }}
            >
              Einde
            </ThemeButton>
          </div>
        </div>
      </AlertScreen>
      {resetFunction && (
        <PopUp>
          <div
            style={{
              padding: 20,
              display: "flex",
              flexDirection: "column",
              gap: 20,
            }}
          >
            <span>Weet je het zeker?</span>
            <div
              style={{
                display: "flex",
                gap: 10,
                width: 200,
              }}
            >
              <ThemeButton onClick={resetFunction}>Ja</ThemeButton>
              <ThemeButton onClick={() => setResetFunction(null)}>
                Nee
              </ThemeButton>
            </div>
          </div>
        </PopUp>
      )}
    </ThemeProvider>
  );
};

export default Oops;
