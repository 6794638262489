import React, { ComponentPropsWithRef } from "react";

import StarSilver from "../../../../assets/stars/star-gray.svg";
import StarGold from "../../../../assets/stars/star-gold-1.svg";

import styled from "styled-components";

interface Props {
  rating: number;
}

const StyledRating = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, calc(-100% - 20px));
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

  img {
    width: 40px;
    height: 40px;
  }

  & > :first-child {
    transform: rotate(-20deg);
  }

  & > :nth-child(2) {
    transform: translateY(-20px);
  }

  & > :last-child {
    transform: rotate(20deg);
  }
`;

const Rating = ({
  rating,
  ...props
}: ComponentPropsWithRef<typeof StyledRating> & Props) => {
  const goldStars = Math.floor(rating / 50) + 1;

  return (
    <StyledRating
      role="text"
      aria-label={`Je hebt ${goldStars} van de 3 sterren voor dit onderdeel.`}
      {...props}
    >
      <img src={StarGold} alt="Gouden ster" />
      <img
        src={rating >= 50 ? StarGold : StarSilver}
        alt={`${rating >= 50 ? "Gold" : "Silver"} star`}
      />
      <img
        src={rating >= 100 ? StarGold : StarSilver}
        alt={`${rating >= 100 ? "Gold" : "Silver"} star`}
      />
    </StyledRating>
  );
};

export default Rating;
