import React from "react";
import { useUserData } from "../../data/UserData";
import Noah from "../../assets/characters/Noah/Noah";
import Help from "../Help/Help";

const Misson = () => {
  const { userData } = useUserData();

  const latestMission = userData.missions[userData.missions.length - 1];

  return latestMission ? (
    <Help
      openButtonPosition="bottom"
      help={{
        characterImage: Noah.Default,
        text: latestMission.text,
      }}
    ></Help>
  ) : (
    <></>
  );
};

export default Misson;
