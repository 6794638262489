import Noah from "../../../assets/characters/Noah/Noah";

import eventImg1 from "./images/event/event1.1-accident.svg";
import eventImg2 from "./images/event/event1.2-ziekenhuis.svg";
import ZorgToeslagCharacter from "./images/Zorgtoeslag/ZorgToeslag";
import Background from "./images/zorgtoeslag-inside_2.svg";
import { Choices, Variable } from "../../variables";
import { euroFormat } from "../../../util/EuroFormat";
import { getVoiceLineGenerator } from "../../../util/Voice";
import StreetAnim from "./Event-straat oversteken.lottie";

const eventImages = [eventImg1, eventImg2];

const importAll = (require) =>
  require.keys().reduce((acc, next) => {
    acc[next.replace("./", "")] = require(next);
    return acc;
  }, {});

const coins = importAll(
  // @ts-ignore
  require.context("./images/coins", false, /\.(png|jpe?g|svg)$/)
);

const TOESLAG_MAP = {
  0: 123,
  2000: 123,
  2400: 123,
  2800: 123,
  10000: 123,
  30000: 87,
  32000: 64,
  34000: 41,
  36000: 19,
  38000: 0,
};
const zorgToeslagFun = (a) => TOESLAG_MAP[a];

const voice = getVoiceLineGenerator(
  [
    "Noah-Kristel-06.14-21.mp3",
    "20230713-Noah-Kristel-11.1-01.mp3",
    "20230713-Noah-Kristel-10.30-14.mp3",
    "20230713-Noah-Kristel-10.18-15.mp3",
    "20230713-Noah-Kristel-10.6-06.mp3",
    "20230713-Noah-Kristel-15.3-07.mp3",
    "Zorgtoeslag-Suzanne-03.4-01.mp3",
    "Zorgtoeslag-Suzanne-02.7-02.mp3",
    "Zorgtoeslag-Suzanne-03.6-03.mp3",
    "Zorgtoeslag-Suzanne-02.9-04.mp3",
    "Zorgtoeslag-Suzanne-02.12-05.mp3",
    "Zorgtoeslag-Suzanne-02.14-06.mp3",
  ].map((f) => require(`./voice/${f}`))
);

const options2 = ["Noah-Kristel-06.18-24.mp3", "Noah28.mp3"].map((f) =>
  require(`./voice/${f}`)
);

const ZorgToeslagModule: Module = {
  name: "Zorgtoeslag",
  resumeBuilding: "zorgtoeslag",
  stages: [
    {
      type: "conversation",
      name: "convo 6",
      buttonText: "De stad in",
      inCity: true,
      characterImage: Noah.ThumbsUp,
      steps: [
        {
          text: "Je kan verschillende achievements behalen tijdens het spel. De eerste heb je al! Je kunt ze bekijken in de showroom in de stad!",
          voiceLine: voice(),
        },
      ],
      results: [
        {
          type: "mission",
          mission: {
            text: "Ga naar de showroom.",
          },
        },
      ],
    },
    {
      type: "goToBuilding",
      building: "achievements",
      shouldNavigate: true,
    },
    {
      type: "conversation",
      characterImage: Noah.Sweat,
      inCity: true,
      buttonText: "Naar de stad",
      steps: [
        {
          text: "Ik las net iets over *zorgtoeslag*? Wat is dat? Wat levert mij dat op? Laten we snel op zoek gaan!",
          voiceLine: voice(),
        },
      ],
      results: [
        {
          type: "mission",
          mission: {
            text: "Zoek uit wat *zorgtoeslag* is.",
          },
        },
      ],
    },
    {
      type: "goToBuilding",
      building: "zorgtoeslag",
    },
    {
      type: "playAnimation",
      lottieFile: StreetAnim,
      redirect: "/game",
    },
    {
      type: "event",
      buttonText: "Verder",
      redirect: "/game",
      steps: [
        {
          text: "Je steekt het zebrapad over, maar een fietser remt niet op tijd... Je springt opzij, maar komt vervelend neer. Je pols is gebroken!",
          header: "Pas op!",
          image: eventImages[0],
          voiceLine: voice(),
        },
        {
          header: "Zorgkosten",
          image: eventImages[1],
          text: `{{ choice = {385: 0, 585: 1, 885: 2}[${Variable(
            "eigenRisicoZorgverzekering"
          )}] }}De behandeling in het ziekenhuis kost €1000. Je hebt een eigen risico van €{{= ${Variable(
            "eigenRisicoZorgverzekering"
          )} }}. Je betaalt nu dus €{{= ${Variable(
            "eigenRisicoZorgverzekering"
          )} }} en de zorgverzekering betaalt de rest!`,
          voiceLine: voice(),
        },
      ],
      results: [
        {
          type: "bank",
          description: "Ziekenhuis kosten",
          amount: `{{= -${Variable("eigenRisicoZorgverzekering")} }}`,
          key: "ziekenhuis",
        },
      ],
    },
    {
      type: "redirect",
      redirect: "/city",
    },
    {
      type: "conversation",
      inCity: true,
      buttonText: "Verder",
      steps: [
        {
          text: "Zo, dat ging maar net goed. Waar waren we ook alweer?",
          voiceLine: voice(),
          characterImage: Noah.Sweat,
        },
        {
          text: "Oh ja, we gingen naar de zorgtoeslag!",
          voiceLine: voice(),
          characterImage: Noah.ThumbsUp,
        },
      ],
    },
    {
      type: "conversation",
      characterImage: ZorgToeslagCharacter.Default,
      right: true,
      buttonText: "Verder",
      backgroundImage: Background,
      replayHere: true,
      steps: [
        {
          text: "Hai! Ik vertel je meer over de *zorgtoeslag*. Toeslag betekent dat je geld van de *Belastingdienst* kunt krijgen.",
          voiceLine: voice(),
          characterImage: ZorgToeslagCharacter.Wave,
        },
        {
          text: "Met deze *zorgtoeslag* kun je een deel van je *zorgkosten* betalen.",
          voiceLine: voice(),
        },
        {
          voiceLine: voice(),
          text: "Hoeveel *zorgtoeslag* jij krijgt, hangt af van jouw *inkomen*.",
        },
        {
          voiceLine: voice(),
          text: "Wil jij weten hoeveel *zorgtoeslag* jij kunt krijgen?",
          characterImage: ZorgToeslagCharacter.Pointing,
        },
      ],
    },
    {
      type: "trueFalse",
      penalty: 5,
      incorrectCharacter: ZorgToeslagCharacter.Default,
      correctCharacter: ZorgToeslagCharacter.ThumbsUp,
      questions: [
        {
          question:
            "De Belastingdienst kijkt naar jouw bruto jaarinkomen. Maar wat is een bruto jaarinkomen?",
          answer: 1,
          correctText:
            "Dat klopt! Dit is het *salaris* wat vaak bovenaan op jouw *loonstrook* staat.",
          incorrectText: (i) =>
            i === 0
              ? "Als je geld verdient, moet je ook *belasting* betalen. Je *bruto inkomen* is dus het geld wat je verdient voor er *belasting* is afgetrokken"
              : "Het *bruto jaarinkomen* is al het geld dat je in een jaar hebt verdiend, waar nog geen *belasting* vanaf is getrokken. De bonus moet je hier vaak bovenop tellen.",
          answers: [
            "Al het geld wat je officieel in een jaar verdient en op je rekening krijgt.",
            "Het geld wat je een heel jaar hebt verdiend, waar nog geen belasting vanaf is getrokken.",
            "Een bonus aan het einde van het jaar.",
          ],
        },
      ],
    },
    {
      type: "conversation",
      characterImage: ZorgToeslagCharacter.ThumbsUp,
      right: true,
      backgroundImage: Background,
      buttonText: "Verder",
      steps: [
        {
          text: "Je weet nu wat het *bruto jaarinkomen* is. Hoeveel *zorgtoeslag* kun je vragen?",
          voiceLine: voice(),
        },
      ],
    },
    {
      type: "sliderInteraction",
      text: "Bereken ons bruto jaarinkomen. Vul dit in op de slider. Dan zie je hoeveel zorgtoeslag je krijgt. Druk op '?' voor meer uitleg.",
      button: "Controleer",
      help: {
        characterImage: ZorgToeslagCharacter.ShowTablet,
        text: [
          "Jouw *bruto maandinkomen* is €200 per maand. Dat moeten we omrekenen naar het *bruto jaarinkomen*.",
          "Je bruto jaarinkomen bereken je zo:\n*bruto maandinkomen x 12*",
        ],
      },
      penalty: 5,
      inputs: [
        {
          type: "range",
          name: "inkomen",
          label: "Jaarinkomen",
          min: 0,
          max: 9,
          mapping: Object.keys(TOESLAG_MAP).map(Number),
          step: 1,
          start: 9,
          formatter: euroFormat.format,
        },
      ],
      outputs: [
        {
          name: "Maandelijkse zorgtoeslag",
          function: zorgToeslagFun,
          formatter: euroFormat.format,
          correctAnswer: (n) => n === 2400,
          imageFunction: (a) => coins[`coins-${a}.png`],
        },
      ],
      feedback: {
        character: (a) =>
          a === 2400
            ? ZorgToeslagCharacter.ThumbsUp
            : ZorgToeslagCharacter.Default,
        correct: "Dat klopt! Je krijgt €123 zorgtoeslag per maand.",
        incorrect: (a) =>
          a < 2400
            ? "Dat is niet jouw bruto jaarinkomen, je verdient *meer* dan dat."
            : "Dat is niet jouw bruto jaarinkomen, je verdient *minder* dan dat.",
      },
    },
    {
      type: "conversation",
      characterImage: ZorgToeslagCharacter.ThumbsUp,
      backgroundImage: Background,
      right: true,
      buttonText: "Verder",
      steps: [
        {
          text: "Wat weet jij allemaal over *zorgtoeslag*? Test je kennis!",
          voiceLine: voice(),
        },
      ],
    },
    {
      penalty: 5,
      type: "trueFalse",
      help: {
        characterImage: ZorgToeslagCharacter.ShowTablet,
        text: "Geef aan of deze stellingen wel of niet waar zijn.",
      },
      incorrectCharacter: ZorgToeslagCharacter.Default,
      correctCharacter: ZorgToeslagCharacter.ThumbsUp,
      questions: [
        {
          question: "Iedereen in Nederland krijgt evenveel zorgtoeslag.",
          answer: 1,
          correctText:
            "Goed zo! Hoeveel *zorgtoeslag* je krijgt, hangt af van jouw *inkomsten*.",
          incorrectText:
            "Helaas, dit is niet goed. Hoeveel *zorgtoeslag* je krijgt, hangt af van jouw *inkomsten*.",
        },
        {
          question:
            "Vraag je zorgtoeslag aan? Het kan dan 1 tot 3 maanden duren voor je het krijgt.",
          answer: 0,
          correctText:
            "Dat klopt. Het kan best lang duren voor je het krijgt. Vraag de *zorgtoeslag* dus op tijd aan!",
          incorrectText:
            "Dit antwoord is helaas niet goed. Het kan best lang duren voor je het krijgt. Vraag de *zorgtoeslag* dus op tijd aan!",
        },
        {
          question:
            "Jouw zorgtoeslag is voor jou. Je kunt de zorgtoeslag ook gebruiken om te shoppen of uit te gaan.",
          answer: 0,
          correctText:
            "Dit klopt. Jouw *zorgtoeslag* is wel helemaal voor jou. Maar het is niet slim om dit te doen!",
          incorrectText:
            "Dit klopt niet. Je mag de *zorgtoeslag* overal voor gebruiken. Maar het is natuurlijk veel slimmer om jouw *zorgverzekering* ermee te betalen!",
        },
        {
          question:
            "De Belastingdienst kan geld terugvragen, als je jouw inkomsten niet van te voren opgeeft.",
          answer: 0,
          correctText:
            "Perfect! Probeer je *inkomsten* zo goed mogelijk door te geven. Dan krijg je geen vervelende verrassingen!",
          incorrectText:
            "Helaas. Je moet geld terugbetalen, als je te veel *zorgtoeslag* hebt aangevraagd.",
        },
      ],
    },
    {
      type: "redirect",
      redirect: "/rating",
    },
    {
      type: "choice",
      text: "Je weet nu meer over de zorgtoeslag. Wil je het aanvragen?",
      header: "Zorgtoeslag aanvragen",
      choices: [
        {
          display: "Ja",
          value: [123],
          saveVariable: [Choices.zorgToeslag],
        },
        {
          display: "Nee",
          value: [0],
          saveVariable: [Choices.zorgToeslag],
        },
      ],
    },
    {
      type: "conversation",
      characterImage: Noah.Default,
      backgroundImage: Background,
      buttonText: "Naar de stad",
      steps: [
        {
          text: `{{ var text; zorgtoeslag = ${Variable(
            "zorgToeslag"
          )} > 0; if (zorgtoeslag) text = 'Die €'+${Variable(
            "zorgToeslag"
          )}+' is een fijne bijdrage voor de *zorgverzekering* van €' + ${Variable(
            "maandelijksePremie"
          )}.toFixed(2).replace(".", ",") + ' per maand. De *Belastingdienst* stort de *zorgtoeslag* elke maand op je rekening.'; else text = 'De *zorgverzekering* van €' + ${Variable(
            "maandelijksePremie"
          )}.toFixed(2).replace(".", ",") + ' per maand blijft zo wel een hoop geld kosten. Dan moet ik maar meer gaan werken.' }}{{= text}}`,
          voiceLine: () => {
            if (window["zorgtoeslag"]) {
              return options2[0];
            } else {
              return options2[1];
            }
          },
        },
      ],
      results: [
        {
          type: "bank",
          amount: `{{= ${Variable("zorgToeslag")} }}`,
          description: "Zorgtoeslag",
          key: "zorgtoeslag",
          monthly: true,
        },
      ],
    },
  ],
};

export default ZorgToeslagModule;
