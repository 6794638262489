import BG from "./images/donorregister_inside.svg";
import DonorChar from "./images/character/DonorChar";
import Noah from "../../../assets/characters/Noah/Noah";
import { getVoiceLineGenerator } from "../../../util/Voice";

const voice = getVoiceLineGenerator(
  [
    "Noah - Kristel-09.3-30.mp3",
    "Noah - Kristel-09.5-31.mp3",
    "Donorregister - Mees-01.7-01.mp3",
    "Donorregister - Mees-01.6-02.mp3",
    "Donorregister - Mees-01.9-03.mp3",
    "Donorregister - Mees-01.11-04.mp3",
    "Donorregister - Mees-01.13-05.mp3",
    "Donorregister - Mees-03.2-06.mp3",
    "Donorregister - Mees-03.4-07.mp3",
    "Donorregister - Mees-01.34-08.mp3",
    "Noah - Kristel-09.7-32.mp3",
    "Donorregister - Mees-01.21-09.mp3",
    "Donorregister - Mees-05.4-10.mp3",
    "Donorregister - Mees-01.26-11.mp3",
    "Donorregister - Mees-01.28-12.mp3",
    "Noah - Kristel-12.1-33.mp3",
  ].map((f) => require(`./voice/${f}`))
);

const DonorModule: Module = {
  resumeBuilding: "ziekenhuis",
  name: "Donorregister",
  stages: [
    {
      type: "conversation",
      inCity: true,
      buttonText: "Verder",
      characterImage: Noah.Default,
      steps: [
        {
          text: "Ik heb een brief gekregen over het *Donorregister*.",
          voiceLine: voice(),
        },
        {
          text: "Ik weet eigenlijk niet precies wat dat is. Laten we dat eens uitzoeken!",
          voiceLine: voice(),
        },
      ],
      results: [
        {
          type: "mission",
          mission: {
            text: "Zoek uit wat het *Donorregister* is.",
          },
        },
      ],
    },
    {
      type: "conversation",
      backgroundImage: BG,
      right: true,
      replayHere: true,
      steps: [
        {
          characterImage: DonorChar.Default,
          text: "Word je 18? Dan kom je in het *Donorregister*.",
          voiceLine: voice(),
        },
        {
          characterImage: DonorChar.Explaining,
          text: "Je kunt *zelf kiezen* of je wel of geen donor wilt zijn.",
          voiceLine: voice(),
        },
        {
          characterImage: DonorChar.Default,
          text: "Denk hier goed over na en *maak een bewuste keuze die bij jou past*.",
          voiceLine: voice(),
        },
        {
          characterImage: DonorChar.Explaining,
          text: "Als je zelf *geen keuze* maakt, ben je *automatisch donor*.",
          voiceLine: voice(),
        },
        {
          characterImage: DonorChar.Default,
          text: "Dan kunnen je organen na je dood naar een patiënt gaan.",
          voiceLine: voice(),
        },
        {
          characterImage: DonorChar.Explaining,
          voiceLine: [voice(), 750, voice()],
          text: "Je hebt vier keuzes in het *Donorregister*:\n1. ‘*Ja*, ik wil donor worden.’,\n2. ‘*Nee*, ik wil geen donor worden.’\n3. ‘*Mijn familie beslist na mijn dood* of ik donor ben.’\nof 4. ‘*Een persoon die ik zelf aanwijs, beslist na mijn dood*, of ik donor ben.’.",
        },
        {
          characterImage: DonorChar.Book,
          voiceLine: voice(),
          text: "Laten we snel nog een paar vragen beantwoorden over het *Donorregister* om er nog meer over te leren.",
        },
      ],
      buttonText: "Verder",
    },
    {
      type: "trueFalse",
      penalty: 5,
      questions: [
        {
          question: "Maak ik geen keuze, dan ben ik geen donor",
          answer: 1,
          correctText:
            "Goed zo! Je bent automatisch donor, als je zelf geen bewuste keuze maakt. Als je geen donor wilt zijn, moet je dit zelf aangeven.",
          incorrectText:
            "Dit klopt niet. Je bent automatisch donor, als je zelf geen bewuste keuze maakt. Als je geen donor wilt zijn, moet je dit zelf aangeven.",
        },
        {
          question: "Je keuze in het Donorregister kun je altijd aanpassen.",
          answer: 0,
          correctText:
            "Goed zo! Je kunt je keuze altijd online aanpassen. Zo vaak je wilt.",
          incorrectText:
            "Dat klopt niet. Je kunt je keuze altijd online aanpassen. Zo vaak je wilt.",
        },
        {
          question:
            "Je kunt zelf kiezen welke organen en/of weefsels je wilt doneren.",
          answer: 0,
          correctText:
            "Goed zo! Je kunt zelf aangeven welke organen en weefsels je wilt doneren.",
          incorrectText:
            "Dat klopt niet. Je kunt zelf aangeven welke organen en weefsels je wilt doneren.",
        },
        {
          question:
            "Na donatie zie je er nog goed uit voor het afscheid door de familie.",
          answer: 0,
          correctText:
            "Goed zo! De operatie verloopt heel netjes. Met kleding aan is er niets meer te zien.",
          incorrectText:
            "Dat klopt niet. De operatie verloopt heel netjes. Met kleding aan is er niets meer te zien.",
        },
      ],
      correctCharacter: DonorChar.Default,
      incorrectCharacter: DonorChar.Explaining,
      answers: ["Waar", "Niet waar"],
    },
    {
      type: "conversation",
      backgroundImage: BG,
      characterImage: Noah.Sweat,
      steps: [
        {
          text: "Ik kom nog best veel moeilijke woorden tegen. Laten we die eens beter bekijken.",
          voiceLine: voice(),
        },
      ],
      buttonText: "Verder",
    },
    {
      type: "bubblePopperInteraction",
      answers: [
        "het Donorregister",
        "organen",
        "geen bezwaar",
        "(orgaan)donatie",
      ],
      penalty: 5,
      questions: [
        {
          question:
            "Hart, lever, longen, nieren, alvleesklier en de dunne darm.",
          answers: ["organen"],
          canSkip: true,
        },
        {
          question: "Het weggeven van organen of weefsel aan een patiënt.",
          answers: ["(orgaan)donatie"],
          canSkip: true,
        },
        {
          question:
            "Hierin staat van alle Nederlanders vermeld, of ze na hun dood wel of geen donor willen zijn.",
          answers: ["het Donorregister"],
          canSkip: true,
        },
        {
          question:
            "Dit komt bij je naam te staan als je zelf geen keuze invult.",
          answers: ["geen bezwaar"],
          canSkip: true,
        },
      ],
    },
    {
      type: "redirect",
      redirect: "/rating",
    },
    {
      type: "conversation",
      backgroundImage: BG,
      right: true,
      replayHere: true,
      steps: [
        {
          characterImage: DonorChar.Book,
          text: "Je weet nu dat je in het *Donorregister* komt, als je 18 wordt.",
          voiceLine: voice(),
        },
        {
          characterImage: DonorChar.Explaining,
          text: "Denk goed na of je een donor wilt zijn. En bespreek dit met je familie en vrienden.",
          voiceLine: voice(),
        },
        {
          characterImage: DonorChar.Explaining,
          voiceLine: voice(),
          text: "Maak daarna je *eigen keuze*. Je kunt dit *altijd aanpassen*.",
        },
        {
          characterImage: DonorChar.Book,
          voiceLine: voice(),
          text: "Geef je keuze door aan het *Donorregister* via de brief of online. Als je dit niet doet, ben je automatisch donor.",
        },
        {
          characterImage: Noah.ThumbsUp,
          right: false,
          text: "Goed om dit allemaal te weten! Laten we weer teruggaan naar de stad.",
          voiceLine: voice(),
        },
      ],
      buttonText: "Verder",
    },
    {
      type: "redirect",
      redirect: "/city",
    },
  ],
};

export default DonorModule;
